import React, { useContext, useEffect, useState } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
// import SignUp from "./SignUp";
// import { Helper } from "../Helper";
// import UserProvider from "../providers/UserProvider";
// import ProfilePage from "./ProfilePage";
import Header from "./../Include/Header";
import SideBar from "./../Include/SideBar";
import Dashboard from "./../Page/Dashboard";
import Store from './../Page/Store/Store';
import Member from './../Page/Member/Member';
import MemberView from './../Page/Member/View';
import MangeStore from './../Page/Manage/Store';
import StoreManageForm from "./../Page/Manage/StoreForm";
import ManagePromotionEvent from "./../Page/Manage/EventPromotion";
import FormEventPromotion from "./../Page/Manage/FormEventPromotion";
import HistoryManagePromotionEvent from "./../Page/Manage/EventPromotionHistory";
import AdminSetting from './../Page/AdminSetting';
import StoreForm from './../Page/Store/Form';
import Artist from './../Page/Artist/Index';
import ArtistView from './../Page/Artist/View';
import ExchangeRequest from './../Page/Exchange/Request';
import Live from './../Page/Live/Live';
import PaymentPackage from "./../Page/Payment/PaymentPackageDetail";
import PasswordReset from './PasswordReset';
import FoodRequest from './../Page/Menu/FoodRequest';
import MenuPage from './../Page/Menu/MenuPage';
// import TestPage from './../Page/TestPage';
import AdminDetail from './../Page/AdminDetail';
import MusicRequest from './../Page/Staticts/MusicRequest';
import StoreTransaction from './../Page/Store/Transaction';
import Channel from './../Page/Payment/Channel';
import PaymentResult from './../Page/Payment/PaymentResult';
import Mockup from './../Page/Mockup';
import { UserContext } from "../providers/UserProvider";
import { db, api_host, api_host_app } from "./../firebase"
import Transection from "../Page/Payment/Transection";
window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.segment = window.location.pathname.split('/');
let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
  is_emulators = true;
}
window.api_host = api_host;
if (is_emulators) {
  window.api_host = "http://localhost:5001/skitz-app-test/asia-southeast2/backend";
}
window.api_host_app = api_host_app;
window.omise_pkey = "pkey_test_5lwzzziyzpdnqjniqrb";
window.Omise.setPublicKey(window.omise_pkey);
window.frontend_url = `https://${process.env.REACT_APP_FRONTEND_URL}/`;


function Application() {
  const data = useContext(UserContext);
  const [isCloseServer, setCloseServer] = useState(false)
  // const [isAPI, setIsAPI] = useState(false);
  let isExpired = true;
  if (data.user_data && data.user_data.store && data.user_data.store.isExpired !== undefined) {
    isExpired = data.user_data.store.isExpired;
  }


  window.uid = data && data.user && data.user.storeUid ? data.user.storeUid : false;
  useEffect(() => {
    db.collection(`constants`).doc(`closeServer`).onSnapshot(async (snapshot) => {
      let ref = await snapshot.ref.get();
      let data = ref.data();
      setCloseServer(data.value);
    })
  }, []);

  return (
    data && data.user.uid ?
      isCloseServer && !data.user_data.user.dev ?
        <ServerClose />
        :
        <div className={"hold-transition sidebar-mini"}>
          <div className="wrapper">
            <Header data={data} />
            <SideBar data={data}
              package_features={data.user_data
                && data.user_data.package
                && data.user_data.package.option
                && data.user_data.package.option.features ?
                data.user_data.package.option.features : false
              }
            />
            <Router className="content-wrapper">
              <Dashboard path="/" uid={data.user.storeUid}
                package_features={data.user_data
                  && data.user_data.package
                  && data.user_data.package.option
                  && data.user_data.package.option.features ?
                  data.user_data.package.option.features : false
                }
                disableEdit={true}
                current_billing={data.user_data.current_billing}
                next_billing={data.user_data.next_billing}
                isExpired={isExpired}
              />
              <Dashboard path="/dashboard" uid={data.user.storeUid}
                package_features={data.user_data
                  && data.user_data.package
                  && data.user_data.package.option
                  && data.user_data.package.option.features ?
                  data.user_data.package.option.features : false
                }
                disableEdit={true}
                current_billing={data.user_data.current_billing}
                next_billing={data.user_data.next_billing}
                isExpired={isExpired}
              />
              {
                data.user
                  && data.user.store
                  && data.user.store.permission
                  && data.user.store.permission.storeProfile
                  && data.user.store.permission.storeProfile.view ?
                  <>
                    <Store path="/store"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      current_billing={data.user_data.current_billing}
                      next_billing={data.user_data.next_billing}
                      isExpired={isExpired}
                    />
                    <StoreForm path="/store/edit"
                      mode="edit"
                      type="store"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      } />
                    <PaymentPackage path="/payment-package/:packageUid"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      current_billing={data.user_data.current_billing}
                      next_billing={data.user_data.next_billing}
                      isExpired={isExpired}
                    />
                    <Transection path="/transection/:transectionUid"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      fetchProvider={() => {
                        data.fetchProvider();
                      }}
                    />
                    <StoreTransaction path="/exchange/transaction"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                    <Channel path="/payment/channels"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                    <PaymentResult path="/payment/result"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                  </>
                  : null
              }
              {
                data.user
                  && !isExpired
                  && data.user.store
                  && data.user.store.permission
                  && data.user.store.permission.member
                  && data.user.store.permission.member.view ?
                  <>
                    <Member path="/member"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                    <MemberView path="/member/view/:member_uid"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                  </>
                  : null
              }
              {
                data.user
                  && !isExpired
                  && data.user.store
                  && data.user.store.permission
                  && data.user.store.permission.manageStore
                  && data.user.store.permission.manageStore.view ?
                  <>
                    <MangeStore path="/manage-store"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }

                    />
                  </>
                  : null
              }
              {
                data.user
                  && !isExpired
                  && data.user.store
                  && data.user.store.permission
                  && data.user.store.permission.manageEventPromotion
                  && data.user.store.permission.manageEventPromotion.view
                  && (data.user_data
                    && data.user_data.package
                    && data.user_data.package.option
                    && data.user_data.package.option.features
                    && data.user_data.package.option.features.canManageEvent
                  ) ?
                  <>
                    <ManagePromotionEvent path="/manage-promotion-event"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                    <HistoryManagePromotionEvent path="/manage-promotion-event/history"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                    />
                    <FormEventPromotion path="/manage-promotion-event/add"
                      mode={"add"}
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                    <FormEventPromotion path="/manage-promotion-event/edit/:eventPromotionUid"
                      mode={"edit"}
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                    {
                      data.user_data.package.option.features.canStorePostTimeline ?
                        <StoreManageForm path="/manage-store/add"
                          mode={"add"}
                          uid={data.user.storeUid}
                          package_features={data.user_data
                            && data.user_data.package
                            && data.user_data.package.option
                            && data.user_data.package.option.features ?
                            data.user_data.package.option.features : false
                          }
                          user={data.user_data.user}
                        />
                        : null
                    }
                    <StoreManageForm path="/manage-store/edit/:postID"
                      mode={"edit"}
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                  </>
                  : null
              }
              {
                data.user
                  && (data.user_data
                    && data.user_data.package
                    && data.user_data.package.option
                    && data.user_data.package.option.features
                    && data.user_data.package.option.features.haveMenu
                  ) ?
                  <>
                    <MenuPage
                      path="/manage-menu"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                      foodRenderType={data.user_data.store.foodRendertype}
                    />
                    <FoodRequest
                      path="/food/request"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                  </>
                  : null
              }
              {
                data.user
                  && data.user.store.permission
                  && data.user.store.permission.artist
                  && data.user.store.permission.artist.view
                  && (data.user_data
                    && data.user_data.package
                    && data.user_data.package.option
                    && data.user_data.package.option.features
                    && data.user_data.package.option.features.haveArtistLive
                  ) ?
                  <>
                    <Artist
                      path="/manage-artist"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                    <ArtistView
                      path="/artist/view/:uid"
                      storeuid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                  </>
                  : null
              }
              {
                data.user ?
                  <>
                    <ExchangeRequest
                      path="/exchange-request"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                  </>
                  : null
              }
              {
                data.user
                  && data.user.store.permission
                  && data.user.store.permission.live
                  && data.user.store.permission.live.view
                  && (data.user_data
                    && data.user_data.package
                    && data.user_data.package.option
                    && data.user_data.package.option.features
                    && data.user_data.package.option.features.haveArtistLive
                  ) ?
                  <>
                    <Live
                      path="/manage-live"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />
                    <MusicRequest
                      path="/staticts"
                      uid={data.user.storeUid}
                      package_features={data.user_data
                        && data.user_data.package
                        && data.user_data.package.option
                        && data.user_data.package.option.features ?
                        data.user_data.package.option.features : false
                      }
                      user={data.user_data.user}
                    />

                  </>
                  : null
              }
              {
                data.user
                  && data.user
                  && data.user.store
                  && data.user.store.permission
                  && data.user.store.permission.managePermission
                  && data.user.store.permission.managePermission.view
                  && (data.user_data
                    && data.user_data.package
                    && data.user_data.package.option
                    && data.user_data.package.option.features
                    && data.user_data.package.option.features.havePermissionStore
                  ) ?
                  <AdminSetting path="/permission"
                    uid={data.user.storeUid}
                    userUid={data.user.uid}
                    isOwner={data.user_data.user.isOwner}
                    package={data.user_data.package}
                  />
                  : null
              }
              <Mockup path="/mock-up" uid={data.user.storeUid} />
              <AdminDetail path="admin-detail/:provider_uid" />
            </Router>
          </div>
        </div>
      :
      <Router>
        <SignIn path="/" />
        <PasswordReset path="/password-reset" />
      </Router>
  );
}

export default Application;
class ServerClose extends React.Component {
  render() {
    return (
      <div className={"hold-transition login-page"}>
        <div className="login-box" style={{ marginTop: '-15rem', width: 450, maxWidth: '100%' }}>
          <div className="login-logo">
            <img src="assets/images/skitz-white.png" alt="Skitz Logo" style={{ maxHeight: 250 }} />
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <h2 className="login-box-msg" style={{ color: '#000' }}>Server Closed</h2>
              <p style={{ textAlign: 'center' }}>ระบบทำการปิดปรับปรุง<br />งดใช้บริการชั่วคราว</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}