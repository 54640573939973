import React from 'react';
import { Link, navigate } from '@reach/router';
import GoogleMap from './../../Components/GoogleMap';
import { Helper, SortableContainer, SortableItem } from '../../Helper';
import { QRCode } from 'react-qrcode-logo';
import { storage } from '../../firebase';
import { getStore } from '../../services/StoreServices';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Pagination from './../../Components/Pagination';
import StorePackage from './include/StorePackage';
import StoreExchange, { StoreExchangeRequestModal } from './include/StoreExchange';
import StorePaymentTransaction from './include/StorePaymentTransaction';
const axios = require('axios').default;
export default class Store extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            is_user_loading: true,
            is_package_loading: true,
            is_review_loading: true,
            is_promotion_loading: true,
            is_gallery_loading: true,
            is_sequence_loading: false,
            users: [],
            promotions: [],
            feeds: [],
            reviews: [],
            store_gallery: [],
            package: false,
            provider: false,
        }
        this.StorePaymentTransactionRef = React.createRef()
        this.StoreCoinTransactionRef = React.createRef()
        this.StoreExchangeRef = React.createRef()
    }

    async componentDidMount() {
        await this.fetch();
        await this.fetchGallery();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/store', {
            data: {
                uid: this.props.uid,
                package_type: true,
                only_main_package: true,
            }
        });
        let store = false;
        let _package = false;
        let provider = false;
        let package_types = false;
        if (res.data.success) {
            store = res.data.response.store;
            _package = res.data.response.store.package;
            provider = res.data.response.provider;
            package_types = res.data.response.package_types;
            let _store = await getStore(this.props.uid);
            if (!_store.error) {
                store.storeData = _store;
            }
        }

        this.setState({
            data: store,
            package: _package,
            provider: provider,
            package_types: package_types,
            is_loading: false,
            is_loading_profile: false,
        })
    }

    async fetchGallery() {
        let qs = {};
        qs.storeUid = this.props.uid;
        let res = await axios.get(window.api_host + '/store-gallery?' + window.$.param(qs));
        let store_gallery = [];
        // console.log(res.data);
        if (res.data.success) {
            store_gallery = res.data.store_gallery;
        }
        this.setState({
            store_gallery: store_gallery,
            is_sequence_loading: false,
            is_gallery_loading: false,
        })
    }



    render() {
        // console.log(this.state.data.photoPath)
        let currentPoint = 0;
        if (this.state.data && this.state.data.storeData) {
            currentPoint = this.state.data.storeData.currentPoint;
            if (this.state.data.storeData.currentReservedPoint && parseFloat(this.state.data.storeData.currentReservedPoint) > 0) {
                currentPoint -= this.state.data.storeData.currentReservedPoint;
            }
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ร้านค้า </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ร้านค้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                {/* STORE INFO */}
                                <div className="card card-outline">
                                    <div className="card-body box-profile">
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading_profile ?
                                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && !this.state.data ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data ?
                                                <>
                                                    <div className="flex row" style={{ alignItems: 'center' }}>
                                                        <div>
                                                            <div>{Helper.getStoreStatus(this.state.data.status)}</div>
                                                            <div>
                                                                {
                                                                    this.state.data.isPrivate ?
                                                                        <span className="badge badge-info">ส่วนตัว</span>
                                                                        :
                                                                        <span className="badge badge-dark">สาธารณะ</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            !this.props.disableEdit ?
                                                                <Link to={"/store/edit"} className="link right-floated"> แก้ไข</Link>
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img
                                                                src={this.state.data.photoPath ? this.state.data.photoPath : "/assets/images/no_upload_image.jpg"}
                                                                alt="Store profile" />
                                                        </div>
                                                    </div>
                                                    <h3 className="profile-username text-center">{this.state.data.nameTH || '-'}</h3>

                                                    <p className="text-muted text-center">{this.state.data.nameEN || '-'}</p>
                                                    <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', borderRight: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {this.state.data && this.state.data.storeData && this.state.data.storeData.currentCoin ? Helper.numberFormat(this.state.data.storeData.currentCoin) : 0}
                                                            <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </span>
                                                        <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {Helper.numberFormat(currentPoint)}
                                                            <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </span>
                                                    </div>
                                                    <div className='mb-2 d-flex justify-content-center'>
                                                        <button
                                                            type={"button"}
                                                            className="btn btn-primary mr-3"
                                                            onClick={() => {
                                                                Helper.reactToDom(window.$('body'),
                                                                    <StoreExchangeRequestModal
                                                                        storeId={this.state.data.storeData.id}
                                                                        currentPoint={currentPoint}
                                                                        store={this.state.data.storeData}
                                                                        onSuccess={(res) => {
                                                                            this.setState({
                                                                                is_loading_profile: true
                                                                            }, () => {
                                                                                this.fetch();
                                                                            })
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        >ขอแลกแต้ม</button>
                                                        <Link className="btn btn-default" to={"/payment/channels"}>เติมเงินร้าน</Link>
                                                    </div>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        <li className="list-group-item">
                                                            <b>ผู้ติดตาม</b> <div className="float-right">{Helper.numberFormat(this.state.follow_amount)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>ราคา</b> <div className="float-right">฿ {Helper.numberFormat(this.state.data.minPrice)} - {Helper.numberFormat(this.state.data.maxPrice)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>จำนวนผู้เข้าร้าน</b> <div className="float-right">{Helper.numberFormat(this.state.data.guestsLimit)}</div>
                                                        </li>
                                                    </ul>

                                                    <div className="flex row">

                                                        <div className={"right-floated"}>{Helper.getDateThai(this.state.data.createdAt, { is_show_time: true })}</div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>

                                {
                                    this.state.data ?
                                        <>
                                            {
                                                this.state.data
                                                    && this.state.data.coordinate
                                                    && this.state.data.coordinate._latitude
                                                    && this.state.data.coordinate._longitude ?
                                                    <GoogleMap
                                                        height={150}
                                                        mode={"view"}
                                                        lat={this.state.data.coordinate._latitude}
                                                        lng={this.state.data.coordinate._longitude}
                                                    />
                                                    : null
                                            }
                                            <div className="card">
                                                <div className="card-body">
                                                    <strong><i className="far fa-file-alt mr-1"></i> ภาพรวม</strong>
                                                    <p className="text-muted">
                                                        {this.state.data.overview}
                                                    </p>
                                                    <hr />
                                                    <strong><i className="fas fa-map-marker-alt mr-1"></i>ที่อยู่</strong>
                                                    <p className="text-muted">
                                                        {
                                                            this.state.data.contact
                                                                && this.state.data.contact.address ?
                                                                this.state.data.contact.address + ' '
                                                                : null
                                                        }
                                                        {
                                                            this.state.data.contact.province
                                                                && this.state.data.contact.province.name ?
                                                                this.state.data.contact.province.name.th : null
                                                        }
                                                    </p>
                                                    <hr />
                                                    <div className="flex row">
                                                        <div>
                                                            <strong><i className="far fa-envelope"></i> อีเมล์</strong>
                                                        </div>
                                                        <div className="text-muted right-floated">
                                                            {
                                                                this.state.data.contact
                                                                    && this.state.data.contact.email ?
                                                                    this.state.data.contact.email
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="flex row">
                                                        <div>
                                                            <strong><i className="fas fa-mobile-alt"></i> เบอร์โทรศัพท์</strong>
                                                        </div>
                                                        <div className="text-muted right-floated">
                                                            {
                                                                this.state.data.contact
                                                                    && this.state.data.contact.telephone ?
                                                                    this.state.data.contact.telephone
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                this.state.data.workingTime ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header">
                                                            <div className="card-title">วันเวลาทำการ</div>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.monday}
                                                                title={"วันจันทร์"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.tuesday}
                                                                title={"วันอังคาร"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.wednesday}
                                                                title={"วันพุธ"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.thursday}
                                                                title={"วันพฤหัส"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.friday}
                                                                title={"วันศุกร์"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.saturday}
                                                                title={"วันเสาร์"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.sunday}
                                                                title={"วันอาทิตย์"}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </>
                                        : null
                                }
                            </div>
                            <div className="col-12 col-md-9">
                                <div className={"card"}>
                                    <div className={"card-body"}>
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && !this.state.package ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.package ?
                                                <StorePackage
                                                    data={this.state.data}
                                                    package_list={this.state.package_types}
                                                    current_billing={this.props.current_billing}
                                                    uid={this.props.uid}
                                                    is_loading={this.state.is_loading}
                                                    is_show_user={true}
                                                    package={this.state.package}
                                                    isExpired={this.props.isExpired}
                                                />
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    !this.state.is_loading ?
                                        <div className="card card-primary card-outline card-outline-tabs">
                                            <div className="card-header p-0 border-bottom-0">
                                                <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                                    {
                                                        !this.props.disableEdit ?
                                                            <>
                                                                <li className="nav-item">
                                                                    <a className="nav-link active"
                                                                        id="gallery-history-link"
                                                                        data-toggle="pill"
                                                                        href="#gallery-history"
                                                                        role="tab"
                                                                        aria-controls="gallery-history"
                                                                        aria-selected="false"
                                                                    // onClick={() => {
                                                                    //     this.fetchGallery();
                                                                    // }}
                                                                    >
                                                                        <h5>แกลอรี่</h5>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link "
                                                                        ref={(ref) => this.package_btn_tabs = window.$(ref)}
                                                                        id="package-table-link"
                                                                        data-toggle="pill"
                                                                        href="#package-table"
                                                                        role="tab"
                                                                        aria-controls="custom-tabs-three-settings"
                                                                        aria-selected="true"
                                                                    >
                                                                        <h5>แพ็คเกจ</h5>
                                                                    </a>
                                                                </li>
                                                            </>
                                                            : null
                                                    }

                                                    <li className="nav-item">
                                                        <a className={this.props.disableEdit ? "nav-link active" : "nav-link"}
                                                            id="billing-package-history-link"
                                                            data-toggle="pill"
                                                            href="#billing-package-history"
                                                            role="tab"
                                                            aria-controls="billing-package-history"
                                                            aria-selected="false"
                                                        >
                                                            <h5>ประวัติการเติมแพ็กเกจ</h5>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className={this.props.disableEdit ? "nav-link active" : "nav-link"}
                                                            id="exchange-point-history-link"
                                                            data-toggle="pill"
                                                            href="#exchange-point-history"
                                                            role="tab"
                                                            aria-controls="exchange-point-history"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.StoreExchangeRef.current.firstTimeFetch()
                                                            }}
                                                        >
                                                            <h5>ประวัติการแลกแต้ม</h5>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className={this.props.disableEdit ? "nav-link active" : "nav-link"}
                                                            id="billing-history-link"
                                                            data-toggle="pill"
                                                            href="#billing-history"
                                                            role="tab"
                                                            aria-controls="billing-history"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.StorePaymentTransactionRef.current.firstTimeFetch()
                                                            }}
                                                        >
                                                            <h5>ประวัติการชำระเงิน</h5>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-body">
                                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                                    {
                                                        !this.props.disableEdit ?
                                                            <>
                                                                <div className="tab-pane fade active show" id="gallery-history" role="tabpanel" aria-labelledby="gallery-history">
                                                                    <StoreGallery
                                                                        data={this.state.data}
                                                                        uid={this.props.uid}
                                                                        is_loading={this.state.is_gallery_loading}
                                                                        is_sequence_loading={this.state.is_sequence_loading}
                                                                        store_gallery={this.state.store_gallery}
                                                                        fetch={() => this.fetchGallery()}
                                                                    />
                                                                </div>
                                                                <div className="tab-pane fade " id="package-table" role="tabpanel" aria-labelledby="package-table">
                                                                    <PackageList
                                                                        data={this.state.package_types}
                                                                        uid={this.props.uid}
                                                                        package={this.state.package}
                                                                        next_billing={this.props.next_billing}
                                                                    />
                                                                </div>
                                                            </>
                                                            : null
                                                    }

                                                    <div className={this.props.disableEdit ? "tab-pane fade active show " : "tab-pane fade "} id="billing-history" role="tabpanel" aria-labelledby="billing-history">
                                                        <div className='form-group'>
                                                            <Link className="btn btn-default" to={"/payment/channels"}>เติมเงินร้าน</Link>
                                                        </div>
                                                        <StorePaymentTransaction
                                                            hideHeader={true}
                                                            ref={this.StorePaymentTransactionRef}
                                                            uid={this.props.uid}
                                                            id={this.state.data.storeData.id}
                                                            disableRunDidMount={true}
                                                        />
                                                    </div>
                                                    <div className={this.props.disableEdit ? "tab-pane fade active show " : "tab-pane fade "} id="billing-package-history" role="tabpanel" aria-labelledby="billing-package-history">
                                                        <StorePackage
                                                            data={this.state.data}
                                                            uid={this.props.uid}
                                                            is_loading={this.state.is_package_loading}
                                                            is_show_history={true}
                                                        />
                                                    </div>
                                                    <div className={this.props.disableEdit ? "tab-pane fade active show " : "tab-pane fade "} id="exchange-point-history" role="tabpanel" aria-labelledby="exchange-point-history">
                                                        <div className="mb-3">
                                                            <button
                                                                type={"button"}
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    Helper.reactToDom(window.$('body'),
                                                                        <StoreExchangeRequestModal
                                                                            storeId={this.state.data.storeData.id}
                                                                            currentPoint={this.state.data.storeData.currentPoint - this.state.data.storeData.currentReservedPoint}
                                                                            store={this.state.data.storeData}
                                                                            onSuccess={(res) => {
                                                                                this.setState({
                                                                                    is_loading_profile: true
                                                                                }, () => {
                                                                                    this.fetch();
                                                                                })
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            >ขอแลกแต้ม</button>
                                                        </div>
                                                        <StoreExchange
                                                            ref={this.StoreExchangeRef}
                                                            disableRunDidMount={true}
                                                            data={this.state.data}
                                                            uid={this.props.uid}
                                                            is_loading={this.state.is_package_loading}
                                                            is_show_history={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class StoreGallery extends React.Component {

    async create(url) {
        let ref = await axios.post(window.api_host + '/create-store-gallery', {
            ...url,
            uid: this.props.uid
        });
        if (ref.data.success) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มรูปภาพสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.props.fetch();
            this.$file_btn.removeClass('loading');
        }
        if (ref.data.error) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มรูปภาพไม่สำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            // this.fetch();
            this.$file_btn.removeClass('loading');
        }
    }

    async delete(store_gallery, options) {

        let ref = await axios.post(window.api_host + '/delete-store-gallery', {
            uid: store_gallery.uid,
            storeUid: this.props.uid
        });
        if (ref.data.success) {
            await storage.ref().child(store_gallery.storePath).delete();
            window.$(document).Toasts('create', {
                title: 'ลบรูปภาพสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            // this.fetch();
            // this.$file_btn.removeClass('loading');
            if (options.onSuccess) {
                options.onSuccess();
            }
        }
        if (ref.data.error) {
            window.$(document).Toasts('create', {
                title: 'ลบรูปภาพไม่สำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            // this.fetch();
            // this.$file_btn.removeClass('loading');
            if (options.onError) {
                options.onError();
            }
        }
    }

    render() {
        return (
            <>
                <div className="flex row">
                    <h6 style={{ fontWeight: 'bold' }}>แกลอรี่</h6>
                </div>
                <div className="form-group">
                    <input name="image"
                        type={"file"}
                        accept="image/*"
                        ref={(ref) => {
                            this.$file = window.$(ref);
                            this.$file_btn = this.$file.parent().find('.btn');
                        }}
                        style={{ display: 'none' }}
                        id="file_image_bank"
                        onChange={async (e) => {
                            if (this.$file_btn.hasClass('loading')) { return false; }
                            await Helper.uploadFile(this.$file, 'store-gallery/', {
                                onSuccess: (url) => {
                                    // console.log(url);
                                    this.create(url);
                                },
                                onError: () => {
                                    this.$file_btn.removeClass('loading');
                                },
                                resize: true
                            })
                        }}
                    />
                    <label htmlFor="file_image_bank"
                        type={"button"}
                        className="btn btn-default"
                    >
                        อัพโหลด
                    </label>
                </div>
                <SortableContainer key={"promotion-slider"}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        // this.setSequence(oldIndex, newIndex);
                    }}
                    useDragHandle
                    axis={'xy'}
                >
                    <div className={"row"}>

                        {
                            this.props.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            this.props.is_sequence_loading ?
                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                : null
                        }
                        {
                            !this.props.is_loading
                                && this.props.store_gallery.length === 0 ?
                                <h5 style={{ padding: '1rem', textAlign: 'center' }}>ไม่มีข้อมูล</h5>
                                : null
                        }
                        {
                            !this.props.is_loading ?
                                this.props.store_gallery.map((store_gallery, store_gallery_i) => {
                                    return (
                                        <SortableItem
                                            key={store_gallery_i}
                                            index={store_gallery_i}
                                        >
                                            <div
                                                className={"col-sm-12 col-lg-3"}
                                                key={store_gallery_i}
                                            >
                                                <div className={"card"}>
                                                    <div className={"card-body"} style={{ padding: '0.5rem' }}>
                                                        <div className={"promotion-slide-frame"}>
                                                            {/* <div className="promotion-drag-btn">
                                                                <DragHandle key={`item-${store_gallery_i}`}>
                                                                    <button className={"btn btn-default"}>
                                                                        <i className="fas fa-arrows-alt" />
                                                                    </button>
                                                                </DragHandle>
                                                            </div> */}
                                                            <div className={"promotion-delete"}>
                                                                <button className={"btn btn-danger"}
                                                                    ref={(ref) => {
                                                                        window.$(ref).off().on('click', () => {
                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                            window.$(ref).addClass('loading');
                                                                            Helper.confirmModal({
                                                                                title: "ยืนยันลบรูปสไลด์",
                                                                                description: "ต้องการลบรูปสไลด์นี้หรือไม่ ?",
                                                                                onConfirm: () => {
                                                                                    this.delete(store_gallery, {
                                                                                        onSuccess: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                            this.props.fetch();
                                                                                        },
                                                                                        onError: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                        }
                                                                                    });
                                                                                },
                                                                                onCancel: () => {
                                                                                    window.$(ref).removeClass('loading');
                                                                                }
                                                                            });
                                                                        })
                                                                    }
                                                                    }
                                                                >
                                                                    <i className={"fas fa-trash-alt"} />
                                                                </button>
                                                            </div>
                                                            <a href={store_gallery.imagePath ? store_gallery.imagePath : null} data-fancybox="gallery">
                                                                <img src={store_gallery.imagePath ? store_gallery.imagePath : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SortableItem>
                                    );
                                })
                                : null
                        }
                    </div>
                </SortableContainer>
            </>
        );
    }
}
class DateTimeView extends React.Component {
    render() {
        return (
            <div className="flex row">
                <div>
                    <strong><i className="far fa-calendar-alt"></i> {this.props.title}</strong>
                </div>
                <div className="text-muted right-floated">
                    {
                        this.props.day
                            && this.props.day.time
                            && this.props.day.time.open
                            && this.props.day.time.close ?
                            <><i className="far fa-clock"></i> {this.props.day.time.open + ' - ' + this.props.day.time.close}</>
                            : 'ปิดทำการ'
                    }
                </div>
            </div>
        );
    }
}

class PackageList extends React.Component {
    render() {
        let current_index = 0;
        this.props.data.map((item, item_i) => {
            if (item.uid === this.props.package.uid) {
                current_index = item_i
            }
            return true;
        })
        // let now = new Date();
        // console.log(this.props);
        return (
            <>
                <div className="flex row">
                    <h6 style={{ fontWeight: 'bold' }}>แพ็คเกจ</h6>
                </div>
                <div className="package-table-scrollbar">
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th><div style={{ width: 150 }} /></th>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <th key={item_i}>
                                                <div style={{ color: item_i === current_index ? 'blue' : null, textAlign: 'center', minWidth: 110 }}>
                                                    {
                                                        item_i === current_index ?
                                                            <i className="fas fa-thumbtack" style={{ marginRight: 5 }}></i>
                                                            : null
                                                    } {item.name}
                                                </div>
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>
                                    <div>ระบบโพส</div>
                                    <ul style={{ marginBottom: 0 }}>
                                        {/* <li>ผู้ใช้งาน</li> */}
                                        <li>ร้าน</li>
                                        <li>ลบโพส</li>
                                    </ul>
                                </td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                <div>
                                                    {
                                                        item.options.features.haveTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                                {/* <div>
                                                    {
                                                        item.options.features.canGuestPostTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div> */}
                                                <div>
                                                    {
                                                        item.options.features.canStorePostTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.options.features.canStoreRemoveTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            {/* <tr>
                                <td>ระบบสกิดบนแอพพลิเคชั่น</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveSkitz ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr> */}
                            <tr>
                                <td>บังคับผู้ใช้งานออกจากระบบ (เตะผู้ใช้งาน)</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.canKickMember ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            {/* <tr>
                                <td>แบนผู้ใช้งาน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveBanUser ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr> */}
                            <tr>
                                <td>ระบบข่าวสารของร้าน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.canManageEvent ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบจัดการผู้ดูแลระบบและสิทธิ์การเข้าถึง</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.havePermissionStore ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ดูรูปโปรไฟล์ผู้ใช้งาน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.isPreviewMemberProfileStore ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบนักดนตรี/Live</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveArtistLive ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบจัดการเมนู</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveMenu ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>จำนวนผู้เข้าสู่ระบบสูงสุด</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {Helper.numberFormat(item.options.features.onlineCapacity)} คน
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ราคา/เดือน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {Helper.numberFormat(item.options.summary.total)} บาท
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td></td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i}>
                                                {
                                                    item_i === current_index
                                                        && item.options
                                                        && item.options.summary
                                                        && parseFloat(item.options.summary.total) !== 0
                                                        && !this.props.next_billing
                                                        ?
                                                        <Link className="btn btn-primary" style={{ width: '100%' }} to={"/payment-package/" + item.uid}>ต่ออายุแพ็คเกจ</Link>
                                                        : null
                                                }
                                                {
                                                    (
                                                        item_i === current_index
                                                        && item.options
                                                        && item.options.summary
                                                        && parseFloat(item.options.summary.total) === 0
                                                    ) || (
                                                            item_i === current_index
                                                            && item.options
                                                            && item.options.summary
                                                            && parseFloat(item.options.summary.total) !== 0
                                                            && this.props.next_billing
                                                        )
                                                        ?
                                                        <button className="btn btn-primary disabled" style={{ width: '100%' }} type={"button"}>แพ็กเกจปัจจุบัน</button>
                                                        : null
                                                }
                                                {
                                                    item_i < current_index && !this.props.next_billing ?
                                                        <Link className="btn btn-default" style={{ width: '100%' }} to={"/payment-package/" + item.uid}>ปรับลดแพ็คเกจ</Link>
                                                        : null
                                                }
                                                {
                                                    item_i < current_index && this.props.next_billing ?
                                                        <button className="btn btn-default disabled" style={{ width: '100%' }} type={"button"}>ปรับลดแพ็คเกจ</button>
                                                        : null
                                                }
                                                {
                                                    item_i > current_index
                                                        // && !this.props.next_billing
                                                        ?
                                                        <Link className="btn btn-success" style={{ width: '100%' }} to={"/payment-package/" + item.uid}>อัพเกรดแพ็คเกจ</Link>
                                                        : null
                                                }
                                                {/* {
                                                    item_i > current_index
                                                        && this.props.next_billing ?
                                                        <button className="btn btn-success disabled" style={{ width: '100%' }} type={"button"}>อัพเกรดแพ็คเกจ</button>
                                                        : null
                                                } */}
                                            </td>
                                        );
                                    })
                                }
                            </tr>

                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}