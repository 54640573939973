import React from "react";
import Pagination from "../../../Components/Pagination";
import { Helper } from "../../../Helper";
import { getPaymentTransaction, paymentRefund } from "../../../services/PaymentServices";
import { Link, navigate } from "@reach/router";
import CustomDateInput from '../../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import { getStore, getStoreSummary } from "../../../services/StoreServices";
import { exchangeRequest, getExchange } from "../../../services/ExchangeServices";
import { getSetting } from "../../../services/SettingsServices";
const banksLogo = require('banks-logo');
const default_limit = 5;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            data: [],
            page: 1,
            date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            channel: Helper.getParameterByName('channel') ? Helper.getParameterByName('channel') : '',
            q: Helper.getParameterByName('q') ? Helper.getParameterByName('q') : '',
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            total_items: 0,
            count_items: 0,
            storeId: props.id || false,
            currentPoint: props.currentPoint || 0,
            setting: false,
            summary: false,
        }
    }



    async fetch() {

        let storeId = this.state.storeId;
        let currentPoint = this.state.currentPoint;
        let date = false;
        let setting = this.state.setting;
        if (!this.state.setting) {
            let settingRef = await getSetting();
            if (!settingRef.error) {
                setting = settingRef;
            }
        }
        if (this.state.date) {
            let get_date = Helper.getDateToEdit(this.state.date);
            // get_date = new Date(get_date.setTime(get_date.getTime() + (parseFloat(7) * 60 * 60 * 1000)));
            let day = get_date.getDate().toString().padStart(2, 0);
            let month = (get_date.getMonth() + 1).toString().padStart(2, 0);
            date = `${day}-${month}-${get_date.getFullYear()}`;
        }
        let summary = this.state.summary;
        if (!summary) {
            let getSummary = await getStoreSummary(this.props.uid);
            summary = getSummary;
        }


        let filter = {
            page: this.state.page,
            rows: this.state.limit,
        }
        if (date) {
            filter.date = date;
        }
        if (this.state.status) {
            filter.statusId = this.state.status;
        }
        if (this.state.channel) {
            filter.channelId = this.state.channel;
        }
        if (this.state.q) {
            filter.q = this.state.q;
        }
        if (!storeId && this.props.uid && !this.props.id) {
            let storeRes = await getStore(this.props.uid);
            if (!storeRes.error) {
                storeId = storeRes.id;
                currentPoint = storeRes.currentPoint - storeRes.currentReservedPoint;
            } else {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'fas fa-ban',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.setState({
                    is_loading: false,
                    storeId: false,
                    data: [],
                    page: 1,
                    is_done: true,
                    totalItems: 0,
                    itemEnd: 0,
                })
                return;
            }
        }
        if (storeId) {
            filter.storeId = storeId;
        }
        filter.typeId = 2;
        let res = await getExchange(filter);
        // console.log(res);
        let data = [...this.state.data];
        let total_items = 0;
        let count_items = 0;
        let page = this.state.page;
        let is_done = false;
        if (!res.error) {
            data = data.concat(res.items);
            page = parseFloat(this.state.page) + 1;
            total_items = res.total;
            count_items = res.itemEnd;
            is_done = res.total == res.itemEnd;
        }
        this.setState({
            data: data,
            total_items: total_items,
            count_items: count_items,
            page: page,
            is_done: is_done,
            is_loading: false,
            currentPoint: currentPoint,
            storeId: storeId,
            setting: setting,
            summary: summary,
        })
    }
    firstTimeFetch() {
        if (this.state.data.length == 0) {
            this.fetch();
        }
    }

    componentDidMount() {
        if (!this.props.disableRunDidMount) {
            this.fetch();
        }
    }


    render() {
        return (
            <>
                <section className={"content"}>
                    <div >

                        <div className="row">
                            <div className="col-12 col-md-4">
                                {/* FILTER */}
                                <div className="card card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>
                                        <div className="card-tools" style={{ margin: 0 }}>
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let _data = Helper.getFormInputObject(this.$form);
                                                this.setState({
                                                    ..._data,
                                                    data: [],
                                                    page: 1,
                                                    is_loading: true
                                                }, () => {
                                                    this.fetch();
                                                })
                                                // let url = Helper.getQueryStringLinkFromObject({
                                                //     ...data
                                                // })
                                                // navigate(url)
                                            }}
                                        >
                                            <div className="col-12 form-group">
                                                <label>ค้นหา </label>
                                                <input type="text" name="q" className="form-control" defaultValue={this.state.q} />
                                            </div>
                                            <div className="col-12 form-group">
                                                <label className="d-flex">วันที่
                                                    {
                                                        this.state.date ?
                                                            <span className="right-floated date-cancle-icon"
                                                                onClick={() => {
                                                                    this.setState({ date: "" })
                                                                }}
                                                            >  ยกเลิก
                                                            </span>
                                                            : null
                                                    }
                                                </label>
                                                <div style={{ position: 'relative' }}>
                                                    <DatePicker
                                                        onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                        onChange={date => {
                                                            this.setState({
                                                                date: new Date(date).getTime(),
                                                            })
                                                        }}
                                                        locale="th-TH"
                                                        selected={this.state.date ? Helper.getDateToEdit(this.state.date) : false}
                                                        placeholderText={"วัน / เดือน / ปี"}
                                                        name={"birthDay"}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        withPortal
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => {
                                                            let now = new Date();
                                                            let selected_date = new Date(date);
                                                            // months_th
                                                            const years = [];
                                                            let i;
                                                            for (i = 1980; i <= now.getFullYear(); i++) {
                                                                years.push(i)
                                                            }
                                                            return (
                                                                <div
                                                                    style={{
                                                                        margin: 10,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                        className={"btn btn-primary "}
                                                                        type={"button"}
                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                    >
                                                                        <i className="fas fa-chevron-left" />
                                                                    </button>
                                                                    <select
                                                                        className={"form-control"}
                                                                        value={selected_date.getFullYear()}
                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                        style={{ borderRadius: 0 }}
                                                                    >
                                                                        {years.map(option => (
                                                                            <option key={option} value={option} >
                                                                                {option + 543}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <select
                                                                        className={"form-control"}
                                                                        value={window.months_th[selected_date.getMonth()]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(window.months_th.indexOf(value))
                                                                        }
                                                                        style={{ borderRadius: 0 }}
                                                                    >
                                                                        {window.months_th.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                        className={"btn btn-primary"}
                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                        type={"button"}
                                                                    >
                                                                        <i className="fas fa-chevron-right" />
                                                                    </button>
                                                                </div>
                                                            )
                                                        }}
                                                        customInput={<CustomDateInput />}
                                                    />
                                                    <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />


                                                </div>
                                            </div>

                                            <div className="col-12 form-group">
                                                <label>สถานะ</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"status"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.status || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    <option value={"1"}>
                                                        รอการยืนยัน
                                                    </option>
                                                    <option value={"2"}>
                                                        สำเร็จ
                                                    </option>
                                                    <option value={"3"}>
                                                        ยกเลิก
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        onClick={() => {
                                                            let $q = window.$(`[name=q]`);
                                                            let $status = window.$(`[name=status]`);
                                                            $q.val('')
                                                            $status.val('')
                                                            this.setState({
                                                                date: '',
                                                                q: '',
                                                                status: '',
                                                                data: [],
                                                                page: 1,
                                                                is_loading: true
                                                            }, () => {
                                                                this.fetch();
                                                            })
                                                        }}
                                                    >รีเซ็ท</button>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {
                                    this.state.summary ?
                                        <div className="card">
                                            <div className="card-body pt-0 pb-0">
                                                <ul className="list-group list-group-unbordered mb-0 pb-0">
                                                    <li className="list-group-item border-top-0 ">
                                                        <b>แต้มปัจจุบัน</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.currentPoint, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>แลกแต้มทั้งหมด</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.totalExchangePoint, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <b>ส่วนต่างทั้งหมด</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.totalCommissionTHB, { decimal: 2 })} บาท</div>
                                                    </li>
                                                    <li className="list-group-item border-0 ">
                                                        <b>จำนวนเงินทั้งหมด</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.totalExchangeTHB, { decimal: 2 })} บาท</div>
                                                    </li>

                                                </ul>
                                                {

                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="col-12 col-md-7">
                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                <RenderList
                                    setting={this.state.setting}
                                    data={this.state.data}
                                    isCanRefund={this.props.user && this.props.user.permission && this.props.user.permission.transaction && this.props.user.permission.transaction.refund ? true : false}
                                    onRefund={(obj) => {
                                        let datas = [...this.state.data];
                                        let i = 0;
                                        for (const data of datas) {
                                            if (data.id == obj.id) {
                                                datas[i] = obj;
                                            }
                                            i++;
                                        }
                                        this.setState({
                                            data: datas,
                                        })
                                    }}
                                />
                                <div className="d-flex mt-2 align-items-center">
                                    {
                                        !this.state.is_loading &&
                                            !this.state.is_done ?
                                            <button
                                                className="btn btn-default"
                                                onClick={(ref) => {
                                                    let node = window.$(ref.target);
                                                    if (node.hasClass('loading')) { return }
                                                    node.addClass('loading');
                                                    this.setState({
                                                        is_loading: true
                                                    }, () => {
                                                        this.fetch()
                                                    })
                                                }}
                                            >โหลดเพิ่ม</button>
                                            :
                                            !this.state.is_done ?
                                                <button className="btn btn-default loading">โหลดเพิ่ม</button>
                                                : null
                                    } <span className='right-floated'>{this.state.count_items}/{this.state.total_items}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
const paymentImage = { height: 20, marginRight: 5 }
class RenderList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการ</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        let statusText = "";
                        if (item.statusId == 1) {
                            statusText = 'รอการยืนยัน'
                        }
                        if (item.statusId == 2) {
                            statusText = 'สำเร็จ'
                        }
                        if (item.statusId == 3) {
                            statusText = 'ยกเลิก'
                        }

                        let amount = 0;
                        let bonusAmount = 0;
                        if (this.props.setting && this.props.setting.storeCommissionRate) {
                            let d = (parseFloat(item.pointAmount) / 100) * parseFloat(this.props.setting.storeCommissionRate)
                            amount = item.pointAmount + d;
                            bonusAmount = d;
                        }
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                    <p className='mb-0'><strong>ผู้ทำรายการ : </strong>{item.store.name}</p>
                                    {
                                        item.created ?

                                            <small className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</small>
                                            : null
                                    }
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="d-flex">
                                    <div>
                                        <div className="d-flex ">
                                            <p className='mb-0 mr-2'>บัญชี : </p>
                                            <div>
                                                {item.bankName ? <div>{item.bankName}</div> : null}
                                                {item.accountName ? <div>{item.accountName}</div> : null}
                                                {item.accountNumber ? <div>{item.accountNumber}</div> : null}
                                            </div>
                                        </div>
                                        <p className='mb-0'>สถานะ :
                                            <span style={{
                                                color:
                                                    item.statusId == 1 ? 'orange' :
                                                        item.statusId == 2 ? 'green' :
                                                            item.statusId == 3 ? "gray" :
                                                                '#000'
                                            }}> {statusText}</span>
                                            {
                                                item.statusId == 2
                                                    && item.transferSlip ?
                                                    <a
                                                        href={item.transferSlip}
                                                        data-fancybox={"transferSlip" + item.id}
                                                        className="ml-2"
                                                    >
                                                        หลักฐานการชำระเงิน
                                                    </a>
                                                    : null
                                            }
                                        </p>
                                        {
                                            item.updatedUser ?
                                                <>
                                                    <p className='mb-0'>ผู้ทำรายการ : {item.updatedUser} </p>
                                                    {
                                                        item.updated ?
                                                            <div><small>{Helper.getDateThai(item.updated, { is_show_time: true, plus_hours: 7 })}</small></div>
                                                            : null
                                                    }
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className="right-floated" style={{ paddingLeft: 15, minWidth: 250 }}>

                                        <div className="d-flex">
                                            <div className="text-left" style={{ flex: 1 }}>
                                                จำนวนแต้ม
                                            </div>
                                            <div>{item.pointAmount ? Helper.numberFormat(item.pointAmount, { decimal: 2 }) : 0} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                        </div>
                                        {
                                            this.props.setting.storeCommissionRate ?
                                                <>
                                                    <div className="d-flex">
                                                        <div className="text-left" style={{ flex: 1 }}>
                                                            โบนัส {this.props.setting.storeCommissionRate}%
                                                        </div>
                                                        <div>{Helper.numberFormat(bonusAmount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="text-left" style={{ flex: 1 }}>
                                                            แต้มรวม
                                                        </div>
                                                        <div>{Helper.numberFormat(amount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        <hr className="mt-1 mb-1" />

                                        <div className="d-flex">
                                            <div className="text-left" style={{ flex: 1 }}>
                                                ได้รับเงิน
                                            </div>
                                            <div>{item.currencyAmount ? Helper.numberFormat(item.currencyAmount, { decimal: 2 }) : 0} บาท</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }


}


export class StoreExchangeRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'form',
            bankList: Object.values(banksLogo.data),
            is_loading: props.setting ? true : false,
            setting: props.setting || false
        }
    }

    componentDidMount() {
        this.getSetting()
    }

    async getSetting() {
        let setting = this.state.setting;
        if (!this.state.setting) {
            let settingRef = await getSetting();
            if (!settingRef.error) {
                setting = settingRef;
            }
        }
        this.setState({
            setting: setting,
            is_loading: false,
        })
    }

    async request(item) {
        this.$form = this.$modal.find('form');
        let $submit = this.$form.find('button[type="submit"]');
        let data = { ...item };
        data.storeId = this.props.storeId;
        data.typeId = 2;
        // delete data.bankName;
        let res = await exchangeRequest(data);
        if (res.isSuccess) {
            Helper.messageTop({ message: 'ทำการยี่นเรื่องขอแลกเหรียญเรียบร้อยแล้ว', type_class: 'alert-success' })
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess(res);
            }
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            $submit.removeClass('loading');
            return;
        }
    }
    render() {
        let title = "แลกเหรียญ";
        if (this.state.mode == 'confirm') {
            title = "ยืนยันการแลกเหรียญ"
        }
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (!data.amount || data.amount == '0') {
                                Helper.messageTop({ message: 'กรุณากรอกจำนวน Point' })
                                $submit.removeClass('loading');
                                return;
                            }
                            if (!data.accountName
                                || !data.accountNumber
                                || !data.bankName
                            ) {
                                Helper.messageTop({ message: 'กรุณากรอกข้อมูลบัญชี' })
                                $submit.removeClass('loading');
                                return;
                            }
                            if (this.state.mode == 'confirm') {
                                this.request(data)
                                return;
                            } else {
                                let d = ((parseFloat(data.amount) / 100) * parseFloat(this.state.setting.storeCommissionRate))
                                let confirmData = {}
                                confirmData.name = this.props.store.name
                                let now = new Date();
                                confirmData.created = Helper.getDateThai(now.getTime());
                                confirmData.bankName = data.bankName;
                                confirmData.accountName = data.accountName;
                                confirmData.accountNumber = data.accountNumber;
                                confirmData.pointAmount = data.amount;
                                confirmData.total = parseFloat(data.amount) + parseFloat(d);
                                confirmData.pointMargin = d;
                                confirmData.totalBaht = parseFloat(confirmData.total) / parseFloat(this.state.setting.exchangeRatePerTHB)
                                // console.log(confirmData);
                                // return;
                                this.setState({
                                    confirmData: confirmData,
                                    mode: 'confirm'
                                })
                            }
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                <div style={{ display: this.state.mode == 'confirm' ? "none" : "block" }}>
                                    <div className={"form-group"}>
                                        <label>จำนวน Point ({this.props.currentPoint ? Helper.numberFormat(this.props.currentPoint) : 0})</label>
                                        <input type={'number'} className={"form-control"} name="amount"
                                            onKeyUp={(e) => {
                                                let _value = e.target.value;
                                                if (_value && parseFloat(_value) > this.props.currentPoint) {
                                                    window.$(e.target).val(this.props.currentPoint)
                                                }
                                                if ((_value && parseFloat(_value) < 0) || isNaN(_value)) {
                                                    window.$(e.target).val(0)
                                                }
                                            }}
                                            onChange={(e) => {
                                                let _value = e.target.value;
                                                if (_value && parseFloat(_value) > this.props.currentPoint) {
                                                    window.$(e.target).val(this.props.currentPoint)
                                                }
                                                if ((_value && parseFloat(_value) < 0) || isNaN(parseFloat(_value))) {
                                                    window.$(e.target).val(0)
                                                }
                                            }}

                                        />
                                    </div>
                                    <div>
                                        <label>บัญชีธนาคาร</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-12 form-group">
                                            <select
                                                ref={(ref) => {
                                                    window.$(ref).select2({ placeholder: 'ธนาคาร' });
                                                    if (this.state.confirmData && this.state.confirmData.bankName) {
                                                        window.$(ref).val(this.state.confirmData.bankName).trigger('change')
                                                    } else {
                                                        window.$(ref).val(null).trigger('change')
                                                    }
                                                }}
                                                className={"form-control select2"}
                                                name={"bankName"}
                                                style={{ width: '100%' }}
                                            >
                                                {
                                                    this.state.bankList.map((bank, i) => {
                                                        return (<option value={bank.official_name_thai} key={i}>
                                                            {bank.official_name_thai}
                                                        </option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label>ชื่อบัญชี</label>
                                            <input name="accountName" className="form-control" />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label>เลขบัญชี</label>
                                            <input name="accountNumber" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.mode == 'confirm' ? "block" : "none" }}>
                                    {
                                        this.state.confirmData ?
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label>ชื่อคนทำรายการ</label>
                                                        <p className="mb-0">{this.state.confirmData.name}</p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label>เวลาที่ทำรายการ</label>
                                                        <p className="mb-0">{this.state.confirmData.created}</p>
                                                    </div>
                                                </div>
                                                <hr className="mt-2 mb-2" />
                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        <label>ธนาคาร</label>
                                                        <p className="mb-0">{this.state.confirmData.bankName}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label>ชื่อบัญชี</label>
                                                        <p className="mb-0">{this.state.confirmData.accountName}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label>เลขบัญชี</label>
                                                        <p className="mb-0">{this.state.confirmData.accountNumber}</p>
                                                    </div>
                                                </div>
                                                <hr className="mt-2 mb-2" />
                                                <div className="d-flex justify-content-end">
                                                    <label>แต้ม point ที่ทำการโอน</label>
                                                    <p className="mb-0"
                                                        style={{ width: 150, display: 'flex', justifyContent: 'flex-end' }}
                                                    >
                                                        {Helper.numberFormat(this.state.confirmData.pointAmount, { decimal: 2 })}
                                                        <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 5 }} />
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label>โบนัส {this.state.setting.storeCommissionRate}%</label>
                                                    <p className="mb-0"
                                                        style={{ width: 150, display: 'flex', justifyContent: 'flex-end' }}
                                                    >
                                                        {Helper.numberFormat(this.state.confirmData.pointMargin, { decimal: 2 })}
                                                        <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 5 }} />
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label>แต้มทั้งหมด</label>
                                                    <p className="mb-0"
                                                        style={{ width: 150, display: 'flex', justifyContent: 'flex-end' }}
                                                    >
                                                        {Helper.numberFormat(this.state.confirmData.total, { decimal: 2 })}
                                                        <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 5 }} />
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <label>ได้รับเงินจำนวน</label>
                                                    <p className="mb-0"
                                                        style={{ width: 150, display: 'flex', justifyContent: 'flex-end' }}
                                                    >
                                                        {Helper.numberFormat(this.state.confirmData.totalBaht, { decimal: 2 }) + ' '} <strong> บาท</strong>
                                                    </p>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                {
                                    this.state.mode != 'confirm' ?
                                        <button type="button"
                                            className="btn btn-default"
                                            onClick={() => {
                                                this.$modal.modal('hide');
                                                if (this.props.onClose) { this.props.onClose(); }
                                            }}
                                        >ปิด</button>
                                        :
                                        <button type="button"
                                            className="btn btn-default"
                                            onClick={() => {
                                                this.setState({
                                                    mode: 'form'
                                                })
                                            }}
                                        >ยกเลิก</button>
                                }
                                <button type="submit" className={this.state.mode != 'confirm' ? "btn btn-primary" : "btn btn-success"}>
                                    {
                                        this.state.mode != 'confirm' ?
                                            "ดำเนินการ" : "ยืนยันขอแลกแต้ม"
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}