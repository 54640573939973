import React, { useContext, useState } from "react";
import { Link } from "@reach/router";

export default class Error extends React.Component {
    render() {
        return (
            <section className="content" style={{ backgroundColor: '#f4f6f9', marginTop: 50 }}>
                <div className="error-page">
                    <h2 className="headline text-danger" style={{ marginRight: 20 }}>500</h2>

                    <div className="error-content" style={{paddingTop: 30}}>
                        <h3><i className="fas fa-exclamation-triangle text-danger"></i> เกิดข้อผิดพลาด.</h3>
                        <p>
                            ไม่พบข้อมูลผู้ใช้งาน
                        <button type="button" className="btn btn-link" onClick={()=> {this.props.signOut()}}>ไปยังหน้า Login</button>
                        </p>
                    </div>
                </div>

            </section>
        );
    }
}