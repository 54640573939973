import React from "react";
import { Link } from '@reach/router';
import { Helper } from "./../Helper";
import CreateAirDrop from '../Components/CreateAirDrop';
import { getStore } from "../services/StoreServices";
const axios = require('axios').default;
export default class Mockup extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }

    componentDidMount() {
        // this.callAPI();
        // this.check('ทดสอบ123')
        this.fetchStore();
    }

    async fetchStore() {
        let store = false;
        let _store = await getStore(this.props.uid);
        if (!_store.error) {
            store = _store;
        }
        this.setState({
            store: store,
        })
    }

    // async callAPI() {
    //     let data = {};
    //     // data.postBy = 'store';
    //     data.storeUid = this.props.uid;
    //     // data.storeUid = 1;
    //     let res = await axios.post(window.api_host + '/get-store-posts', { ...data });
    //     console.log(res.data);
    //     if (this.$callApiBtn.hasClass('loading')) {
    //         this.$callApiBtn.removeClass('loading')
    //     }
    // }

    // async getCallAPI() {
    //     let res = await axios.get(window.api_host + '/test-call-sql');
    //     console.log(res.data)
    //     if (this.$testCallApiBtn.hasClass('loading')) {
    //         this.$testCallApiBtn.removeClass('loading')
    //     }
    // }



    // async check(text) {
    //     let res = await axios.post(window.api_host + '/test-password-checking', { password: text })
    //     // alert(english.test(text));
    //     console.log(res.data);
    // }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จ่ายเงิน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/promotion">จัดการ</Link></li>
                                    <li className="breadcrumb-item active">จ่ายเงิน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <button
                            className="btn btn-default"
                            ref={(ref) => {
                                this.$testCallApiBtn = window.$(ref);
                            }}
                            onClick={() => {
                                if (this.$testCallApiBtn.hasClass('loading')) { return; }
                                this.$testCallApiBtn.addClass('loading');
                                this.getCallAPI();
                            }}
                        >Test Call API</button>
                        <button
                            className="btn btn-default"
                            ref={(ref) => {
                                this.$callApiBtn = window.$(ref);
                            }}
                            onClick={() => {
                                if (this.$callApiBtn.hasClass('loading')) { return; }
                                this.$callApiBtn.addClass('loading');
                                this.callAPI();
                            }}
                        >Call API</button> */}
                        <button className="btn btn-default"
                            onClick={() => {
                                Helper.reactToDom(
                                    window.$('body'),
                                    <CreateAirDrop
                                        {...this.state.store}
                                        storeId={this.state.store.id}
                                    />
                                )
                            }}
                        >
                            Air Drip
                        </button>
                    </div>
                    {/* <form className="row"
                        ref={(ref) => {
                            this.$form = window.$(ref)
                            this.submit = this.$form.find('button[type="submit"]');
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.submit.hasClass('loading')) { return false; }
                            this.submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            this.check(data.password)
                        }}
                    >
                        <div className="form-group col-2">
                            <input name="password" className="form-control" />
                        </div>
                        <div className="form-group col-2">
                            <button className="btn btn-default" style={{ width: '100%' }}>ทดสอบ</button>
                        </div>
                    </form> */}
                </div>

            </>
        );
    }
}