import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../../../Helper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getStore } from '../../../services/StoreServices';
import { getExchange, getExchangeTransaction } from '../../../services/ExchangeServices';
const axios = require('axios').default;


export default class ArtistPayment extends React.Component {
    constructor() {
        super();
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        this.state = {
            currentUser: currentUser,
            is_loading: true,
            data: [],
            page: 1,
            limit: 10,
            artists: [],
            storeId: false,
            totalItems: 0,
            itemEnd: 0,
            is_done: true,
            statusId: false,
        }
    }
    firstTimeFetch() {
        if (this.state.data.length == 0) {
            this.setState({
                data: [],
                page: 1,
                totalItems: 0,
                itemEnd: 0,
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let storeId = this.state.storeId;
        let data = [...this.state.data];
        let page = 1;
        let is_done = true;
        let totalItems = 0;
        let itemEnd = 0;
        let filter = {}
        filter.page = this.state.page;
        filter.rows = this.state.limit;
        filter.artistId = this.props.uid;
        if (!storeId) {
            let storeRes = await getStore(this.props.storeuid);
            if (!storeRes.error) {
                filter.storeId = storeRes.id;
                storeId = storeRes.id;
            } else {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'fas fa-ban',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.setState({
                    is_loading: false,
                    storeId: false,
                    data: [],
                    page: 1,
                    is_done: true,
                    totalItems: 0,
                    itemEnd: 0,
                })
            }
        } else {
            filter.storeId = storeId;
        }
        let res = await getExchangeTransaction(filter);
        if (!res.error) {
            data = data.concat(res.items);
            page = res.page == res.endPage ? this.state.page : (this.state.page + 1);
            is_done = res.page == res.endPage;
            totalItems = res.total;
            itemEnd = res.itemEnd;
        }

        this.setState({
            is_loading: false,
            storeId: storeId,
            data: data,
            page: page,
            is_done: is_done,
            totalItems: totalItems,
            itemEnd: itemEnd,
        }, () => {
            if (this.$viewMore) {
                this.$viewMore.removeClass('loading')
            }
        })
    }
    loadMore() {
        this.fetch();
    }

    render() {
        return (
            <div className='card-body' style={{ position: 'relative' }}>
                {
                    this.state.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <>
                    <div className="row">
                        <div className="col-12 col-sm-10 col-md-6">
                            <div className="card">
                                <div className="card-body p-0">
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length === 0 ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                            : null
                                    }
                                    {
                                        this.state.is_loading ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                <div className="ui active inline loader small" ></div>
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.data.length > 0
                                            && !this.state.is_loading ?
                                            <>
                                                <ul className="nav nav-pills flex-column">
                                                    {
                                                        this.state.data.map((item, item_i) => {
                                                            return (
                                                                <li className="nav-item" key={item_i} style={item_i > 0 ? { borderTop: '1px solid rgba(0,0,0,.125)' } : null}>
                                                                    <div
                                                                        className="nav-link"
                                                                    >
                                                                        <div className="store-item item-row">
                                                                            <div className="description">
                                                                                <div><strong style={{ color: '#000' }}>{item.requestNumber}</strong></div>
                                                                                {
                                                                                    item.artists ?
                                                                                        <div>เบิกโดย : {item.artists.name}</div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    item.store ?
                                                                                        <div>ร้าน : {item.store.name}</div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div className="right-floated">
                                                                                {
                                                                                    item.pointAmount ?
                                                                                        <div className='d-flex align-items-center justify-content-end'>
                                                                                            {Helper.numberFormat(item.pointAmount)} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                                    <small>{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>

                                            </>
                                            : null
                                    }
                                </div>
                                {
                                    !this.state.is_done ?
                                        <div className="d-flex mt-2 align-items-center pr-2 pl-2 pt-2 pb-2">
                                            <button
                                                ref={(ref) => { this.$viewMore = window.$(ref); }}
                                                className="btn btn-sm btn-default"
                                                onClick={(ref) => {
                                                    if (this.$viewMore.hasClass('loading')) { return }
                                                    this.$viewMore.addClass('loading');
                                                    this.loadMore();
                                                }}
                                            >โหลดเพิ่ม</button> <span className='right-floated' style={{ color: '#333' }}>{this.state.itemEnd}/{this.state.totalItems}</span>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.is_done
                                        && this.state.data.length > 0 ?
                                        <div className="d-flex mt-2 align-items-center pr-2 pl-2 pt-2 pb-2">
                                            <span className='right-floated' stlye={{ color: '#333' }}>{this.state.itemEnd}/{this.state.totalItems}</span>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>

                </>
            </div>
        )
    }

}