import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Pagination from './../../Components/Pagination';
import { getUsers } from '../../services/UserServices';
// import { db } from '../../firebase';
const axios = require('axios').default;
const default_limit = 10;
export default class Member extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            timerage: 'รายปี',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_member_loading: true,
            data: [],
            count_all_item: 0,
            count_item: 0,
            provinces: Helper.getAllProvinces(),
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
            maxAge: Helper.getParameterByName('maxAge') ? Helper.getParameterByName('maxAge') : '',
            minAge: Helper.getParameterByName('minAge') ? Helper.getParameterByName('minAge') : '',
            gender: Helper.getParameterByName('gender') ? Helper.getParameterByName('gender') : '',
            isOnline: Helper.getParameterByName('isOnline') ? Helper.getParameterByName('isOnline') : false,
            member: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                timerage: 'รายปี',
                date: new Date().getTime(),
                year: Helper.getParameterByName('year') ? parseFloat(Helper.getParameterByName('year')) : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? parseFloat(Helper.getParameterByName('month')) : parseFloat(new Date().getMonth()),
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
                maxAge: Helper.getParameterByName('maxAge') ? Helper.getParameterByName('maxAge') : '',
                minAge: Helper.getParameterByName('minAge') ? Helper.getParameterByName('minAge') : '',
                gender: Helper.getParameterByName('gender') ? Helper.getParameterByName('gender') : '',
                isOnline: Helper.getParameterByName('isOnline') ? Helper.getParameterByName('isOnline') : false,
                chart: [],
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                timerage: 'รายปี',
                date: new Date().getTime(),
                year: parseFloat(new Date().getFullYear()),
                month: parseFloat(new Date().getMonth()),
                is_loading: true,
                page: 1,
                limit: default_limit,
                keyword: '',
                province_code: '',
                maxAge: Helper.getParameterByName('maxAge') ? Helper.getParameterByName('maxAge') : '',
                minAge: Helper.getParameterByName('minAge') ? Helper.getParameterByName('minAge') : '',
                gender: Helper.getParameterByName('gender') ? Helper.getParameterByName('gender') : '',
                isOnline: Helper.getParameterByName('isOnline') ? Helper.getParameterByName('isOnline') : false,
                data: [],
                chart: [],
            }, () => {
                this.fetch();
            })
        }
        if (this.props.member_uid && this.props.member_uid !== prevProps.member_uid) {
            this.setState({
                is_member_loading: true,
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let qs = {
            uid: this.props.uid,
            page: this.state.page,
            limit: this.state.limit,
            keyword: this.state.keyword,
            province_code: this.state.province_code,
            gender: this.state.gender,
            maxAge: this.state.maxAge,
            minAge: this.state.minAge,
            isOnline: this.state.isOnline,
        }
        if (this.props.member_uid) {
            qs.member_uid = this.props.member_uid
        }
        let res = await getUsers(this.state.page, this.state.limit, qs.gender, qs.minAge, qs.maxAge, qs.keyword, qs.isOnline, this.props.uid)
        // let res = await axios.get(window.api_host + '/store-user?' + window.$.param(qs));
        let data = [];
        let member = false;
        let count_all_item = 0;
        let count_item = 0;
        let count_average_per_day = 0;
        let count_today = 0;
        if (!res.error) {
            data = res.items;
            count_item = res.total;
            count_all_item = res.total;
        }

        this.setState({
            is_loading: false,
            is_member_loading: false,
            data: data,
            count_average_per_day: count_average_per_day,
            count_today: count_today,
            count_all_item: count_all_item,
            count_item: count_item,
            member: member,
        })
    }

    render() {
        // console.log(this.state.data.photoPath)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สมาชิก </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">สมาชิก</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                {/* FILTER */}
                                <div className="card card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ display: 'block' }}>
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    ...data
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-6 form-group">
                                                <label>ค้นหา</label>
                                                <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อสมาชิก" />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>เพศ</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"gender"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.province_code || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    <option value={"1"}>
                                                        ชาย
                                                    </option>
                                                    <option value={"2"}>
                                                        หญิง
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='col-12'>
                                                <label>
                                                    <input type="checkbox" name="isOnline" value={true} defaultChecked={this.state.isOnline} />
                                                    <span className={"ml-2"}>ออนไลน์</span>
                                                </label>
                                            </div>
                                            <div className="col-12 form-group">
                                                <label>อายุ</label>
                                                <input type="text"
                                                    className="js-range-slider"
                                                    // name="age-range"
                                                    data-type="double"
                                                    data-min="0"
                                                    data-max="100"
                                                    data-from={this.state.minAge}
                                                    data-to={this.state.maxAge}
                                                    data-grid="true"
                                                    ref={(ref) => {
                                                        window.$(ref).ionRangeSlider({
                                                            onChange: (e) => {
                                                                this.$minAge.val(e.from);
                                                                this.$maxAge.val(e.to);
                                                            }
                                                        });
                                                    }}

                                                />
                                                <input type={"hidden"} ref={(ref) => { this.$minAge = window.$(ref) }} name="minAge" value={this.state.minAge} />
                                                <input type={"hidden"} ref={(ref) => { this.$maxAge = window.$(ref) }} name="maxAge" value={this.state.maxAge} />
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12 col-md-4"}>
                                {/* LIST */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการสมาชิก
                                            <div className="flex row">
                                                <small>สมาชิกทั้งหมด</small>
                                                <small className="right-floated">
                                                    {Helper.numberFormat(this.state.count_all_item)}
                                                </small>
                                            </div>
                                        </h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((item, item_i) => {
                                                                return (
                                                                    <li className="nav-item" key={item_i}>
                                                                        <Link to={"/member/view/" + item.fireBaseUid} className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                <div className="icon">
                                                                                    <div className="user-image-frame">
                                                                                        <img
                                                                                            src={item.userImage || "/assets/images/no_img.png"}
                                                                                            alt="User profile" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="description">
                                                                                    <div>{item.displayName}</div>
                                                                                    <div>
                                                                                        {
                                                                                            item.isOnline ?
                                                                                                <span className="badge badge-success">ออนไลน์</span>
                                                                                                :
                                                                                                <span className="badge badge-secondary">ออฟไลน์</span>
                                                                                        }
                                                                                        {
                                                                                            item.isArrived ?
                                                                                                <span className="badge badge-success" style={{ marginLeft: 5 }}>อยู่ในร้าน</span>
                                                                                                :
                                                                                                <span className="badge badge-secondary" style={{ marginLeft: 5 }}>ไม่อยู่ในร้าน</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    <div className="small-text">{Helper.getDateThai(new Date(item.createdAt).getTime())}</div>
                                                                                    <div className="small-text">{Helper.getTime(new Date(item.createdAt).getTime())}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.count_all_item}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-3">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">
                                            ข้อมูลจำนวนสมาชิก
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="flex row">
                                            <label>สมาชิกทั้งหมด</label>
                                            <div className="right-floated">
                                                {Helper.numberFormat(this.state.count_all_item)}
                                            </div>
                                        </div>
                                        <div className="flex row">
                                            <label>สมาชิกวันนี้</label>
                                            <div className="right-floated">
                                                {Helper.numberFormat(this.state.count_today)}
                                            </div>
                                        </div>
                                        <div className="flex row">
                                            <label>สมาชิกเฉลี่ยต่อวัน</label>
                                            <div className="right-floated">
                                                {Helper.numberFormat(parseFloat(this.state.count_average_per_day).toFixed(1))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
