import React from 'react';
import { Helper } from '../Helper';
import CustomDateInput from '../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import { getSetting } from '../services/SettingsServices';
import { sendAridrop } from '../services/DistributeServices';
import { getStore } from '../services/StoreServices';
const minAmount = 0;
export default class AirDrop extends React.Component {
    constructor() {
        super();
        let date = new Date();
        this.state = {
            is_loading: true,
            isConfirmed: false,
            hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            minutes: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
            settings: false,
            store: false,

        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let store = false;
        let _store = await getStore(this.props.uid);
        if (!_store.error) {
            store = _store;
        }
        let settings = await getSetting();
        this.setState({
            settings: settings,
            store: store,
            is_loading: false,
        })
    }

    renderConfirm() {
        let price = 0;
        let paymentPrice = 0;
        let chargePrice = 0;
        price = this.state.confirmData.amount * this.state.confirmData.quantity;
        chargePrice = (price * this.state.settings.airDropServiceCharge) / 100;
        chargePrice = Math.ceil(chargePrice);
        paymentPrice = price + chargePrice;
        return (
            <>
                <div >
                    <div className="form-group">
                        <label style={{ marginBottom: 0 }}>Unit Value</label>
                        <p>{Helper.numberFormat(this.state.confirmData.amount)}</p>
                    </div>
                    <div className="form-group">
                        <label style={{ marginBottom: 0 }}>Quantity</label>
                        <p>{Helper.numberFormat(this.state.confirmData.quantity)}</p>
                    </div>
                    <hr />
                    <div className="form-group">
                        <label style={{ marginBottom: 0 }}>Total Price</label>
                        <p>{Helper.numberFormat(price)}</p>
                    </div>
                    <div className="form-group">
                        <label style={{ marginBottom: 0 }}>Service charge {this.state.settings.airDropServiceCharge}%</label>
                        <p>{Helper.numberFormat(chargePrice)}</p>
                    </div>
                    <div className="form-group">
                        <label style={{ marginBottom: 0 }}>Total</label>
                        <p>{Helper.numberFormat(paymentPrice)}</p>
                    </div>
                    <hr />
                    {
                        this.state.confirmData.now_checkbox ?
                            <div className="form-group">
                                <label>Start Date</label>
                                <p>Now</p>
                            </div>
                            :
                            <div className="form-group">
                                <label>Start Date</label>
                                <p>{`${Helper.getDateThai(this.state.defaultValue.date)} ${this.state.confirmData.hour}:${this.state.confirmData.minute}`}</p>
                            </div>
                    }
                </div>
            </>
        )
    }

    onInputChange(e) {
        let node = window.$(e.target);
        let name = e.target.name;
        if (!e.target.value || (e.target.value && parseFloat(e.target.value) && parseFloat(e.target.value) < minAmount)) {
            node.val(minAmount)
            return;
        }
        if (name == 'amount') {
            if (e.target.value && this.props.currentCoin && parseFloat(e.target.value) && parseFloat(e.target.value) > parseFloat(this.props.currentCoin)) {
                node.val(this.props.currentCoin)
                return;
            }
        }
        node.val(parseFloat(e.target.value))

    }


    async sendAirDrop() {
        let data = {};
        data.amount = this.state.confirmData.amount;
        data.quantity = this.state.confirmData.quantity;
        let now = new Date();
        let hour = now.getHours();
        if (parseFloat(hour) < 10) {
            hour = `0${hour}`;
        }
        let minute = now.getMinutes();
        if (parseFloat(minute) < 10) {
            minute = `0${minute}`;
        }
        let nowCustom = Helper.SkitzformatDateNumber(now) + hour + minute
        if (!this.state.confirmData.now_checkbox) {
            data.startTime = this.state.confirmData.date + this.state.confirmData.hour + this.state.confirmData.minute;
            if (data.startTime && (parseFloat(data.startTime) < parseFloat(nowCustom))) {
                window.$(document).Toasts('create', {
                    title: 'ไม่สามารถส่ง Airdrop ได้',
                    body: 'ไม่สามารถส่ง Airdrop ย้อนหลังได้',
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.setState({
                    isConfirmed: false,
                }, () => {
                    this.$submit.removeClass('loading');
                })
                return;
            }
        } else {
            data.startTime = nowCustom
        }
        data.storeId = this.state.store.id;
        let res = await sendAridrop(data);
        if (res.error) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถส่ง Airdrop ได้',
                body: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        } else {
            window.$(document).Toasts('create', {
                title: 'ส่ง Airdrop สำเร็จ',
                body: 'ระบบได้ทำการส่ง Airdrop ไปยังผู้ใช้งานที่อยู่ในร้านเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })

            this.$modal.modal('hide');
        }
    }


    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            if (this.props.onCancel) {
                                this.props.onCancel();
                            }
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]')
                    }}
                >
                    <form
                        className="modal-dialog"
                        role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (!data.amount
                                || (data.amount && data.amount <= 0)
                                || !data.quantity
                                || (data.quantity && data.quantity <= 0)
                            ) {
                                Helper.messageTop({ message: 'กรุณากรอกจำนวนมากกว่า 0' });
                                this.$submit.removeClass('loading');
                                return;
                            }
                            if (!data.now_checkbox && !data.date) {
                                Helper.messageTop({ message: 'กรุณาเลือกวันที่' });
                                this.$submit.removeClass('loading');
                                return;
                            }
                            if (!this.state.isConfirmed) {
                                this.setState({
                                    confirmData: { ...data },
                                    isConfirmed: true,
                                }, () => {
                                    this.$submit.removeClass('loading');
                                })
                                return;
                            }
                            if (this.state.isConfirmed) {
                                if (!this.state.confirmData) {
                                    Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' });
                                    this.setState({
                                        isConfirmed: false,
                                    }, () => {
                                        this.$submit.removeClass('loading');
                                    })
                                    return;
                                }
                                this.sendAirDrop()
                            }
                        }}
                    >
                        <div className="modal-content">

                            {
                                this.state.is_loading ?
                                    <div className={"absolute_loading"}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            <div className="modal-header d-flex align-items-center">
                                <h4 className="modal-title" id="myModalLabel">
                                    AirDrop
                                </h4>
                                <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', borderRight: '1px solid #f4f4f4', alignItems: 'center' }}>
                                        {this.state.store && this.state.store.currentCoin ? Helper.numberFormat(this.state.store.currentCoin) : 0}
                                        <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 20, marginLeft: 5 }} />
                                    </span>
                                    <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center' }}>
                                        {this.state.store && this.state.store.currentPoint ? Helper.numberFormat(this.state.store.currentPoint) : 0}
                                        <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.isConfirmed ?
                                        this.renderConfirm() : null
                                }
                                <div style={{ display: this.state.isConfirmed ? 'none' : 'block' }}>
                                    <div className="form-group">
                                        <label>Unit Value</label>
                                        <input type='number' name="amount" className="form-control" required={true} defaultValue={minAmount} onChange={(e) => { this.onInputChange(e) }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <input type='number' name="quantity" className="form-control" required={true} defaultValue={minAmount} onChange={(e) => { this.onInputChange(e) }} />
                                    </div>

                                    <div id="checkbox">
                                        <label>
                                            <input
                                                ref={(ref) => { this.$checkbox = window.$(ref) }}
                                                onChange={(e) => {
                                                    let node = window.$(e.target)
                                                    if (node[0]) {
                                                        let is_checked = node[0].checked;
                                                        if (!is_checked) {
                                                            this.$dateForm.slideDown();
                                                            let defaultValue = { ...this.state.defaultValue }
                                                            let now = new Date();
                                                            let hour = now.getHours();
                                                            if (parseFloat(hour) < 10) {
                                                                hour = `0${hour}`;
                                                            }
                                                            let minute = now.getMinutes();
                                                            if (parseFloat(minute) < 10) {
                                                                minute = `0${minute}`;
                                                            }

                                                            defaultValue.date = now.getTime();
                                                            defaultValue.hour = hour;
                                                            defaultValue.minute = minute;
                                                            this.setState({
                                                                defaultValue: defaultValue
                                                            }, () => {
                                                                this.$selectHours.val(hour).trigger('change');
                                                                this.$selectMinute.val(minute).trigger('change');
                                                            })
                                                        } else {
                                                            this.$dateForm.slideUp();
                                                        }

                                                    }
                                                }}
                                                type={"checkbox"}
                                                name="now_checkbox"
                                                value={true}
                                                defaultChecked={true}
                                            /> Now
                                        </label>

                                    </div>
                                    <div ref={(ref) => { this.$dateForm = window.$(ref) }} className="form-group" style={{ display: 'none' }}>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <label>Start Date</label>
                                                <DatePicker
                                                    onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                    onChange={date => {
                                                        let _date = new Date(date);
                                                        let defaultValue = { ...this.state.defaultValue };
                                                        defaultValue.date = _date.getTime();
                                                        this.setState({
                                                            defaultValue: defaultValue,
                                                        })
                                                    }}
                                                    minDate={Helper.getDateToEdit(new Date().getTime())}
                                                    locale="th-TH"
                                                    selected={this.state.defaultValue && this.state.defaultValue.date ? Helper.getDateToEdit(this.state.defaultValue.date) : false}
                                                    placeholderText={"วัน / เดือน / ปี"}
                                                    name={"date"}
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    withPortal
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled
                                                    }) => {
                                                        let now = new Date();
                                                        let selected_date = new Date(date);
                                                        // months_th
                                                        const years = [];
                                                        let i;
                                                        for (i = 1980; i <= now.getFullYear(); i++) {
                                                            years.push(i)
                                                        }
                                                        return (
                                                            <div
                                                                style={{
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                    className={"btn btn-primary "}
                                                                    type={"button"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                >
                                                                    <i className="fas fa-chevron-left" />
                                                                </button>
                                                                <select
                                                                    className={"form-control"}
                                                                    value={selected_date.getFullYear()}
                                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option} >
                                                                            {option + 543}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <select
                                                                    className={"form-control"}
                                                                    value={window.months_th[selected_date.getMonth()]}
                                                                    onChange={({ target: { value } }) =>
                                                                        changeMonth(window.months_th.indexOf(value))
                                                                    }
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {window.months_th.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                    className={"btn btn-primary"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                    type={"button"}
                                                                >
                                                                    <i className="fas fa-chevron-right" />
                                                                </button>
                                                            </div>
                                                        )
                                                    }}
                                                    customInput={<CustomDateInput />}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <label>Hour</label>
                                                <select ref={(ref) => { this.$selectHours = window.$(ref); window.$(ref).select2() }} className='form-control select2' name="hour" style={{ width: '100%' }}
                                                    defaultValue={this.state.defaultValue && this.state.defaultValue.hour ? this.state.defaultValue.hour : false}
                                                >
                                                    {
                                                        this.state.hours.map((hour, i) => {
                                                            return <option value={hour} key={i}>{hour}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-3'>
                                                <label>Minute</label>
                                                <select ref={(ref) => { this.$selectMinute = window.$(ref); window.$(ref).select2() }} className='form-control select2' name="minute" style={{ width: '100%' }}
                                                    defaultValue={this.state.defaultValue && this.state.defaultValue.minute ? this.state.defaultValue.minute : false}
                                                >
                                                    {
                                                        this.state.minutes.map((minute, i) => {
                                                            return <option value={minute} key={i}>{minute}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <input type="hidden" id="date" name="date" value={this.state.defaultValue && this.state.defaultValue.date ? Helper.SkitzformatDateNumber(this.state.defaultValue.date) : false} onChange={() => { console.log('change') }} readOnly={true} />

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="flex row" style={{ width: '100%' }}>
                                    <div type={"button"} className="btn btn-default"
                                        onClick={() => {
                                            if (this.state.isConfirmed) {
                                                this.setState({
                                                    isConfirmed: false
                                                })
                                            } else {
                                                this.$modal.modal('hide');
                                            }
                                        }}
                                    >
                                        {
                                            this.state.isConfirmed ?
                                                "ยกเลิก"
                                                : "ปิด"
                                        }
                                    </div>
                                    <button type="submit" className="btn btn-primary" style={{ marginLeft: 'auto' }}>

                                        {
                                            this.state.isConfirmed ?
                                                "ยืนยันส่ง AirDrop"
                                                : "ดำเนินการ"
                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}