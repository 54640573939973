import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../Helper';
import Pagination from './../../Components/Pagination';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostItem from '../../Components/PostItem';
import { getPaymentTransaction } from './../../services/PaymentServices';
import MemberPayment from './include/MemberPayment';
import { getUser } from '../../services/UserServices';
const axios = require('axios').default;
export default class View extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            is_user_loading: true,
            users: [],
            follower: [],
            following: [],
            lives: [],
            posts: [],
            transections: [],
            mashing: [],
            friends: 0,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.uid !== prevProps.uid) {
            this.setState({
                is_loading: true,
                data: false,
                is_user_loading: true,
                users: [],
                follower: [],
                following: [],
                lives: [],
                posts: [],
                transections: [],
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/user', {
            data: {
                uid: this.props.member_uid
            }
        });
        let user = false;
        if (res.data.success && res.data.response.user) {
            user = res.data.response.user
        }
        let follower = [];
        if (res.data.success && res.data.response.follower) {
            follower = res.data.response.follower;
        }
        let following = [];
        if (res.data.success && res.data.response.following) {
            following = res.data.response.following;
        }
        let lives = [];
        if (res.data.success && res.data.response.lives) {
            lives = res.data.response.lives;
        }
        let posts = [];
        let transections = [];
        if (res.data.success && res.data.response.transection) {
            transections = res.data.response.transection;
        }
        let mashing = [];

        let friends = 0;
        if (res.data.success && res.data.response.mashing) {
            mashing = res.data.response.mashing;
            friends = res.data.response.mashing.length;
        }
        if (user) {
            let res = await getUser(user.uid);
            if (!res.error) {
                user.user = res;
                user.isOnline = res.isOnline;
            }
        }
        this.setState({
            data: user,
            follower: follower,
            following: following,
            mashing: mashing,
            lives: lives,
            posts: posts,
            transections: transections,
            is_loading: false,
            friends: friends,
        }, () => {
            if (this.state.data) {
                this.memberCheckIn.fetchPosts();
                this.memberCheckIn.fetchLives();
            }
        })
    }

    banUser() {
        Helper.confirmModal({
            title: `แบนผู้ใช้งาน`,
            description: `ยืนยันการแบนผู้ใช้งาน`,
            onConfirm: async () => {
                let data = {};
                data.uid = this.props.uid;
                data.adminUid = this.props.adminUid;
                let res = await axios.post(`${window.api_host}/user-ban`, { ...data });
                // console.log(res.data);
                if (res.data.success) {
                    this.setState({
                        data: res.data.response.user,
                    }, () => {
                        Helper.messageTop({ message: "แบน User นี้แล้ว", type_class: 'alert-success' })
                    })
                }
                if (res.data.error) {
                    Helper.messageTop({ message: res.data.message })
                }
            }
        })
    }

    unBanUser() {
        Helper.confirmModal({
            title: `ปลดแบนผู้ใช้งาน`,
            description: `ยืนยันการปลดแบนผู้ใช้งาน`,
            onConfirm: async () => {
                let data = {};
                data.uid = this.props.uid;
                data.adminUid = this.props.adminUid;
                let res = await axios.post(`${window.api_host}/user-unban`, { ...data });
                // console.log(res.data);
                if (res.data.success) {
                    this.setState({
                        data: res.data.response.user,
                    }, () => {
                        Helper.messageTop({ message: "ปลดแบน User นี้แล้ว", type_class: 'alert-success' })
                    })
                }
                if (res.data.error) {
                    Helper.messageTop({ message: res.data.message })
                }
            }
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สมาชิก </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/member">สมาชิก</Link></li>
                                    <li className="breadcrumb-item active">ดูข้อมูล</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.is_loading ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                {
                    !this.state.is_loading
                        && this.state.data ?
                        <section className={"content"}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className={"col-12 col-md-3"}>
                                        {/* STORE INFO */}
                                        <div className="card card-outline">
                                            <div className="card-body box-profile">
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data ?
                                                        <>
                                                            <div className={`flex row`}>
                                                                {
                                                                    this.state.data.isBanned ?
                                                                        <div className={`flex row`} style={{ alignItems: `center` }}>
                                                                            <i className="fas fa-circle" style={{ color: 'red', marginRight: 5 }} /> ถูกแบน
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className={`right-floated`}>
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-tools dropdown-toggle"
                                                                            type="button"
                                                                            id="dropdownMenuButton"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                            style={{ padding: 0 }}
                                                                        >
                                                                            <i className="fas fa-bars"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                                                            {
                                                                                !this.state.data.isBanned ?
                                                                                    <button
                                                                                        type="button"
                                                                                        className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            this.banUser()
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-circle" style={{ color: 'red' }} /> แบน
                                                                                    </button>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.data.isBanned ?
                                                                                    <button
                                                                                        type="button"
                                                                                        className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            this.unBanUser()
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-circle" style={{ color: 'green' }} /> ปลดแบน
                                                                                    </button>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <div className="profile-user-img img-frame img-fluid img-circle">
                                                                    <img
                                                                        src={this.state.data && this.state.data.photoURL ? this.state.data.photoURL : "/assets/images/no_img.png"}
                                                                        alt="User profile" />
                                                                </div>
                                                            </div>
                                                            <h3 className="profile-username text-center">{this.state.data.displayName || '-'}</h3>
                                                            <div className="text-muted text-center">
                                                                {this.state.data.messageStatus || '-'}<br />
                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                                    <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', borderRight: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                                        {Helper.numberFormat(this.state.data.user.currentCoin)}
                                                                        <div style={{ display: 'inline-flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: "orange", overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                                            <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>C</span>
                                                                        </div>
                                                                    </span>
                                                                    <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                                        {Helper.numberFormat(this.state.data.user.currentPoint)}
                                                                        <div style={{ display: 'inline-flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: "#50AFFF", overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                                            <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>P</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <ul className="list-group list-group-unbordered mb-3 mt-2">
                                                                <li className="list-group-item">
                                                                    <b>Follower</b> <div className="float-right">{this.state.follower ? Helper.numberFormat(this.state.follower.length) : Helper.numberFormat(0)}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Following</b> <div className="float-right">{this.state.following ? Helper.numberFormat(this.state.following.length) : Helper.numberFormat(0)}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Friends</b> <div className="float-right">{Helper.numberFormat(this.state.friends)}</div>
                                                                </li>
                                                            </ul>
                                                            <div className="flex row">
                                                                <div>
                                                                    {
                                                                        this.state.data
                                                                            && this.state.data.isOnline ?
                                                                            <span className="badge badge-success">ออนไลน์</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.data
                                                                            && !this.state.data.isOnline ?
                                                                            <span className="badge badge-secondary">ออฟไลน์</span>
                                                                            : null
                                                                    }
                                                                </div>
                                                                <div className="right-floated">
                                                                    {
                                                                        this.state.data && this.state.data.user && this.state.data.user.currentStore && this.state.data.isOnline ?
                                                                            <>{this.state.data.user.currentStore}
                                                                                {/* <span className="badge badge-secondary">{Helper.getTime(new Date(this.state.data.onlineTime))}</span> */}
                                                                            </>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                        <div className="card">
                                            {/* <div className="card-header">
                                        <div className="card-title">ข้อมูลทั่วไป</div>
                                    </div> */}
                                            <div className="card-body">
                                                <div className="flex row">
                                                    <div><strong>เบอร์โทรศัพท์</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.phoneNumber ? this.state.data.phoneNumber.replace('+66', '0') : '-'}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>วัน/เดือน/ปีเกิด</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.birthDate ? Helper.getDateThai(new Date(this.state.data.birthDate)) : '-'}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>อายุ</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.age && this.state.data.age !== undefined ? this.state.data.age : '-'}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>เพศ</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.sex && this.state.data.sex === 'man' ? "ชาย" : null}
                                                        {this.state.data && this.state.data.sex && this.state.data.sex === 'woman' ? "หญิง" : null}
                                                        {
                                                            !this.state.data
                                                                || !this.state.data.sex ? '-' : null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>สถานะ</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.relationshipStatus && this.state.data.relationshipStatus === 'single' ? "โสด" : '-'}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>อาชีพ</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.job ? this.state.data.job : "-"}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>การเรียน</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.learning && this.state.data.learning === 'studying' ? "กำลังศึกษา" : null}
                                                        {this.state.data && this.state.data.learning && this.state.data.learning === 'studied' ? "จบการศึกษา" : null}
                                                        {
                                                            !this.state.data
                                                                || !this.state.data.learning ? '-' : null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div><strong>จาก</strong></div>
                                                    <div className="right-floated">
                                                        {this.state.data && this.state.data.learnFrom ? this.state.data.learnFrom : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="card card-primary card-outline card-outline-tabs">
                                            <div className="card-header p-0 border-bottom-0">
                                                <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active"
                                                            id="custom-tabs-three-information-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-three-information"
                                                            role="tab"
                                                            aria-controls="custom-tabs-three-information"
                                                            aria-selected="false"
                                                        >
                                                            <h5>ข้อมูลสมาชิก</h5>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                            id="custom-tabs-three-checkin-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-three-checkin"
                                                            role="tab"
                                                            aria-controls="custom-tabs-three-checkin"
                                                            aria-selected="false"
                                                        // onClick={() => {
                                                        //     this.memberCheckIn.fetchPosts();
                                                        //     this.memberCheckIn.fetchLives();
                                                        // }}
                                                        >
                                                            <h5>ข้อมูลการใช้งาน</h5>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                            id="custom-tabs-three-payment-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-three-payment"
                                                            role="tab"
                                                            aria-controls="custom-tabs-three-payment"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.memberPayment.fetch();
                                                            }}
                                                        >
                                                            <h5>ข้อมูลการเงิน</h5>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="tab-content" id="custom-tabs-three-tabContent">
                                                <div className="tab-pane fade active show" id="custom-tabs-three-information" role="tabpanel" aria-labelledby="custom-tabs-three-information-tab">
                                                    <div className="card-body">
                                                        <MemberInformation
                                                            user={this.state.data}
                                                            follower={this.state.follower}
                                                            is_loading={this.state.is_loading}
                                                            adminUid={this.props.adminUid}
                                                            updateUser={(user) => {
                                                                console.log("updateUser", user)
                                                                this.setState({
                                                                    data: user
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade " id="custom-tabs-three-checkin" role="tabpanel" aria-labelledby="custom-tabs-three-checkin-tab">
                                                    <MemberCheckIn
                                                        ref={(ref) => { this.memberCheckIn = ref; }}
                                                        user={this.state.data}
                                                        posts={this.state.posts}
                                                        lives={this.state.lives}
                                                        is_loading={this.state.is_loading}
                                                        uid={this.props.uid}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="custom-tabs-three-payment" role="tabpanel" aria-labelledby="custom-tabs-three-payment-tab">
                                                    <MemberPayment
                                                        ref={(ref) => { this.memberPayment = ref; }}
                                                        user={this.state.data}
                                                        uid={this.props.uid}
                                                        is_loading={this.state.is_loading}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : null
                }
                {
                    !this.state.is_loading
                        && !this.state.data ?
                        <section className={"content"}>
                            <div className="container-fluid">
                                <h3>ไม่มีข้อมูล</h3>
                            </div>
                        </section>
                        : null
                }
            </>
        );
    }
}

class MemberCheckIn extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            posts: [],
            lives: [],
            page_posts: 1,
            limit_posts: 50,
            page_lives: 1,
            limit_lives: 5,
            live_all: 0,
            post_all: 0,
            years: years,
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_loading_posts: true,
            is_loading_lives: true,
            timerage: 'รายเดือน',
            join_stores: [],
            store_selected: false,
        }
    }

    async fetchPosts() {
        let data = [];
        let data_request = {};
        data_request.lang = 'th';
        data_request.userUid = this.props.user.uid;
        data_request.postBy = this.state.postBy;
        data_request.page = this.state.page_posts;
        data_request.limit = parseFloat(this.state.limit_posts);
        data_request.timerage = this.state.timerage;
        let start_date = false;
        let end_date = false;
        if (this.state.timerage === 'รายปี') {
            // alert(this.state.year)
            start_date = new Date(this.state.year, 0, 1);
            end_date = new Date(this.state.year + 1, 0, 1);

            data_request.start_date = Helper.formatDate(start_date);
            data_request.end_date = Helper.formatDate(end_date);
        }
        if (this.state.timerage === 'รายเดือน') {
            // alert(this.state.year, this.state.month)
            let selected_month = parseFloat(this.state.month);
            start_date = new Date(this.state.year, selected_month, 1);
            end_date = new Date(this.state.year, selected_month + 1, 1);

            data_request.start_date = Helper.formatDate(start_date);
            data_request.end_date = Helper.formatDate(end_date);
        }
        if (this.state.timerage === 'รายวัน') {
            // alert(this.state.date)
            let date = new Date(this.state.date);
            start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

            data_request.start_date = Helper.formatDate(start_date);
            data_request.end_date = Helper.formatDate(end_date);
        }
        // data.storeUid = 1;
        let count_all = 0;
        let posts_ref = await axios.post(window.api_host + '/get-store-posts', { ...data_request });
        // console.log(posts_ref)
        if (posts_ref.data.success) {
            data = posts_ref.data.res;
        }
        let return_data = {};
        return_data.item = this.state.posts.concat(data);
        return_data.count = data.length;
        let is_show_more_btn = data.length >= this.state.limit_posts;
        this.setState({
            posts: return_data.item,
            post_all: 0,
            is_loading_posts: false,
            is_show_more_btn: is_show_more_btn,
        })
    }

    async fetchLives() {
        let data = {};
        data.userUid = this.props.user.uid;
        if (this.state.store_selected && this.state.store_selected !== '') {
            data.storeUid = this.state.store_selected;
        }
        data.offset = 0;
        if (this.state.page_lives > 1) {
            data.offset = (this.state.page_lives - 1) * this.state.limit_lives;
        }
        data.limit = this.state.limit_lives;
        let res = await axios.get(window.api_host + '/get-user-joinstore?' + window.$.param(data));
        // console.log(res.data);
        let lives = [];
        let live_all = 0;
        let join_stores = [];
        if (res.data.response) {
            lives = this.state.lives.concat(res.data.response.data);
            live_all = res.data.response.count_all;
            join_stores = res.data.response.join_store;
        }
        this.setState({
            lives: lives,
            live_all: live_all,
            join_stores: join_stores,
            is_loading_lives: false
        })
    }



    render() {
        return (
            <ul className="list-group list-group-flush" style={{ position: 'relative' }}>

                <li className="list-group-item" style={{ border: 0 }}>

                    <div className="row">
                        <div className="col-12 col-md-3">
                            {/* <label>วันที่</label> */}
                            {/* <div className="flex row merge-selectbox">
                                <select className={"form-control"}
                                    name={"month"}
                                    style={{ flex: 1 }}
                                    onChange={(e) => {
                                        this.setState({
                                            month: e.target.value,
                                            is_loading_posts: true
                                        }, () => {
                                            this.fetchPosts();
                                        })
                                    }}
                                    defaultValue={this.state.month}
                                >
                                    {
                                        window.months_th.map((month, month_i) => (
                                            <option value={month_i} key={month_i}>{month}</option>
                                        ))
                                    }
                                </select>
                                <select className={"form-control"}
                                    style={{ flex: 1 }}
                                    name={"year"}
                                    onChange={(e) => {
                                        this.setState({
                                            year: e.target.value,
                                            is_loading_posts: true
                                        }, () => {
                                            this.fetchPosts();
                                        })
                                    }}
                                    defaultValue={this.state.year}
                                >
                                    {
                                        this.state.years.map((years) => (
                                            <option value={years} key={years}>{years + 543}</option>
                                        ))
                                    }
                                </select>
                            </div> */}
                        </div>
                        <div className="col-12 col-md-3" />
                        <div className="col-12 col-md-3">
                            <label>ร้าน</label>
                            <select className="form-control"
                                onChange={(e) => {
                                    let store_selected = false;
                                    if (e.target.value) {
                                        store_selected = e.target.value;
                                    }
                                    this.setState({
                                        lives: [],
                                        page_lives: 1,
                                        is_loading_lives: true,
                                        store_selected: store_selected
                                    }, () => {
                                        this.fetchLives();
                                    })
                                }}
                            >
                                <option value="">ทั้งหมด</option>
                                {
                                    this.state.join_stores.length > 0 ?
                                        this.state.join_stores.map((join_store, join_store_i) => {
                                            return (
                                                <option value={join_store.uid} key={join_store_i}>{join_store.store.name}</option>
                                            )
                                        })
                                        : null
                                }
                            </select>
                        </div>
                    </div>

                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col-12 col-md-6" style={{ position: 'relative' }}>
                            {
                                this.state.is_loading_posts ?
                                    <div className={"absolute_loading"}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            <div className="flex row flex-center mb-3">
                                <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>โพส
                                    <div className="badge badge-primary" style={{ marginLeft: 10 }}>
                                        {Helper.numberFormat(this.state.post_all)}
                                    </div>
                                </h5>
                            </div>
                            <div className="timeline">
                                {
                                    this.state.posts.length > 0 ?
                                        this.state.posts.map((item, item_i) => {
                                            item.userImage = this.props.user.photoURL;
                                            return (
                                                <React.Fragment
                                                    key={item_i}
                                                >
                                                    <PostItem
                                                        item={item}
                                                        index={item_i}
                                                        onDeleteSuccess={() => {
                                                            this.setState({
                                                                is_loading_posts: true
                                                            }, () => {
                                                                this.fetchPosts();
                                                            })
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )
                                        }) : null
                                }

                                <div>
                                    {
                                        this.state.is_show_more_btn ?
                                            <i className="fas fa-angle-down bg-gray"
                                                onClick={() => {
                                                    this.setState({
                                                        is_loading_posts: true,
                                                        page_posts: this.state.page_posts + 1,
                                                    }, () => {
                                                        this.fetchPosts();
                                                    })
                                                }}
                                            />
                                            :
                                            <i className="fas fa-minus bg-gray" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">

                            {
                                this.state.is_loading_lives ?
                                    <div className={"absolute_loading"}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            <div className="flex row flex-center mb-3">
                                <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>เข้าร้าน
                                    <div className="badge badge-info" style={{ marginLeft: 10 }}>
                                        {Helper.numberFormat(this.state.live_all)}
                                    </div>
                                </h5>
                            </div>
                            <div className="timeline">
                                {
                                    this.state.lives.map((item, item_i) => {
                                        return (
                                            <div key={item_i}>
                                                <i className="fas fa-store bg-info"></i>
                                                <div className="timeline-item">
                                                    <span className="time"><i className="far fa-calendar-alt"></i> {Helper.getDateThai(item.createdAt)}</span>
                                                    <h3 className="timeline-header">{item.store.name}</h3>
                                                    <div className="timeline-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="flex row">
                                                                    <div>
                                                                        <strong>
                                                                            {item.status === 'login' ? 'เข้าร้าน' : null}
                                                                            {item.status === 'logout' ? 'ออกร้าน' : null}
                                                                        </strong>
                                                                    </div>
                                                                    <div className="right-floated">
                                                                        {Helper.getTime(item.createdAt)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div>
                                    {
                                        (this.state.page_lives * this.state.limit_lives) < this.state.live_all ?
                                            <i className="fas fa-angle-down bg-gray"
                                                onClick={() => {
                                                    this.setState({
                                                        is_loading_lives: true,
                                                        page_lives: this.state.page_lives + 1,
                                                    }, () => {
                                                        this.fetchLives();
                                                    })
                                                }}
                                            />
                                            :
                                            <i className="fas fa-minus bg-gray" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        );
    }
}

class MemberInformation extends React.Component {
    constructor(props) {
        super(props);
        let default_image = [];
        if (props.user && props.user.photoPath) {
            default_image = props.user.photoPath;
        }
        let default_follower = [];
        if (props.follower) {
            default_follower = props.follower;
        }
        this.state = {
            images: [],
            is_loading: true,
            page_follow: 1,
            limit_follow: 15,
            page_image: 1,
            limit_image: 12,
            default_image: default_image,
            default_follower: default_follower,
            image_all: 0,
            follow_all: 0,
        }
    }

    fetchImages() {
        let data = {};
        let images = Helper.prepareDataLimit(this.state.default_image, this.state.limit_image, this.state.page_image, { mergePage: true });
        data.images = images;
        data.count_all = this.state.default_image.length;
        return data;
    }
    fetchFollower() {
        let data = {};
        let follower = Helper.prepareDataLimit(this.state.default_follower, this.state.limit_follow, this.state.page_follow);
        data.follower = follower;
        data.count_all = this.state.default_follower.length;
        return data;
    }
    componentDidMount() {
        let follower = this.fetchFollower();
        let images = this.fetchImages();
        this.setState({
            images: images.images,
            follower: follower.follower,
            image_all: images.count_all,
            follow_all: follower.count_all,
        })
    }

    componentDidUpdate(prevProps) {
        if ((this.props.user && this.props.user.photoPath !== prevProps.user.photoPath)
            || (this.props.follower.length !== prevProps.follower.length)
        ) {
            this.setState({
                default_image: this.props.user && this.props.user.photoPath ? this.props.user.photoPath : [],
                default_follower: this.props.follower || [],
            }, () => {
                let follower = this.fetchFollower();
                let images = this.fetchImages();
                this.setState({
                    images: images.images,
                    follower: follower.follower,
                    image_all: images.count_all,
                    follow_all: follower.count_all,
                })
            })
        }
    }


    async deleteImage(index, options) {
        // ลบรูปจาก storage
        // console.log(this.props.user.storagePath[index]);
        Helper.removeFile(this.props.user.storagePath[index]);
        // ลบรูปจาก storage สำเร็จ
        // อัพเดทข้อมูลรูปจาก 
        let update_user = {};
        update_user.photoPath = [...this.props.user.photoPath];
        update_user.storagePath = [...this.props.user.storagePath];
        update_user.photoPath.splice(index, 1)
        update_user.storagePath.splice(index, 1)
        if (index !== undefined && index === 0) {
            if (update_user.photoPath.length > 0 && update_user.photoPath[0]) {
                update_user.photoURL = update_user.photoPath[0];
            } else {
                update_user.photoURL = null
            }
        }
        update_user.adminUid = this.props.adminUid;
        update_user.uid = this.props.user.uid;
        let res = await axios.put(`${window.api_host}/user`, update_user);
        // console.log(res.data.response)
        if (res.data.success) {
            this.props.updateUser(res.data.response.user);
            if (options.onSuccess) {
                options.onSuccess();
            }
        }
        if (res.data.error) {
            Helper.messageTop({ message: res.data.message })
            if (options.onError) {
                options.onError();
            }
        }
        // อัพเดทสำเร็จ
        // let images = [...this.state.images];
        // images.splice(index, 1);
        // this.setState({
        //     images: images
        // })

    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                {
                    this.props.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="flex row flex-center mb-3">
                            <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>รูปภาพ
                                <div className="badge badge-primary" style={{ marginLeft: 10 }}>
                                    {Helper.numberFormat(this.state.image_all)}
                                </div>
                            </h5>
                        </div>
                        <ul className="users-list square-list clearfix">
                            {
                                this.state.image_all > 0 ?
                                    this.state.images.map((image, image_i) => {
                                        return (
                                            <li className="item promotion-slide-frame" style={{ width: '25%', position: 'relative' }} key={image_i}>
                                                <div className="promotion-delete">
                                                    <button
                                                        ref={(ref) => {
                                                            let node = window.$(ref);
                                                            node.off().on('click', () => {
                                                                if (node.hasClass('loading')) { return; }
                                                                node.addClass('loading');
                                                                Helper.confirmModal({
                                                                    title: "ลบรูปภาพ",
                                                                    description: `กรุณายืนยันการลบบรูปภาพ. หากลบรูปภาพนี้จะเป็นการลบรูปออกจากระบบ ไม่สามารถกู้คืนได้`,
                                                                    onCancel: () => {
                                                                        node.removeClass('loading')
                                                                    },
                                                                    onConfirm: () => {
                                                                        this.deleteImage(image_i, {
                                                                            onSuccess: () => {
                                                                                node.removeClass('loading')
                                                                            }
                                                                        });
                                                                    }
                                                                })
                                                            })
                                                        }}
                                                        className="btn btn-danger"
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                                <a className="image-item-frame" href={image || "/assets/images/no_img.png"} data-fancybox="users">
                                                    <img src={image || "/assets/images/no_img.png"} alt="User" style={{ borderRadius: 0 }} />
                                                </a>
                                            </li>
                                        )
                                    })
                                    : null
                            }
                            {
                                this.state.image_all === 0 ?
                                    <h5 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</h5>
                                    : null
                            }
                        </ul>
                        {
                            this.state.image_all > (this.state.page_image * this.state.limit_image) ?
                                <div style={{ textAlign: 'center' }}>
                                    <button className="btn btn-default"
                                        onClick={() => {
                                            this.setState({
                                                page_image: this.state.page_image + 1
                                            }, () => {
                                                let images = this.fetchImages();
                                                this.setState({
                                                    images: images.images,
                                                    image_all: images.count_all,
                                                })
                                            })
                                        }}
                                    >โหลดรูปเพิ่ม</button>
                                </div>
                                : null
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="flex row flex-center mb-3">
                            <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>ผู้ติดตาม
                                <div className="badge badge-primary" style={{ marginLeft: 10 }}>
                                    {Helper.numberFormat(this.state.follow_all)}
                                </div>
                            </h5>
                        </div>

                        {
                            this.state.follow_all > 0 ?
                                <>
                                    <ul className="users-list clearfix">
                                        {
                                            this.state.follower.map((follower, follower_i) => {
                                                // console.log(follower)
                                                return (
                                                    <li key={follower_i}>
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            {
                                                                follower
                                                                    && follower.follower
                                                                    && follower.follower.photoPath ?
                                                                    <img src={follower.follower.photoPath} alt="User" />
                                                                    :
                                                                    <img src={"/assets/images/no_img.png"} alt="User" />
                                                            }
                                                        </div>
                                                        <Link className="users-list-name" to={'/member/view/' + follower.uid}>{follower && follower.follower && follower.follower.displayName ? follower.follower.displayName : '-'}</Link>
                                                        <span className="users-list-date">{Helper.getDateThai(follower.createdAt, { is_show_time: false })}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <hr />
                                </>
                                : null
                        }


                        {
                            this.state.follow_all === 0 ?
                                <h5 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</h5>
                                : null
                        }
                        <Pagination
                            now={this.state.page_follow}
                            all_items={this.state.follow_all}
                            limit={this.state.limit_follow}
                            onReturnPage={(page) => {
                                this.setState({
                                    page_follow: page,
                                })
                            }}
                            location={this.props.location}
                        />
                    </div>
                </div>
            </div>
        );
    }
}