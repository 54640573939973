import React from "react";
import { Link } from "@reach/router";
// import { db, signInWithGoogle } from "../firebase";
// import { auth } from "../firebase";
import { Helper } from "../Helper";
import { authProvider, db } from "./../firebase";
const axios = require('axios').default;


const SignIn = () => {

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState(null);
  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    // console.log(email, password);
    if ($submitBtn.hasClass('loading')) { return false; }
    $submitBtn.addClass('loading');
    await authProvider.signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        // Signed in
        var user = userCredential.user;
        // ...
        let userData = await (await db.doc('providers/' + user.uid).get()).data();
        let __data = {};
        __data.uid = user.uid;
        __data.displayName = user.displayName;
        if (userData.updatedAt) {
          __data.updatedAt = userData.updatedAt.toDate();
        }
        let storeUid = userData.storesUid[0];

        let storeRef = await db.doc(`stores/${storeUid}`).get();
        let store = storeRef.data();
        if (store.status === 'cancel') {
          $submitBtn.removeClass('loading')
          Helper.messageTop({ message: 'ไม่สามารถเข้าสู่ระบบได้ โปรติดต่อผู้ดูแลระบบ' });
          return;
        }
        __data.storeUid = storeUid;
        Helper.setCookie('user', JSON.stringify(__data), 1)
        await axios.put(`${window.api_host}/provider`, { password: password, uid: user.uid });
        window.location = '/';
        Helper.messageTop({ message: 'เข้าสู่ระบบสำเร็จ', type_class: 'alert-success' });
      })
      .catch((error) => {
        console.log(error)
        // var errorCode = error.code;
        // var errorMessage = error.message;
        $submitBtn.removeClass('loading')
        Helper.messageTop({ message: 'อีเมล์หรือรหัสผ่านไม่ถูกต้อง' });
      });

  };


  let $submitBtn;
  let $form;
  return (
    <div className={"hold-transition login-page bg-custom"}>
      <div className="login-box" style={{ marginTop: '-15rem' }}>
        <div className="login-logo">
          <img src="assets/images/skitz-white.png" alt="skitz Logo" style={{ maxHeight: 150 }} />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">กรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ</p>

            <form ref={(ref) => $form = window.$(ref)}
              onSubmit={(e) => {
                e.preventDefault();
                let data = {};
                $form.serializeArray().map((item) => {
                  data[item.name] = item.value;
                  return true
                })
                signInWithEmailAndPasswordHandler(e, data.email, data.password)
              }}
            >
              <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="อีเมล" name="email" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="รหัสผ่าน" name="password" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="mb-3 mt-3 flex-row">
                <div className="right-floated">
                  <Link to={"/password-reset"}>ลืมรหัสผ่าน</Link>
                </div>
              </div>
              <button type="submit"
                ref={(ref) => { $submitBtn = window.$(ref); }}
                className="btn login-btn btn-block inverted"
              >เข้าสู่ระบบ</button>
            </form>

            {/* <div className="social-auth-links text-center mb-3">
              <p>- OR -</p>
              <a href="#" className="btn btn-block btn-primary">
              <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
              <button
                ref={(ref) => { $googleBtn = window.$(ref); }}
                className="btn btn-block btn-danger"
                onClick={() => {
                  if ($googleBtn.hasClass('loading')) { return; }
                  $googleBtn.addClass("loading");
                  signInWithGoogle();
                }}
              >
                <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
            </button>
            </div> */}
            <p class="mt-3"  style={{textAlign: 'center'}}>
              <a href={window.frontend_url + "register-store"} class="text-center">ลงทะเบียนร้าน</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SignIn;
