import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import { getCoinTransaction } from '../../services/CoinServices';
import { getStore } from '../../services/StoreServices';
import CustomDateInput from '../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import StoreCoinTransaction from './include/StoreCoinTransaction';

const default_limit = 5;
export default class Transaction extends React.Component {

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ประวัติแลกเปลี่ยนแต้ม</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ประวัติแลกเปลี่ยนแต้ม</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <StoreCoinTransaction
                            uid={this.props.uid}
                            id={false}
                            typeIds={`19,21,22`}
                        />
                    </div>
                </section>
            </>
        )
    }
}
