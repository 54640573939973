import React from 'react';

export default class PermissionCheckBox extends React.Component {
    setSelectAll(ref) {
        if(this.props.readOnly) {return;}
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            _ref.parents('.input-field').find('input[type="checkbox"]').prop('checked', true);
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.input-field').find('input[type="checkbox"]').prop('checked', false);
        }
    }
    checkSelectall(ref) {
        if(this.props.readOnly) {return;}
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            let count_all_checked_input = _ref.parents('.radio-list').find('input[type="checkbox"]:checked').length;
            let count_checked_input = _ref.parents('.radio-list').find('input[type="checkbox"]:checked').length;
            if (count_all_checked_input === count_checked_input) {
                _ref.parents('.input-field').find('.main-input-checkbox').find('input[type="checkbox"]').prop('checked', true);
            }
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.input-field').find('.main-input-checkbox').find('input[type="checkbox"]').prop('checked', false);
        }
    }
    render() {
        let is_readonly = false;
        if(this.props.readOnly) {
            is_readonly = this.props.readOnly
        }
        return (
            <>
                <div className={is_readonly ? "form-group input-field block-field": "form-group input-field"}
                style={{display: this.props.hide? "none" : null}}
                >
                    <div className="custom-control custom-switch main-input-checkbox">
                        <input type="checkbox"
                            className="custom-control-input"
                            id={"all_" + this.props.inputKeywordName + "_permission"}
                            name={"all_" + this.props.inputKeywordName + "_permission"}
                            defaultValue={"on"}
                            defaultChecked={
                                this.props.permission
                                    && this.props.permission.add
                                    && this.props.permission.edit
                                    && this.props.permission.delete
                                    && this.props.permission.view
                                    ? true : false
                            }
                            onChange={(ref) => {
                                this.setSelectAll(ref)
                            }}
                        />
                        <label className="custom-control-label" htmlFor={"all_" + this.props.inputKeywordName + "_permission"}>{this.props.title}</label>
                    </div>
                    <div className="flex row radio-list">
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                className="custom-control-input"
                                id={"add_" + this.props.inputKeywordName + "_permission"}
                                name={"add_" + this.props.inputKeywordName + "_permission"}
                                defaultValue={"on"}
                                defaultChecked={
                                    this.props.permission
                                        && this.props.permission.add ? this.props.permission.add : false}
                                onChange={(ref) => {
                                    this.checkSelectall(ref)
                                }}
                            />
                            <label className="custom-control-label" htmlFor={"add_" + this.props.inputKeywordName + "_permission"}>เพิ่ม</label>
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                className="custom-control-input"
                                id={"edit_" + this.props.inputKeywordName + "_permission"}
                                name={"edit_" + this.props.inputKeywordName + "_permission"}
                                defaultValue={"on"}
                                defaultChecked={
                                    this.props.permission
                                        && this.props.permission.edit ? this.props.permission.edit : false}
                                onChange={(ref) => {
                                    this.checkSelectall(ref)
                                }}
                            />
                            <label className="custom-control-label" htmlFor={"edit_" + this.props.inputKeywordName + "_permission"}>แกไข</label>
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                className="custom-control-input"
                                id={"delete_" + this.props.inputKeywordName + "_permission"}
                                name={"delete_" + this.props.inputKeywordName + "_permission"}
                                defaultValue={"on"}
                                defaultChecked={
                                    this.props.permission
                                        && this.props.permission.delete ? this.props.permission.delete : false}
                                onChange={(ref) => {
                                    this.checkSelectall(ref)
                                }}
                            />
                            <label className="custom-control-label" htmlFor={"delete_" + this.props.inputKeywordName + "_permission"}>ลบ</label>
                        </div>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                className="custom-control-input"
                                id={"view_" + this.props.inputKeywordName + "_permission"}
                                name={"view_" + this.props.inputKeywordName + "_permission"}
                                defaultValue={"on"}
                                defaultChecked={
                                    this.props.permission
                                        && this.props.permission.view ? this.props.permission.view : false}
                                onChange={(ref) => {
                                    this.checkSelectall(ref)
                                }}
                            />
                            <label className="custom-control-label" htmlFor={"view_" + this.props.inputKeywordName + "_permission"}>ดูข้อมูล</label>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}