import React, { useState, useContext } from "react";
import { authProvider } from "./../firebase";
// import { UserContext } from "../providers/UserProvider";
import { Link, navigate } from "@reach/router";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = event => {
    event.preventDefault();
    if ($submitBtn.hasClass('loading')) { return false; }
    $submitBtn.addClass('loading');
    authProvider
      .sendPasswordResetEmail(email)
      .then(() => {
        $submitBtn.removeClass('loading')
        setEmailHasBeenSent(true);
        setTimeout(() => {
          setEmailHasBeenSent(false);
          navigate('/')
        }, 5000);
      })
      .catch((e) => {
        console.log(e)
        $submitBtn.removeClass('loading')
        if(e.code == `auth/user-not-found`) {
          setError("Owner's email invalid");
        } else {
          setError("Error resetting password");
        }
      });
  };


  let $submitBtn;
  return (
    <div className={"hold-transition login-page bg-custom"}>
      <div className="login-box" style={{ marginTop: '-15rem' }}>
        <div className="login-logo">
          <img src="assets/images/skitz-white.png" alt="skitz Logo" style={{ maxHeight: 150 }} />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">ลืมรหัสผ่าน</p>

            <form
              onSubmit={(event) => {
                sendResetEmail(event);
              }}
            >
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="email"
                  name="userEmail"
                  id="userEmail"
                  value={email}
                  placeholder="กรอกอีเมล"
                  onChange={onChangeHandler} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              {
                emailHasBeenSent ?
                  <p className="mb-3 mt-3 flex-row">
                    ส่งอีเมลแล้ว
                  </p>
                  : null
              }
              {
                error ?
                  <p className="mb-3 mt-3 flex-row" style={{ color: 'red' }}>
                    {error}
                  </p>
                  : null
              }
              <button type="submit"
                ref={(ref) => { $submitBtn = window.$(ref); }}
                className="btn login-btn btn-block inverted"
              >
                ส่งอีเมล
              </button>
              <p class="mt-3" style={{textAlign: 'center'}}>
                <Link to="/" class="text-center">กลับ</Link>
              </p>
            </form>

          </div>
        </div>
      </div>
    </div>
  )

};

export default PasswordReset;
