import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../../../Helper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPaymentTransaction } from './../../../services/PaymentServices'
import { getArtistCoinTransaction } from '../../../services/CoinServices';
import { getChatGiftList, getChatGiftTransactionList } from '../../../services/ChatGiftServices';
const axios = require('axios').default;


export default class ArtistPayment extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            transaction_type: 'coin',
            // PAYMENT TRANSACTION STATE
            paymentitemEnd: 0,
            paymentTotal: 0,
            payment_date: false,
            payment_page: 1,
            payment_limit: 5,
            payment_data: [],
            // COIN TRANSACTION STATE
            coinitemEnd: 0,
            coinTotal: 0,
            coin_data: [],
            coin_date: false,
            coin_page: 1,
            coin_limit: 10,
            // GIFT TRANSACTION STATE
            gift_date: false,
            gift_data: [],
            gift_page: 1,
            gift_limit: 10,
            giftitemEnd: 0,
            giftTotal: 0,
        }
    }

    componentDidUpdate(prevProps) {

    }

    firstTimeFetch() {
        if (this.state.coin_data.length == 0) {
            this.setState({
                is_loading: true,
                transaction_type: 'coin',
                // PAYMENT TRANSACTION STATE
                paymentitemEnd: 0,
                paymentTotal: 0,
                payment_date: false,
                payment_page: 1,
                payment_limit: 5,
                payment_data: [],
                // COIN TRANSACTION STATE
                coinitemEnd: 0,
                coinTotal: 0,
                coin_data: [],
                coin_date: false,
                coin_page: 1,
                coin_limit: 10,
                // GIFT TRANSACTION STATE
                gift_date: false,
                gift_data: [],
                gift_page: 1,
                gift_limit: 10,
                giftitemEnd: 0,
                giftTotal: 0,
            }, () => {
                this.fetch();
            })
        }
    }
    fetch() {
        if (this.state.transaction_type === 'payment') {
            this.fetchPayment();
        } else if (this.state.transaction_type === 'coin') {
            this.fetchCoin();
        } else if (this.state.transaction_type === 'gift') {
            this.fetchChatGift();
        } else {
            this.setState({
                is_loading: false,
            })
        }
    }
    async fetchPayment() {
        let filter = {
            userId: this.props.user.userId,
            page: this.state.payment_page,
            rows: this.state.payment_limit
        }
        let res = await getPaymentTransaction(filter);
        let items = [...this.state.payment_data];
        let page = 1;
        let is_done = false;
        let paymentTotal = 0;
        let paymentitemEnd = 0;
        if (!res.error) {
            items = items.concat(res.items);
            page = res.page == res.endPage ? this.state.payment_page : (this.state.payment_page + 1);
            is_done = res.page == res.endPage;
            paymentTotal = res.total;
            paymentitemEnd = res.itemEnd;
        } else {
            is_done = true;
        }
        this.setState({
            payment_data: items,
            payment_isDone: is_done,
            payment_page: page,
            is_loading: false,
            paymentitemEnd: paymentitemEnd,
            paymentTotal: paymentTotal,
        });
    }

    async fetchCoin() {
        let types = `6,20`;
        if (this.props.coinTypes) {
            types = this.props.coinTypes;
        }
        let res = await getArtistCoinTransaction(this.props.user.id, this.state.coin_page, this.state.coin_limit, false, false, types);
        let items = [...this.state.coin_data];
        let page = 1;
        let is_done = false;
        let coinTotal = 0;
        let coinitemEnd = 0;
        if (!res.error) {
            items = items.concat(res.items);
            page = res.page == res.endPage ? this.state.coin_page : (this.state.coin_page + 1);
            is_done = res.page == res.endPage;
            coinTotal = res.total;
            coinitemEnd = res.itemEnd;
        } else {
            is_done = true;
        }
        this.setState({
            coin_data: items,
            coin_isDone: is_done,
            coin_page: page,
            is_loading: false,
            coinitemEnd: coinitemEnd,
            coinTotal: coinTotal,
        });
    }

    async fetchChatGift() {
        let items = [...this.state.gift_data];
        if (this.state.gift_data.length == 0) {
            let gifts = await getChatGiftList();
            if (gifts) {
                for (const gift of gifts) {
                    items.push({ ...gift, usedAmount: 0 })
                }
            }
        }
        let res = await getChatGiftTransactionList(this.props.user.user.userId, this.state.gift_page, this.state.gift_limit);
        let page = 1;
        let is_done = false;
        let coinTotal = 0;
        let coinitemEnd = 0;
        if (!res.error) {
            // items = res.items;
            for (const item of res) {
                let index = items.findIndex(object => {
                    return object.id === item.chatGiftId;
                });
                if (index && index != -1) {
                    items[index].usedAmount += 1;
                }
            }
        } else {
            is_done = true;
        }
        this.setState({
            gift_data: items,
            gift_isDone: is_done,
            gift_page: page,
            is_loading: false,
            giftitemEnd: coinitemEnd,
            giftTotal: coinTotal,
        });
    }


    loadMore() {
        this.setState({
            is_loading: true,
        }, () => {
            this.fetch();
        })
    }

    render() {
        return (
            <div className='card-body' style={{ position: 'relative' }}>
                {
                    this.state.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <>
                    <div className="row">
                        <div className="col-12 col-sm-10 col-md-7">
                            <div className="tab-content" id="vert-tabs-tabContent">
                                <div className="tab-pane text-left fade active show">
                                    {
                                        this.state.transaction_type === 'payment' ?
                                            <>
                                                <ul className="list-group">
                                                    <RenderPaymentTransactionList
                                                        data={this.state.payment_data}
                                                    />
                                                </ul>
                                                {
                                                    !this.state.is_loading
                                                        && !this.state.payment_isDone ?
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <button
                                                                className="btn btn-default"
                                                                onClick={(ref) => {
                                                                    let node = window.$(ref.target);
                                                                    if (node.hasClass('loading')) { return }
                                                                    node.addClass('loading');
                                                                    this.loadMore();
                                                                }}
                                                            >โหลดเพิ่ม</button> <span className='right-floated'>{this.state.paymentitemEnd}/{this.state.paymentTotal}</span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.payment_isDone
                                                        && this.state.payment_data.length > 0 ?
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <span className='right-floated'>{this.state.paymentitemEnd}/{this.state.paymentTotal}</span>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        this.state.transaction_type === 'coin' ?
                                            <>
                                                <ul className="list-group">
                                                    <RenderCoinTransactionList
                                                        data={this.state.coin_data}
                                                        user={{
                                                            ...this.props.user,
                                                            uid: this.props.uid
                                                        }}
                                                    />
                                                </ul>
                                                {
                                                    !this.state.is_loading
                                                        && !this.state.coin_isDone ?
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <button
                                                                className="btn btn-default"
                                                                onClick={(ref) => {
                                                                    let node = window.$(ref.target);
                                                                    if (node.hasClass('loading')) { return }
                                                                    node.addClass('loading');
                                                                    this.loadMore();
                                                                }}
                                                            >โหลดเพิ่ม</button> <span className='right-floated'>{this.state.coinitemEnd}/{this.state.coinTotal}</span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.coin_isDone
                                                        && this.state.coin_data.length > 0 ?
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <span className='right-floated'>{this.state.coinitemEnd}/{this.state.coinTotal}</span>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        this.state.transaction_type === 'gift' ?
                                            <>
                                                <ul className="list-group">
                                                    <RenderGiftTransactionList
                                                        data={this.state.gift_data}
                                                        user={{
                                                            ...this.props.user,
                                                            uid: this.props.uid
                                                        }}
                                                    />
                                                </ul>
                                                {/* {
                                                    !this.state.is_loading
                                                        && !this.state.gift_isDone ?
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <button
                                                                className="btn btn-default"
                                                                onClick={(ref) => {
                                                                    let node = window.$(ref.target);
                                                                    if (node.hasClass('loading')) { return }
                                                                    node.addClass('loading');
                                                                    this.loadMore();
                                                                }}
                                                            >โหลดเพิ่ม</button> <span className='right-floated'>{this.state.coinitemEnd}/{this.state.coinTotal}</span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.gift_isDone
                                                        && this.state.gift_data.length > 0 ?
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <span className='right-floated'>{this.state.coinitemEnd}/{this.state.coinTotal}</span>
                                                        </div>
                                                        : null
                                                } */}
                                            </>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        )
    }

}
class RenderGiftTransactionList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการใช้ของขวัญ</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                </div>
                                <div className="d-flex">
                                    <div style={{ marginRight: 15 }}><img style={{ width: 60, height: 60 }} src={item.image} /></div>
                                    <div>
                                        <p className='mb-0'><strong>#{item.giftName}</strong></p>
                                        <p className='mb-0'><strong>Code : {item.giftCode}</strong></p>
                                        {item.fee ? <p className="mb-0">เหรียญ : <strong>{Helper.numberFormat(item.fee)}</strong></p> : null}
                                        {item.price ? <p className="mb-0">ราคา : <strong>฿{Helper.numberFormat(item.price, { decimal: 2 })}</strong></p> : null}
                                    </div>
                                    <div className='right-floated'>
                                        {
                                            item.created ?
                                                <p className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                                : null
                                        }
                                        <p className="mb-0"> จำนวนการใช้ : <strong style={{ color: item.usedAmount > 0 ? 'green' : '#000' }}>{item.usedAmount ? Helper.numberFormat(item.usedAmount) : 0}</strong></p>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }
}
class RenderCoinTransactionList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการใช้เหรียญ</strong>
                </li>
            )
        }

        let user = this.props.user;
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        let displayName;
                        let toDisplayName;
                        if (item.user) {
                            if (item.user.fireBaseUid == user.uid) {
                                displayName = item.user.displayName;
                            } else {
                                toDisplayName = item.user.displayName;
                            }
                        }
                        if (item.sender) {
                            if (item.sender.fireBaseUid == user.uid) {
                                displayName = item.sender.displayName;
                            } else {
                                toDisplayName = item.sender.displayName;
                            }
                        }
                        if (item.reciever) {
                            if (item.reciever.fireBaseUid == user.uid) {
                                displayName = item.reciever.displayName;
                            } else {
                                toDisplayName = item.reciever.displayName;
                            }
                        }
                        if (item.artists) {
                            if (item.artists.fireBaseUid == user.uid) {
                                displayName = item.artists.name;
                            } else {
                                toDisplayName = item.artists.name;
                            }
                        }

                        if (item.typeId == 9) {
                            item.type.name = "Added from";
                            toDisplayName = "Admin";
                        }
                        if (item.typeId == 10) {
                            item.type.name = "Removed from";
                            toDisplayName = "Admin";
                        }
                        let amountType = 'c';
                        console.log(item)
                        if (item.typeId == 6
                            || item.typeId == 4
                            || item.typeId == 8
                            || item.typeId == 14
                            || item.typeId == 20
                            || item.typeId == 21) {
                            amountType = 'p';
                        }
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                    <p className='mb-0'><strong>#{item.transactionNumber}</strong> {item.statusId == 2 ? <small className='badge badge-danger'>Void</small> : null}</p>
                                    {
                                        item.created ?
                                            <p className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                            : null
                                    }
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <p className='mb-0'><strong>{displayName}</strong> {item.type.name} <strong>{toDisplayName}</strong></p>
                                        {
                                            item.store ?
                                                <p className='mb-0'>{item.store.name}</p>
                                                : null
                                        }
                                        {
                                            item.note ?
                                                <>
                                                    <strong>Note : </strong>
                                                    <p className='mb-0'>{item.note}</p>
                                                </>
                                                : null
                                        }
                                    </div>

                                    <div className="right-floated d-flex align-items-center">
                                        <p className=" mb-0"><strong>{item.amount ? Helper.numberFormat(item.amount, { decimal: 2 }) : 0}</strong></p>
                                        <div style={{ display: 'flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: amountType == "c" ? 'orange' : '#50AFFF', overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>{amountType}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }
}
class RenderPaymentTransactionList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการชำระเงิน</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        let amount = item.amount ?? 0;
                        let amountType = 'c';
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                    <p className='mb-0'><strong>#{item.transactionNumber}</strong></p>
                                    {
                                        item.created ?
                                            <p className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                            : null
                                    }
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <p className="mb-0">ผู้ชำระเงิน : {item.payerName}</p>
                                        <div className="d-flex align-items-center">
                                            <p className='mb-0'>รายการ : {item.paymentType.name} {item.coinAmount ? `${Helper.numberFormat(item.coinAmount)}` : null}</p>
                                            <div style={{ display: 'flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: amountType == "c" ? 'orange' : '#50AFFF', overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>{amountType}</span>
                                            </div>
                                        </div>
                                        <p className='mb-0'>ช่องทางชำระเงิน : {item.paymentChannel.name}</p>
                                        {
                                            item.referenceNumber ?
                                                <p className='mb-0'>Ref No. : {item.referenceNumber}</p>
                                                : null
                                        }
                                        {
                                            item.invoiceNumber ?
                                                <p className='mb-0'>Invoice No. : {item.invoiceNumber}</p>
                                                : null
                                        }
                                        <p className='mb-0'>สถานะการชำระเงิน : <span style={{
                                            color:
                                                item.paymentStatusId == 3 ? 'green' :
                                                    item.paymentStatusId == 4 ? 'red' :
                                                        item.paymentStatusId == 2 ? "orange" :
                                                            item.paymentStatusId == 6 ? "gray" :
                                                                '#000'
                                        }}>{item.paymentStatus.name}</span>
                                            {
                                                item.paymentStatusId == 3 && item.paidDateTime ?
                                                    <small style={{ fontSize: 12, color: '#999', marginLeft: 5 }}>{Helper.getDateThai(item.paidDateTime, { is_show_time: true, plus_hours: 7 })}</small>
                                                    : null
                                            }
                                            {
                                                item.paymentStatusId == 6 && item.updated ?
                                                    <small style={{ fontSize: 12, color: '#999', marginLeft: 5 }}>{Helper.getDateThai(item.updated, { is_show_time: true, plus_hours: 7 })}</small>
                                                    : null
                                            }
                                        </p>
                                        {
                                            item.refundNumber ?
                                                <p className='mb-0'>Refund No. : {item.refundNumber}</p>
                                                : null
                                        }

                                        {
                                            item.refundReason ?
                                                <p className='mb-0'>Refun reason : {item.refundReason}</p>
                                                : null
                                        }
                                    </div>
                                    <div className="right-floated">
                                        <p className="right-floated"><strong>฿{Helper.numberFormat(amount, { decimal: 2 })}</strong></p>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }
}