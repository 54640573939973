import React from 'react';
import { Helper } from './../../Helper';
import InputMask from 'react-input-mask';
const axios = require('axios').default;

export default class PaymentCreditCardCheckingModal extends React.Component {
    createCreditCard(data) {
        let _data = { ...data };
        _data.cardNumber = '';
        data.cardNumber.split(" ").map((no) => {
            _data.cardNumber += no;
            return true;
        })
        window.Omise.createToken("card",
            {
                "expiration_month": _data.expiryMonth,
                "expiration_year": _data.expiryYear,
                "name": _data.nameOnCard,
                "number": _data.cardNumber,
                "security_code": _data.securityCode,
            }, async (statusCode, response) => {
                // console.log(response);
                // console.log(statusCode);
                if (response.id) {
                    let create_creditcard = {};
                    create_creditcard.token = response.id;
                    create_creditcard.card = response.card;
                    create_creditcard.provider_uid = this.props.provider_uid;
                    let res = await axios.post(window.api_host + '/create-creditcard', { ...create_creditcard });
                    if (res.data.success) {
                        window.$(document).Toasts('create', {
                            title: 'เพิ่มเครดิตการ์ดสำเร็จ',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        });
                        this.props.onSuccess();
                        this.$modal.modal('hide');
                    }
                    if (res.data.error) {
                        window.$(document).Toasts('create', {
                            title: res.data.message,
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        });
                        this.$submit.removeClass("loading")
                    }
                } else {
                    window.$(document).Toasts('create', {
                        title: response.message,
                        icon: 'fas fa-times',
                        autohide: true,
                        class: "bg-danger",
                        delay: 3000,
                    });
                    this.$submit.removeClass("loading")
                }
            });
    }
    render() {
        // console.log(this.props)
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]');
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            this.createCreditCard(data);

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">เพิ่มบัตรเครดิต</h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Card Number</label>
                                    <InputMask mask="9999 9999 9999 9999" type="text" name="cardNumber" className="form-control" placeholder="••••••••••••••••" maskChar={null} />
                                    {/* <input type="text" name="cardNumber" className="form-control" placeholder="••••••••••••••••" maxLength={16} /> */}
                                </div>

                                <div className="form-group">
                                    <label>Name on card</label>
                                    <input type="text" name="nameOnCard" className="form-control" placeholder="Full Name" />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>Expiry date</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <select className="form-control" name="expiryMonth">
                                                        <option value="">MM</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <select className="form-control" name="expiryYear">
                                                        <option value="">YYYY</option>
                                                        <option value="2017">2017</option>
                                                        <option value="2018">2018</option>
                                                        <option value="2019">2019</option>
                                                        <option value="2020">2020</option>
                                                        <option value="2021">2021</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2025">2025</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label>Security code</label>
                                            <input type="text" name="securityCode" className="form-control" placeholder="123" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}