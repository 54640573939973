import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper, SortableContainer, SortableItem } from '../../../Helper';
import { QRCode } from 'react-qrcode-logo';
export default class StorePackage extends React.Component {

    calculateTotalPrice(billing) {
        if (billing.paymentBy === 'creditcard') {
            return Helper.numberFormat(parseFloat(billing.summary.total_price).toFixed(2))
        }
        if (billing.paymentBy === 'adminService' && billing.paymentByDetails && billing.paymentByDetails.receiveAmount) {
            return Helper.numberFormat(parseFloat(billing.paymentByDetails.receiveAmount).toFixed(2))
        }
    }

    render() {
        let expiredDate = new Date();
        let nextMonth = 2;
        if (this.props.data && this.props.data.billing && this.props.data.billing.length > 0) {
            expiredDate = new Date(this.props.data.billing[0].expiredAt);
            nextMonth = 1;
        }
        let expiredMonth = expiredDate.getMonth() + nextMonth;
        let expiredYear = expiredDate.getFullYear();
        let current_package = false;
        if (this.props.package_list && this.props.package) {
            this.props.package_list.map((item) => {
                if (item.uid === this.props.package.uid) {
                    current_package = item;
                    // console.log(item)
                }
                return true;

            })
        }
        // console.log(current_package);
        // console.log(this.props);
        return (
            <div>
                {
                    this.props.is_show_user ?
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="card card-widget widget-user custom">
                                    <div className="widget-user-header bg-primary">
                                        <h3 className="widget-user-username">{this.props.data && this.props.data.owner && this.props.data.owner.displayName ? this.props.data.owner.displayName : '-'}</h3>
                                        <div className={"flex row mt-2"}>
                                            <div>เบอร์โทรศัพท์: {this.props.data && this.props.data.owner && this.props.data.owner.phoneNumber ? this.props.data.owner.phoneNumber : '-'}</div>
                                            <div className="right-floated">อีเมล : {this.props.data && this.props.data.owner && this.props.data.owner.email ? this.props.data.owner.email : '-'}</div>
                                        </div>
                                    </div>
                                    <div className="widget-user-image">
                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                            <img
                                                src={"/assets/images/no_img.png"}
                                                alt="Store profile" />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-sm-4 border-right">
                                                <div className="description-block">
                                                    <h5 className="description-header">{this.props.data && this.props.data.package && this.props.data.package.name ? this.props.data.package.name : '-'}</h5>
                                                    <span className="description-text">CURRENT PACKAGE</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 border-right">
                                                <div className="description-block">
                                                    {
                                                        !this.props.current_billing
                                                            && this.props.data.billing.length === 0
                                                            && this.props.data.package
                                                            && this.props.data.package.option
                                                            && this.props.data.package.option.summary
                                                            && parseFloat(this.props.data.package.option.summary.total) !== 0 ?
                                                            <>
                                                                <span className="description-text">ยังไม่เคยมีการชำระเงิน </span>
                                                                {
                                                                    current_package ?
                                                                        <Link
                                                                            className="btn btn-success btn-lg"
                                                                            style={{ width: '100%' }}
                                                                            to={"/payment-package/" + current_package.uid}
                                                                        >
                                                                            ชำระเงิน
                                                                        </Link>
                                                                        : null
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        !this.props.current_billing
                                                            && this.props.data.package
                                                            && this.props.data.package.option
                                                            && this.props.data.package.option.summary
                                                            && parseFloat(this.props.data.package.option.summary.total) === 0 ?
                                                            <>
                                                                <span className="description-text">-</span>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.props.isExpired
                                                            && this.props.current_billing
                                                            && this.props.data.billing.length > 0 ?
                                                            <>
                                                                <h5 className="description-header">แพ็คเกจหมดอายุ</h5>
                                                                {
                                                                    current_package ?
                                                                        <Link className="btn btn-primary btn-lg" style={{ width: '100%' }} to={"/payment-package/" + current_package.uid}>ต่ออายุ</Link>
                                                                        : null
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        !this.props.isExpired
                                                            && this.props.data.billing.length > 0 ?
                                                            <>
                                                                <h5 className="description-header">{expiredMonth}/{expiredYear + 543}</h5>
                                                                <span className="description-text">NEXT BILLING DATE</span>
                                                                <button className={"btn btn-default"}>
                                                                    {
                                                                        this.props.data
                                                                            && this.props.data.store
                                                                            && this.props.data.store.paidStatus === 'auto' ?
                                                                            <i className={"fas fa-check"} style={{ color: 'green', marginRight: 5 }}></i>
                                                                            :
                                                                            <i className={"fas fa-times"} style={{ color: 'red', marginRight: 5 }}></i>
                                                                    }
                                                                    Auto Continue
                                                                </button>
                                                            </>
                                                            : null
                                                    }
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="description-block">
                                                    <h5 className="description-header">
                                                        {
                                                            this.props.data
                                                                && this.props.data.package.default_card
                                                                && this.props.data.package.default_card
                                                                && this.props.data.package.default_card.last_digits
                                                                ?
                                                                this.props.data.package.default_card.last_digits
                                                                :
                                                                "-"
                                                        }
                                                    </h5>
                                                    <div className="description-text">
                                                        {
                                                            this.props.data
                                                                && this.props.data.package.default_card
                                                                && this.props.data.package.default_card
                                                                && this.props.data.package.default_card.brand
                                                                ?
                                                                this.props.data.package.default_card.brand
                                                                :
                                                                "-"
                                                        }
                                                    </div>
                                                    <span className="description-text">PAYMENT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-4">
                                <div className="flex row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <QRCode value={`https://skitz-app.web.app/stores/${this.props.uid}`}
                                        logoImage={window.qr_logo}
                                        size={300}
                                    />
                                </div>
                            </div>
                        </div>

                        : null
                }
                {
                    this.props.is_show_history ?
                        <>
                            <div className="flex row">
                                <h6 style={{ fontWeight: 'bold' }}>
                                    {/* เก็บเงินครั้งต่อไป */}
                                    ประวัติการเรียกเก็บเงิน
                                </h6>
                            </div>
                            <table className="table table-condensed table-hover">
                                <thead>
                                    <tr>
                                        <th>วันที่</th>
                                        <th>รายละเอียด</th>
                                        <th>รอบสัญญา</th>
                                        <th>ผู้สมัคร</th>
                                        <th>วิธีชำระเงิน</th>
                                        <th>จำนวนเงิน</th>
                                        <th>สถานะการชำระเงิน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.props.data
                                            || !this.props.data.billing
                                            || this.props.data.billing.length === 0 ?
                                            <tr >
                                                <td>ไม่เคยมีการชำระเงิน</td>
                                            </tr>
                                            : null
                                    }
                                    {
                                        this.props.data
                                            && this.props.data.billing ?
                                            this.props.data.billing.map((billing, billing_i) => {
                                                let date = Helper.monthRage(billing.expiredAt)
                                                return (
                                                    <tr key={billing_i}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            navigate('/transection/' + billing.transectionUid);
                                                        }}
                                                    >
                                                        <td>{Helper.getDateThai(new Date(billing.createdAt), { is_show_time: true })}</td>
                                                        <td>
                                                            {
                                                                billing.packagePaymentType === 'renew' ?
                                                                    <>ต่ออายุ</>
                                                                    : null
                                                            }
                                                            {
                                                                billing.packagePaymentType === 'upgrade'
                                                                    || billing.packagePaymentType === 'renew_upgrade' ?
                                                                    <>อัพเกรด</>
                                                                    : null
                                                            }
                                                            {
                                                                billing.packagePaymentType === 'downgrade' ?
                                                                    <>ลดเกรด</>
                                                                    : null
                                                            }
                                                            แพ็คเกจ : {billing.package.name}</td>
                                                        <td>{date.startMonth}/{date.startYear} - {date.expiredMonth}/{date.expiredYear}</td>
                                                        <td>{billing && billing.provider && billing.provider.displayName ? billing.provider.displayName : 'ไม่มีข้อมูล'}</td>
                                                        <td>
                                                            {
                                                                billing.paymentBy === 'creditcard' ?
                                                                    <>บัตรเครดิต ({billing.card ? billing.card.last_digits : "-"}) </>
                                                                    : null
                                                            }
                                                            {
                                                                billing.paymentBy === 'adminService' ?
                                                                    <>ชำระผ่านผู้ดูแลระบบ  </>
                                                                    : null
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                this.calculateTotalPrice(billing)
                                                            }
                                                        </td>
                                                        <td>{billing.isPaid ? 'ชำระเงินแล้ว' : 'ยังไม่ได้ชำระเงิน'}</td>
                                                    </tr>
                                                );
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </>
                        : null
                }

            </div>
        );
    }
}