import React from 'react';
import { Link } from '@reach/router';
import { db , authProvider} from './../firebase';
import { Helper } from './../Helper';
const axios = require('axios').default;

export default class AdminDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        // console.log(this.props.admin_id)
        await db.doc("providers/" + this.props.provider_uid).get().then((doc) => {
            let data = doc.data();
            this.setState({
                data: data
            })
        })
    }


    render() {
        // console.log(this.state.data)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลผู้ใช้งาน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลผู้ใช้งาน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className="col-sm-12 col-lg-3">
                                <div className="card">
                                    <div className="card-header flex row" >
                                        <h3 className="card-title">{this.state.data ? this.state.data.displayName : null}</h3>
                                        {
                                            this.state.data ?
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <div className={'link'}
                                                        onClick={() => {
                                                            Helper.reactToDom(
                                                                window.$('body'),
                                                                <ChangePassword
                                                                    uid={this.props.provider_uid}
                                                                    data={this.state.data}
                                                                    onDone={() => {
                                                                        // this.fetchData();
                                                                        authProvider.signOut();
                                                                        Helper.setCookie('user', "", 1);
                                                                        window.location = '/';
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >เปลี่ยนรหัสผ่าน</div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className={"card-body"}>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data
                                                        && this.state.data.permission
                                                        && this.state.data.permission.admin ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการผู้ดูแล</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class ChangePassword extends React.Component {
    async update(data) {
        let update_data = { ...data };
        let res = await axios.post(window.api_host + '/update-provider-user', { ...update_data });
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                body: res.data.description,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            this.$form.find('button[type="submit"]').removeClass('loading')
            return false;
        }
        window.$(document).Toasts('create', {
            title: "เปลี่ยนข้อมูลสำเร็จ",
            // body: "กรุณากรอกรหัสอย่างน้อย 6 ตังอักษร",
            icon: 'fas fa-check',
            autohide: true,
            class: "bg-success",
            delay: 3000,
        });
        this.$modal.modal('hide');
        this.props.onDone();

    }


    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // data.old_password = this.props.data.password;
                            data.email = this.props.data.email;
                            this.update(data);
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">เปลี่ยนรหัสผ่าน</h4>
                            </div>
                            <div className="modal-body">
                                <div className={"form-group"}>
                                    <label>รหัสผ่านเก่า</label>
                                    <input type="password" name={"password"} className={"form-control"} />
                                </div>
                                <div className={"form-group"}>
                                    <label>รหัสผ่านใหม่</label>
                                    <input type="password" name={"new_password"} className={"form-control"} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ยืนยันรหัสผ่านใหม่</label>
                                    <input type="password" name={"confirm_new_password"} className={"form-control"} />
                                </div>
                                <span style={{ fontSize: 13, color: 'rgba(0,0,0,0.6)' }}>รหัสผ่านอย่างน้อย 6 ตัวอักษรประกอบไปด้วย เลข,ตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่</span>
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}