import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/store`;
export const getStore = async (firebaseUid) => {
    const url = `${baseUrl}/${firebaseUid}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getStores = async (filter) => {
    let url = `${baseUrl}/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getStorePackageChannels = async (id) => {
    const url = `${baseUrl}/channel/${id}/package`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getStoreSummary = async (uid) => {
    const url = `${baseUrl}/${uid}/summary`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}