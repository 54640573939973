import React from 'react';
import { Helper } from '../../Helper';
import { Link } from '@reach/router';
import CustomDateInput from './../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import Pagination from './../../Components/Pagination';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { db, storage } from './../../firebase';
import CreateAirDrop from './../../Components/CreateAirDrop'
import { getStore } from '../../services/StoreServices';
const axios = require('axios').default;

export default class Store extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            timerage: 'รายปี',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            postBy: false,
            posts: [],
            members: [],
            all_guests: [],
            all_posts: [],
            post_member: false,
            limit_posts: 10,
            page_posts: 1,
            limit_guests: 12,
            page_guests: 1,
            post_all: 0,
            guest_all: 0,
            count_all_item: 0,
            count_member_all: 0,
            count_member_today: 0,
            count_store_all: 0,
            count_store_today: 0,
            loading_store: true,
            loading_post: true,
            loading_guest: true,
            is_show_more_btn: false,
        }
    }

    componentDidMount() {
        this.fetch();
        this.fetchPosts();
        this.fetchStore();
    }
    async fetchStore() {
        let res = await db.collection('stores').doc(this.props.uid).get();
        let store = false;
        if (res) {
            store = res.data();
        }
        this.setState({
            store: store,
            loading_store: false,
        })
    }

    updateStoreStatus(type) {
        this.setState({
            loading_store: true
        }, async () => {
            let res = false;
            let message = '';
            if (type === 'close') {
                res = await axios.post(window.api_host + '/close-store', { storeUid: this.props.uid });
                message = 'ปิดร้านสำเร็จ';
            }
            if (type === 'open') {
                res = await axios.post(window.api_host + '/open-store', { storeUid: this.props.uid });
                message = 'เปิดร้านสำเร็จ';
            }
            console.log(res)
            if (res.data.code === 200) {
                window.$(document).Toasts('create', {
                    title: message,
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                });
                this.fetchStore();
            }
            if (res.data.code !== 200) {
                this.setState({
                    loading_store: false
                })
            }
        })

    }

    async fetch() {
        db.collection('stores/' + this.props.uid + '/live').where('status', '==', 'stay').onSnapshot(async rmSnap => {
            let items = [];
            for (const doc of rmSnap.docs) {
                let item = {};
                let userRef = await db.doc('users/' + doc.id).get();
                if (userRef.exists) {
                    item.user = await userRef.data();
                    item.uid = doc.id;
                    items.push(item);
                }
            }
            let guests = {
                item: [],
                count: 0,
            };
            if (items.length > 0) {
                guests = await this.fetchGuests(items);
            }
            this.setState({
                loading_guest: false,
                all_guests: items,
                guests: guests.item,
                guest_all: guests.count,
            })
        });
    }

    async fetchPosts() {
        let data = [];
        let data_request = {};
        data_request.lang = 'th';
        data_request.storeUid = this.props.uid;
        data_request.postBy = this.state.postBy;
        data_request.page = this.state.page_posts;
        data_request.limit = parseFloat(this.state.limit_posts);
        data_request.timerage = this.state.timerage;
        let start_date = false;
        let end_date = false;
        if (this.state.timerage === 'รายปี') {
            // alert(this.state.year)
            start_date = new Date(this.state.year, 0, 1);
            end_date = new Date(this.state.year + 1, 0, 1);

            data_request.start_date = this.formatDate(start_date);
            data_request.end_date = this.formatDate(end_date);
        }
        if (this.state.timerage === 'รายเดือน') {
            // alert(this.state.year, this.state.month)
            let selected_month = parseFloat(this.state.month);
            start_date = new Date(this.state.year, selected_month, 1);
            end_date = new Date(this.state.year, selected_month + 1, 1);

            data_request.start_date = this.formatDate(start_date);
            data_request.end_date = this.formatDate(end_date);
        }
        if (this.state.timerage === 'รายวัน') {
            // alert(this.state.date)
            let date = new Date(this.state.date);
            start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

            data_request.start_date = this.formatDate(start_date);
            data_request.end_date = this.formatDate(end_date);
        }
        let posts_ref = await axios.post(window.api_host + '/get-store-posts', { ...data_request });
        // console.log(posts_ref)
        let summary = {}
        if (posts_ref.data.success) {
            data = posts_ref.data.res;
            summary = posts_ref.data.summary;
        }
        let return_data = {};
        return_data.item = this.state.posts.concat(data);
        return_data.count = data.length;
        let is_show_more_btn = data.length >= this.state.limit_posts;
        // return return_data;
        this.setState({
            loading_post: false,
            posts: return_data.item,
            all_posts: summary.totalUserPost + summary.totalStorePost || 0,
            count_all_item: data.length,
            count_member_all: summary.totalUserPost || 0,
            count_member_today: summary.todayUserPost || 0,
            count_store_all: summary.todayStorePost || 0,
            count_store_today: summary.totalStorePost || 0,
            is_show_more_btn: is_show_more_btn,
        })
    }
    formatDate(date, options) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    fetchGuests(data) {
        let prepareData = Helper.prepareDataLimit(data, this.state.limit_guests, this.state.page_guests);
        let return_data = {};
        return_data.item = prepareData;
        return_data.count = data.length;
        return return_data;
    }
    deletePost(id) {
        Helper.confirmModal({
            title: 'ลบโพส ' + id,
            description: 'ยืนยันการลบโพส',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                this.setState({
                    loading_post: true,
                }, async () => {
                    let res = await axios.delete(window.api_host + '/delete-post?' + window.$.param({ post_id: id }));

                    if (res.data.success) {
                        window.$(document).Toasts('create', {
                            title: 'ลบข้อมูลสำเร็จ',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        })
                        let posts = this.state.posts.filter((e) => e.postId != id);
                        this.setState({
                            posts: posts,
                            loading_post: false,
                        })
                    }
                    if (res.data.error) {
                        window.$(document).Toasts('create', {
                            title: res.data.message,
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        })
                    }
                })
            },
        })
    }

    async kickAllUsers({ onSuccess, onCancel }) {
        Helper.confirmModal({
            title: 'บังคับผู้ใช้งานทั้งหมดออกจากระบบ',
            description: 'ยืนยันการบังคับผู้ใช้งานทั้งหมดออกจากระบบ',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                let res = await axios.post(window.api_host + '/leave-all-store', {
                    storeUid: this.props.uid
                })
                if (res.data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: 'ผู้ใช้งานทั้งหมดออกจากระบบแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    })
                    onSuccess();
                }
                if (res.data.code !== 200) {
                    window.$(document).Toasts('create', {
                        title: res.data.message,
                        icon: 'fas fa-times',
                        autohide: true,
                        class: "bg-danger",
                        delay: 3000,
                    })
                }
            },
            onCancel: () => {
                onCancel();
            }
        })
    }

    async kickUser(data) {
        Helper.confirmModal({
            title: 'บังคับผู้ใช้งาน ' + data.user.displayName + ' ออกจากระบบ',
            description: 'ยืนยันการบังคับผู้ใช้งานออกจากระบบ',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                let res = await axios.post(window.api_host + '/kick-user-store', {
                    userUid: data.uid,
                    storeUid: this.props.uid
                })
                if (res.data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: data.user.displayName + ' ออกจากระบบแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    })
                }
                if (res.data.code !== 200) {
                    window.$(document).Toasts('create', {
                        title: res.data.message,
                        icon: 'fas fa-times',
                        autohide: true,
                        class: "bg-danger",
                        delay: 3000,
                    })
                }
            },
        })
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        // console.log(this.state)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการร้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={"/dashboard"}>หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการร้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3" style={{ zIndex: 1000 }}>
                                <div style={{ position: 'sticky', top: 64 }}>
                                    <div className="card">
                                        <div className="card-body">

                                            {
                                                this.state.loading_store ?
                                                    <div className={"absolute_loading"}>
                                                        <div className="ui active inline loader" ></div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.store ?
                                                    <>
                                                        <div className="flex row" style={{ alignItems: 'center' }}>
                                                            <div>
                                                                {Helper.getStoreStatus(this.state.store.status)}
                                                            </div>

                                                            {
                                                                this.state.store
                                                                    && this.state.store.status === "online" ?
                                                                    <button className="btn btn-danger right-floated"
                                                                        onClick={() => {
                                                                            this.updateStoreStatus('close')
                                                                        }}
                                                                    >ปิดร้าน</button>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.store
                                                                    && this.state.store.status === "offline" ?
                                                                    <button className="btn btn-success right-floated"
                                                                        onClick={() => {
                                                                            this.updateStoreStatus('open')
                                                                        }}
                                                                    >เปิดร้าน</button>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className="text-center">
                                                            <div className="profile-user-img img-frame img-fluid img-circle">
                                                                <img
                                                                    src={this.state.store.photoPath ? this.state.store.photoPath[0] : "/assets/images/no_upload_image.jpg"}
                                                                    alt="Store profile" />
                                                            </div>
                                                        </div>
                                                        <h3 className="profile-username text-center">{this.state.store.nameTH || '-'}</h3>

                                                        <p className="text-muted text-center">{this.state.store.nameEN || '-'}</p>
                                                        <div className='d-flex justify-content-center align-items-center'>

                                                            <button className="btn btn-info"
                                                                onClick={() => {
                                                                    Helper.reactToDom(
                                                                        window.$('body'),
                                                                        <CreateAirDrop
                                                                            uid={this.props.uid}
                                                                        />
                                                                    )
                                                                }}
                                                            >
                                                                ส่ง AirDrop
                                                            </button>
                                                        </div>
                                                    </>
                                                    : null
                                            }

                                        </div>
                                    </div>



                                    <div className="card">
                                        <div className="card-body">
                                            <div>
                                                <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนโพสสมาชิก</h6>
                                                <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                                                    <li className="list-group-item">
                                                        <b>โพสทั้งหมด</b> <div className="float-right">{this.state.count_member_all}</div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>โพสวันนี้</b> <div className="float-right">{this.state.count_member_today}</div>
                                                    </li>
                                                    {/* <li className="list-group-item">
                                <b>โพสเฉลี่ยต่อวัน</b> <div className="float-right">0</div>
                            </li> */}
                                                </ul>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนโพสของร้าน</h6>
                                                <ul className="list-group list-group-unbordered no-border-lastchild mb-0">
                                                    <li className="list-group-item">
                                                        <b>โพสทั้งหมด</b> <div className="float-right">{this.state.count_store_all}</div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>โพสวันนี้</b> <div className="float-right">{this.state.count_store_today}</div>
                                                    </li>
                                                    {/* <li className="list-group-item">
                                <b>โพสเฉลี่ยต่อวัน</b> <div className="float-right">0</div>
                            </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">โพสของร้าน</h3>
                                        {
                                            this.props.package_features.canStorePostTimeline ?
                                                <>
                                                    <div className="card-tools">
                                                        <Link to={"/manage-store/add"} className="btn btn-primary btn-sm">โพสข้อความ</Link>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.loading_post ?
                                                <div className={"absolute_loading"}>
                                                    <div className="ui active inline loader" ></div>
                                                </div>
                                                : null
                                        }
                                        <div className="timeline">
                                            {
                                                this.state.posts.length > 0 && this.state.store ?
                                                    this.state.posts.map((item, item_i) => {
                                                        return (
                                                            <div key={item_i}>
                                                                <div className="timeline-image">
                                                                    {
                                                                        !item.userId && this.state.store.photoPath && this.state.store.photoPath.length > 0 ?
                                                                            <img src={this.state.store.photoPath[0]} alt="Feed" />
                                                                            : item.userId && item.userImage ?
                                                                                <img src={item.userImage} alt="Feed" />
                                                                                : <img src={"/assets/images/no_img.png"} alt="Feed" />
                                                                    }
                                                                </div>
                                                                <i className="fas fa-user bg-green"></i>
                                                                <div className="timeline-item" style={{ overflow: 'inherit' }}>

                                                                    {
                                                                        this.props.package_features.canStoreRemoveTimeline ?
                                                                            <span className="time">
                                                                                <div className="dropdown">
                                                                                    <button
                                                                                        className="btn btn-tools dropdown-toggle remove-after"
                                                                                        type="button"
                                                                                        id="dropdownMenuButton"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                        style={{ padding: 0 }}
                                                                                    >
                                                                                        <i className="fas fa-ellipsis-h" />
                                                                                    </button>
                                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                        {
                                                                                            !item.userId && item.storeUid ?
                                                                                                <Link className="dropdown-item" to={"/manage-store/edit/" + item.id}>แก้ไข</Link>
                                                                                                : null
                                                                                        }
                                                                                        <button
                                                                                            type="button"
                                                                                            className="dropdown-item"
                                                                                            onClick={() => {
                                                                                                this.deletePost(item.postId)
                                                                                            }}
                                                                                        >ลบ </button>
                                                                                    </div>
                                                                                </div>

                                                                            </span>
                                                                            : null
                                                                    }

                                                                    <h3 className="timeline-header">
                                                                        {!item.userId && item.storeUid ? this.state.store.nameTH : null}
                                                                        {item.userId ? item.displayName : null}
                                                                        <div className="timeline-sub-header"><i className="far fa-calendar-alt"></i> {Helper.getDateThai(new Date(item.created), { is_show_time: true })}</div>
                                                                    </h3>
                                                                    <div className="timeline-body">
                                                                        {
                                                                            item.images
                                                                                && item.images.length > 0 ?
                                                                                <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                                                                    {
                                                                                        item.images.map((image, image_i) => (
                                                                                            <div key={image_i} className="slide-image-frame"><img src={image.imageUrl} alt="Feed" /></div>
                                                                                        ))
                                                                                    }
                                                                                </Slider>
                                                                                : null
                                                                        }
                                                                        <div className="description-pre-line">
                                                                            {item.message}
                                                                        </div>
                                                                        <div className="flex row" style={{ marginTop: 5 }}>
                                                                            <div className={"text-descriiption link"}
                                                                                onClick={() => {
                                                                                    // alert('1')
                                                                                    if (!item.commentCount || item.commentCount === 0) { return; }
                                                                                    Helper.reactToDom(window.$('body'),
                                                                                        <CommentList postId={item.postId} />
                                                                                    )
                                                                                }}
                                                                            >
                                                                                {/* <i className={"far fa-heart"} style={{marginRight: 10}}/> */}
                                                                                <i className={"far fa-comment-dots"} style={{ marginRight: 3 }} /> {item.commentCount || 0} comments
                                                                            </div>
                                                                            <div className={!item.emotionCount || item.emotionCount === 0 ? "right-floated text-descriiption" : "right-floated text-descriiption link"}
                                                                                onClick={() => {
                                                                                    // alert('1')
                                                                                    if (!item.emotionCount || item.emotionCount === 0) { return; }
                                                                                    Helper.reactToDom(window.$('body'),
                                                                                        <LikeList postId={item.postId} />
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <i className={"far fa-heart"} style={{ marginRight: 3 }} /> {item.emotionCount || 0} likes
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : null
                                            }

                                            <div>
                                                {
                                                    this.state.is_show_more_btn ?
                                                        <i className="fas fa-angle-down bg-gray"
                                                            onClick={() => {
                                                                this.setState({
                                                                    page_posts: this.state.page_posts + 1,
                                                                    loading_post: true,
                                                                }, () => {
                                                                    this.fetchPosts();
                                                                })
                                                            }}
                                                        />
                                                        :
                                                        <i className="fas fa-minus bg-gray" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-5">
                                <div style={{ position: 'sticky', top: 64 }}>
                                    {
                                        this.state.store && this.state.store.status === 'online' ?
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="flex row" style={{ alignItems: 'center' }}>
                                                        <div className="card-title">
                                                            สมาชิกวันนี้  {this.state.guest_all} คน
                                                        </div>
                                                        {
                                                            this.state.guest_all > 0
                                                                && this.props.package_features.canKickMember ?
                                                                <div className="right-floated">
                                                                    <button type="button"
                                                                        className="btn btn-danger btn-sm"
                                                                        ref={(ref) => {
                                                                            this.$kickall = window.$(ref);
                                                                            this.$kickall.off().on('click', () => {
                                                                                if (this.$kickall.hasClass('loading')) { return; }
                                                                                this.$kickall.addClass('loading')
                                                                                this.kickAllUsers({
                                                                                    onSuccess: () => {
                                                                                        this.$kickall.removeClass('loading');
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        this.$kickall.removeClass('loading');
                                                                                    }
                                                                                });
                                                                            })
                                                                        }}
                                                                    >
                                                                        ออกจากระบบทั้งหมด
                                                                    </button>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{ position: 'relative' }}>
                                                    {
                                                        this.state.loading_guest ?
                                                            <div className={"absolute_loading"}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.guest_all > 0 ?
                                                            <>
                                                                <ul className="users-list clearfix">
                                                                    {
                                                                        this.state.guests.map((guests, guests_i) => {
                                                                            return (
                                                                                <li key={guests_i}>
                                                                                    {
                                                                                        this.props.package_features.canKickMember ?
                                                                                            <button className="btn btn-danger btn-circle"
                                                                                                onClick={() => {
                                                                                                    this.kickUser(guests);
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-sign-out-alt" />
                                                                                            </button>
                                                                                            : null
                                                                                    }
                                                                                    <div className="profile-user-img img-frame img-fluid img-circle">
                                                                                        <img src={guests.user && guests.user.photoURL ? guests.user.photoURL : "/assets/images/no_img.png"} alt="User" />
                                                                                    </div>
                                                                                    {
                                                                                        guests.user
                                                                                            && guests.user.displayName ?
                                                                                            <Link className="users-list-name" to={'/member/view/' + guests.uid}>{guests.user.displayName}</Link>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        guests.createdAt ?
                                                                                            <span className="users-list-date">{Helper.getDateThai(guests.createdAt, { is_show_time: false })}</span>
                                                                                            : null
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <hr />
                                                            </>
                                                            : null
                                                    }


                                                    {
                                                        this.state.guest_all === 0 ?
                                                            <h5 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</h5>
                                                            : null
                                                    }
                                                    {
                                                        this.state.guest_all > 0 ?
                                                            <Pagination
                                                                now={this.state.page_guests}
                                                                all_items={this.state.guest_all}
                                                                limit={this.state.limit_guests}
                                                                onReturnPage={(page) => {
                                                                    // console.log(_guests);
                                                                    this.setState({
                                                                        page_guests: page,
                                                                        loading_guest: true,
                                                                    }, async () => {
                                                                        let _guests = await this.fetchGuests(this.state.all_guests);
                                                                        this.setState({
                                                                            guests: _guests.item,
                                                                            loading_guest: false,
                                                                        })
                                                                    })
                                                                }}
                                                                location={this.props.location}
                                                            />
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 style={{ marginBottom: 0, fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ร้านปิด</h5>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}


class LikeList extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [],
            page: 1,
            limit: 100,
            is_can_loadmore: false,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let res = await axios.get(window.api_host + '/get-likes?' + window.$.param({ postId: this.props.postId, page: this.state.page, limit: this.state.limit }));
        let list = [...this.state.list];
        let is_can_loadmore = true;
        if (res.data.res) {
            list = this.state.list.concat(res.data.res);
            if (res.data.res.length < this.state.limit) {
                is_can_loadmore = false;
            }
        }
        this.setState({
            list: list,
            is_can_loadmore: is_can_loadmore,
        }, () => {
            if (this.loadMore) {
                this.loadMore.removeClass('loading')
            }
        })
    }

    render() {
        // console.log(this.props.list)
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">รายการคนที่กด Like</h4>
                            </div> */}
                            <div className="modal-body">
                                {
                                    this.state.list.length > 0 ?
                                        this.state.list.map((item, item_i) => {
                                            return (

                                                <div class="store-item item-row form-group" key={item_i}>
                                                    <div class="icon"><div>
                                                        <img src={item.userImage} alt="img" />
                                                    </div>
                                                    </div>
                                                    <div class="description">
                                                        <div>{item.displayName}</div>
                                                    </div>
                                                    <div class="right-floated">
                                                        <div class="small-text text-right">
                                                            {Helper.getDateThai(new Date(item.created), { is_show_time: true })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                }
                                {
                                    this.state.is_can_loadmore ?
                                        <button type={"button"}
                                            className="btn btn-default"
                                            ref={(e) => { this.loadMore = window.$(e) }}
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                if (this.loadMore.hasClass('loading')) { return; }
                                                this.loadMore.addClass('loading');
                                                this.setState({
                                                    page: this.state.page + 1,
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >โหลดเพิ่ม</button>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ปิด</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}


class CommentList extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [],
            page: 1,
            limit: 5,
            is_can_loadmore: false,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let res = await axios.get(window.api_host + '/get-comments?' + window.$.param({ postId: this.props.postId, page: this.state.page, limit: this.state.limit }));
        let list = [...this.state.list];
        let is_can_loadmore = true;
        if (res.data.res) {
            list = this.state.list.concat(res.data.res);
            if (res.data.res.length < this.state.limit) {
                is_can_loadmore = false;
            }
        }
        this.setState({
            list: list,
            is_can_loadmore: is_can_loadmore,
        }, () => {
            if (this.loadMore) {
                this.loadMore.removeClass('loading')
            }
        })
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">รายการคนที่กด Like</h4>
                            </div> */}
                            <div className="modal-body">
                                {
                                    this.state.list.length > 0 ?
                                        this.state.list.map((item, item_i) => {
                                            return (
                                                <div class="store-item item-row form-group" key={item_i}>
                                                    <div class="icon"><div>
                                                        <img src={item.userImage} alt="img" />
                                                    </div>
                                                    </div>
                                                    <div class="description">
                                                        <div>{item.displayName}</div>
                                                        <div>{item.message}</div>
                                                    </div>
                                                    <div class="right-floated">
                                                        <div class="small-text text-right">
                                                            {Helper.getDateThai(new Date(item.created), { is_show_time: true })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                }
                                {
                                    this.state.is_can_loadmore ?
                                        <button type={"button"}
                                            className="btn btn-default"
                                            ref={(e) => { this.loadMore = window.$(e) }}
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                if (this.loadMore.hasClass('loading')) { return; }
                                                this.loadMore.addClass('loading');
                                                this.setState({
                                                    page: this.state.page + 1,
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >โหลดเพิ่ม</button>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ปิด</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}