import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/point/exchange`;
const baseUrlReport = `${_serverUrl}/report`;
export const getPointExchangeRequestList = async (filter) => {
    let url = `${baseUrl}/request/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getPointExchangeRequest = async (filter) => {
    let url = `${baseUrl}/request`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const updatePointExchangeRequest = async (id, data) => {
    const url = `${baseUrl}/request/${id}`;
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}