import { Link } from '@reach/router';
import React from 'react';
import { Helper } from '../../Helper';
import { getPointExchangeRequest, updatePointExchangeRequest } from '../../services/PointExchangeServices';
const axios = require('axios').default;
const statusList = [
    { id: ``, name: 'All', text: 'ทั้งหมด' },
    // { id: 1, name: 'Pending', text: 'รอ' },
    { id: 2, name: 'Success', text: 'สำเร็จ' },
    { id: 3, name: 'Canceled', text: 'ยกเลิก' },
];
const defaultLimit = 10;
export default class FoodRequest extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            page: 1,
            limit: defaultLimit,
            totalItems: 0,
            itemEnd: 0,
            is_done: true,
            statusId: 2,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    loadMore() {
        this.fetch();
    }
    async fetch() {
        let data = [...this.state.data];
        let page = 1;
        let is_done = true;
        let totalItems = 0;
        let itemEnd = 0;
        let filter = {}
        filter.page = this.state.page;
        filter.rows = this.state.limit;
        if (this.state.statusId) {
            filter.statusId = this.state.statusId
        }
        let res = await getPointExchangeRequest(filter);
        if (!res.error) {
            data = data.concat(res.items);
            page = res.page == res.endPage ? this.state.page : (this.state.page + 1);
            is_done = res.page == res.endPage;
            totalItems = res.total;
            itemEnd = res.itemEnd;
        }

        this.setState({
            is_loading: false,
            data: data,
            page: page,
            is_done: is_done,
            totalItems: totalItems,
            itemEnd: itemEnd,
        }, () => {
            if (this.$viewMore) {
                this.$viewMore.removeClass('loading')
            }
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายการแลกอาหาร </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/manage-menu">จัดการเมนู</Link></li>
                                    <li className="breadcrumb-item active">รายการแลกอาหาร</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-12 col-md-4'>
                                <div className="card">
                                    <div className="card-header d-flex w-100">
                                        <div>
                                            <h3 className="card-title">ทั้งหมด {this.state.totalItems} รายการ</h3>
                                        </div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <select className='form-control'
                                                name="statusId"
                                                defaultValue={this.state.statusId}
                                                onChange={(e) => {
                                                    let statusId = false;
                                                    if (e.target.value) {
                                                        statusId = e.target.value;
                                                    }
                                                    this.setState({
                                                        is_loading: true,
                                                        data: [],
                                                        page: 1,
                                                        limit: defaultLimit,
                                                        totalItems: 0,
                                                        itemEnd: 0,
                                                        is_done: true,
                                                        statusId: statusId,
                                                    }, () => {
                                                        this.fetch();
                                                    })
                                                }}
                                            >
                                                {
                                                    statusList.map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.id}>{item.text}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((item, item_i) => {
                                                                return (
                                                                    <li className="nav-item"
                                                                        key={item_i} style={item_i > 0 ? { borderTop: '1px solid rgba(0,0,0,.125)' } : null}
                                                                    >
                                                                        <div
                                                                            className="nav-link"
                                                                            onClick={() => {
                                                                                Helper.reactToDom(window.$('body'),
                                                                                    <RenderConfirmModal
                                                                                        item={item}
                                                                                        admin={this.props.user}
                                                                                        onSuccess={(item) => {
                                                                                            let items = [...this.state.data];
                                                                                            items[item_i] = {
                                                                                                ...items[item_i],
                                                                                                statusId: item.statusId,
                                                                                                updatedUser: item.updatedUser,
                                                                                                updated: item.updated
                                                                                            };
                                                                                            this.setState({
                                                                                                data: items
                                                                                            })
                                                                                        }}
                                                                                        renderStatus={this.renderStatus}
                                                                                    />
                                                                                )
                                                                                return;
                                                                            }}
                                                                        >
                                                                            <div className="store-item item-row">
                                                                                <div style={{ width: 100, fontSize: 20, color: '#000', }}>
                                                                                    {item.requestNumber}
                                                                                </div>
                                                                                <div className="description">
                                                                                    <div><strong style={{ color: '#000' }}>{item.food}</strong></div>
                                                                                    {
                                                                                        item.note ?
                                                                                            <div>หมายเหตุ : {item.note}</div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.table ?
                                                                                            <div>โต๊ะ : {item.table}</div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.user ?
                                                                                            <div>สั่งโดย : {item.user.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.store ?
                                                                                            <div>ร้าน : {item.store.name}</div>
                                                                                            : null
                                                                                    }
                                                                                    <div>สถานะ : {this.renderStatus(item.statusId)}</div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    {
                                                                                        item.pointAmount ?
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                {Helper.numberFormat(item.pointAmount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                                        <small>{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_done ?
                                            <div className="d-flex mt-2 align-items-center pr-2 pl-2 pt-2 pb-2">
                                                <button
                                                    ref={(ref) => { this.$viewMore = window.$(ref); }}
                                                    className="btn btn-sm btn-default"
                                                    onClick={(ref) => {
                                                        if (this.$viewMore.hasClass('loading')) { return }
                                                        this.$viewMore.addClass('loading');
                                                        this.loadMore();
                                                    }}
                                                >โหลดเพิ่ม</button> <span className='right-floated' style={{ color: '#333' }}>{this.state.itemEnd}/{this.state.totalItems}</span>
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.is_done
                                            && this.state.data.length > 0 ?
                                            <div className="d-flex mt-2 align-items-center pr-2 pl-2 pt-2 pb-2">
                                                <span className='right-floated' stlye={{ color: '#333' }}>{this.state.itemEnd}/{this.state.totalItems}</span>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
    renderStatus(id) {
        if (id == 1) {
            return <span className='text-warning'>รอ</span>
        }
        if (id == 2) {
            return <span className='text-success'>สำเร็จ</span>
        }
        if (id == 3) {
            return <span className='text-danger'>ยกเลิก</span>
        }
    }
}
class RenderConfirmModal extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    async onConfirm(data) {
        let $submit = window.$('button[type="submit"]');
        data.statusId = 2;
        data.updatedUser = this.props.admin.displayName;
        let res = await updatePointExchangeRequest(this.props.item.id, data);
        if (!res.error) {
            Helper.messageTop({ message: 'ทำการแลกเหรียญเรียบร้อยแล้ว', type_class: 'alert-success' })
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess(res);
            }
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            $submit.removeClass('loading');
            return;
        }
    }

    async onCancel() {
        let $cancel_btn = window.$('#cancel_btn');
        // if ($cancel_btn.hasClass('loading')) { return; }
        // $cancel_btn.addClass('loading')
        let res = await updatePointExchangeRequest(this.props.item.id, { statusId: 3, updatedUser: this.props.admin.displayName });
        if (!res.error) {
            Helper.messageTop({ message: 'ทำการยกเลิกแลกเหรียญเรียบร้อยแล้ว', type_class: 'alert-success' })
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess(res);
            }
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            $cancel_btn.removeClass('loading');
            return;
        }
    }

    render() {
        let item = this.props.item;
        let statusText = "";
        if (item.statusId == 1) {
            statusText = 'รอการยืนยัน'
        }
        if (item.statusId == 2) {
            statusText = 'สำเร็จ'
        }
        if (item.statusId == 3) {
            statusText = 'ยกเลิก'
        }
        let title = "แลกอาหาร";
        if (item) {
            title += ` ${item.requestNumber}`
        }

        let amount = 0;
        let bonusAmount = 0;
        if (this.props.setting && this.props.setting.storeCommissionRate) {
            let d = (parseFloat(item.pointAmount) / 100) * parseFloat(this.props.setting.storeCommissionRate)
            amount = item.pointAmount + d;
            bonusAmount = d;
        }
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            this.onConfirm(data)
                            return;

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                                <div className='modal-tools'>

                                    <button type="button"
                                        className="btn btn-default btn-icon"
                                        onClick={() => {
                                            this.$modal.modal('hide');
                                            if (this.props.onClose) { this.props.onClose(); }
                                        }}
                                    ><i className='fa fa-times' /></button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="store-item item-row">
                                    <div className="description">
                                        <div><strong style={{ color: '#000' }}>{item.food}</strong></div>
                                        {
                                            item.note ?
                                                <div>หมายเหตุ : <strong>{item.note}</strong></div>
                                                : null
                                        }
                                        {
                                            item.table ?
                                                <div>โต๊ะ : <strong>{item.table}</strong></div>
                                                : null
                                        }
                                        {
                                            item.user ?
                                                <div>สั่งโดย : <strong>{item.user.displayName}</strong></div>
                                                : null
                                        }
                                        {
                                            item.store ?
                                                <div>ร้าน : <strong>{item.store.name}</strong></div>
                                                : null
                                        }
                                        <div>สถานะ : {this.props.renderStatus(item.statusId)}</div>
                                    </div>
                                    <div className="right-floated">
                                        {
                                            item.pointAmount ?
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    {Helper.numberFormat(item.pointAmount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                </div>
                                                : null
                                        }
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <small>{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex w-100 mt-2'>
                                    {
                                        item.statusId == 1
                                            || item.statusId == 2 ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button
                                                    id="cancel_btn"
                                                    type="button"
                                                    className={"btn btn-danger"}
                                                    onClick={(e) => {
                                                        let btnRef = window.$(e.target);
                                                        if (btnRef.hasClass('loading')) { return; }
                                                        btnRef.addClass('loading')
                                                        let confirm = window.confirm('ยืนยันจะ ยกเลิกรายการอาหารนี้');
                                                        console.log(confirm)
                                                        if (confirm) {
                                                            this.onCancel();
                                                            return;
                                                        }
                                                        btnRef.removeClass('loading')

                                                    }}
                                                >
                                                    ยกเลิก
                                                </button>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </>
        )
    }
}