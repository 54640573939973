import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import Pagination from './../../Components/Pagination';
import { FormFoods } from './Foods';
const axios = require('axios').default;
const limit = 10;
export default class FoodCategory extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            count_all_item: 0,
            count_item: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : limit,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            menuType: Helper.getParameterByName('menuType') ? Helper.getParameterByName('menuType') : '',
            type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            categoryUid: Helper.getParameterByName('categoryUid') ? Helper.getParameterByName('categoryUid') : false,
            itemTypeUid: Helper.getParameterByName('itemTypeUid') ? Helper.getParameterByName('itemTypeUid') : false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let data = {};
        data.limit = this.state.limit;
        data.page = this.state.page;
        data.keyword = this.state.keyword;
        data.storeUid = this.props.uid;
        data.itemTypeUid = this.props.itemTypeUid;
        if (this.state.categoryUid) {
            data.categoryUid = this.state.categoryUid;
        }
        let res = await axios.get(window.api_host + '/food-category?' + window.$.param(data));
        // console.log(res);
        let count_item = 0;
        let count_all_item = 0;
        let food_category = [];
        if (res.data.success) {
            food_category = res.data.food_category;
            count_all_item = res.data.count_all_item;
            count_item = res.data.count_item;
        }
        this.setState({
            data: food_category,
            is_loading: false,
            count_item: count_item,
            count_all_item: count_all_item,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : limit,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                menuType: Helper.getParameterByName('menuType') ? Helper.getParameterByName('menuType') : '',
                type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                categoryUid: Helper.getParameterByName('categoryUid') ? Helper.getParameterByName('categoryUid') : false,
                itemTypeUid: Helper.getParameterByName('itemTypeUid') ? Helper.getParameterByName('itemTypeUid') : false,
            }, () => {
                this.fetch();
            })
        }
    }

    async delete(id) {
        // console.log(id);
        let res = await axios.delete(window.api_host + '/food-category?storeUid=' + this.props.uid + '&uid=' + id);
        if (res.data.success) {
            this.setState({
                is_loading: true,
            }, () => {
                this.fetch();
            })
        }
    }

    render() {
        // console.log(this.props);
        return (
            <>
                <div className="row">
                    {/* FILTER */}
                    {/* <div className="col-3 col-xs-12">
                        <div className="card card-outline ">
                            <div className="card-header">
                                <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                <div className="card-tools" style={{ margin: 0 }}>
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse" >
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body" style={{ display: 'block' }}>
                                <form className="row"
                                    ref={(ref) => { this.$form = window.$(ref) }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                            return;
                                        }
                                        let data = Helper.getFormInputObject(this.$form);
                                        let url = Helper.getQueryStringLinkFromObject({
                                            page: 1,
                                            ...data
                                        })
                                        navigate(url)
                                    }}
                                >
                                    <div className="col-12 form-group">
                                        <label>ค้นหา</label>
                                        <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อเมนู" defaultValue={this.state.keyword} />
                                    </div>
                                    <input name="menuType" type="hidden" value={this.state.menuType} />
                                    <input name="type" type="hidden" value={this.state.type} />
                                    <input name="itemTypeUid" type="hidden" value={this.state.itemTypeUid} />
                                    <div className="col-12">
                                        <div className="flex row">
                                            <Link className="btn btn-default"
                                                to={"/manage-menu"}
                                            >
                                                กลับ
                                            </Link>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button type="submit" className="btn btn-default">ค้นหา</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> */}
                    {/* LIST */}
                    <div className="col-4 col-xs-12">

                        <Link className="btn btn-default mb-3"
                            to={"/manage-menu"}
                        >
                            กลับ
                        </Link>
                        <div className="card">
                            <div className="card-header d-flex">
                                <div className='d-flex flex-column' style={{ flex: 1 }}>
                                    <h3 className="card-title" style={{ display: 'block' }}>ประเภทอาหาร</h3>
                                    {
                                        !this.state.is_loading ?
                                            <div>
                                                <small>ทั้งหมด {this.state.count_all_item} รายการ</small>
                                            </div>
                                            : null
                                    }

                                </div>
                                {
                                    !this.state.is_loading ?
                                        <div className="right-floted" style={{ margin: 0 }}>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    Helper.reactToDom(
                                                        window.$('body'),
                                                        <FormFoodCategory
                                                            mode={"create"}
                                                            storeUid={this.props.uid}
                                                            itemTypeUid={this.props.itemTypeUid}
                                                            onSuccess={() => {
                                                                this.setState({
                                                                    is_loading: true
                                                                }, () => {
                                                                    this.fetch();
                                                                })
                                                            }}
                                                        />
                                                    )
                                                }}
                                            >เพิ่มประเภทอาหาร</button>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="card-body p-0">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length === 0 ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                        : null
                                }
                                {
                                    this.state.is_loading ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.data.length > 0
                                        && !this.state.is_loading ?
                                        <>
                                            <ul className="nav nav-pills flex-column">
                                                {
                                                    this.state.data.map((item, item_i) => {
                                                        return (
                                                            <li className="nav-item" key={item_i} style={item_i > 0 ? { borderTop: '1px solid rgba(0,0,0,.125)' } : null}>
                                                                <div
                                                                    // to={"/member/view/" + item.uid}
                                                                    className="nav-link"
                                                                >
                                                                    <div className="store-item item-row">
                                                                        <div className="description">
                                                                            <div style={{ cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    let url = Helper.getQueryStringLinkFromObject({
                                                                                        page: this.state.page,
                                                                                        keyword: this.state.keyword,
                                                                                        limit: this.state.limit,
                                                                                        menuType: this.state.menuType,
                                                                                        type: 'foods',
                                                                                        categoryUid: item.id,
                                                                                        itemTypeUid: this.state.itemTypeUid,
                                                                                    })
                                                                                    navigate(url)
                                                                                }}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        </div>
                                                                        <div className="right-floated">
                                                                            {/* <div className="small-text">{Helper.getDateThai(new Date(item.createdAt).getTime())}</div>
                                                                            <div className="small-text">{Helper.getTime(new Date(item.createdAt).getTime())}</div> */}
                                                                            <div className="btn btn-group" style={{ padding: 0 }}>
                                                                                <button className="btn btn-default"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass("loading")) { return; }
                                                                                            window.$(ref).addClass("loading")
                                                                                            Helper.reactToDom(
                                                                                                window.$('body'),
                                                                                                <FormFoodCategory
                                                                                                    mode={"edit"}
                                                                                                    item={item}
                                                                                                    storeUid={this.props.uid}
                                                                                                    onSuccess={(food_category) => {
                                                                                                        let data = [...this.state.data];
                                                                                                        data[item_i] = food_category;
                                                                                                        this.setState({
                                                                                                            data: data
                                                                                                        }, () => {
                                                                                                            window.$(ref).removeClass("loading")
                                                                                                        })
                                                                                                    }}
                                                                                                    onCancel={() => {
                                                                                                        window.$(ref).removeClass("loading")
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                        })

                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-pen" />
                                                                                </button>
                                                                                <button className="btn btn-default"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass("loading")) { return; }
                                                                                            window.$(ref).addClass("loading")
                                                                                            Helper.confirmModal({
                                                                                                title: 'ลบเมนูอาหาร',
                                                                                                description: 'ยืนยันการลบเมนูอาหาร ' + item.nameTH,
                                                                                                onConfirm: () => {
                                                                                                    this.delete(item.id)
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass("loading")
                                                                                                }
                                                                                            })
                                                                                        })

                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash-alt" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>

                                        </>
                                        : null
                                }
                            </div>
                            {
                                !this.state.is_loading
                                    && this.state.data.length !== 0 ?
                                    <div className="card-footer clearfix">
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.count_all_item}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class FormFoodCategory extends React.Component {
    async add(data) {
        // console.log(data);
        let res = await axios.post(window.api_host + '/food-category', { ...data })
        if (res.data.success) {
            this.$submit.removeClass('loading');
            this.$modal.modal('hide');
            this.props.onSuccess()
        }
        if (res.data.error) {
            this.$submit.removeClass('loading');
        }
    }
    async update(data) {
        let _data = {}
        _data.uid = this.props.item.id;
        _data.update = data;
        let res = await axios.put(window.api_host + '/food-category', { ..._data })
        if (res.data.success) {
            this.props.onSuccess(res.data.food_category)
            this.$modal.modal('hide');
        }
        if (res.data.error) {
            this.$submit.removeClass('loading');
        }
    }
    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                if (this.props.onCancel) {
                                    this.props.onCancel();
                                }
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]')
                    }}
                >
                    <form
                        className="modal-dialog"
                        role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (this.props.mode === 'create') {
                                this.add(data);
                            }
                            if (this.props.mode === 'edit') {
                                this.update(data);
                            }
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">
                                    {this.props.mode === 'create' ? "เพิ่มประเภทอาหาร" : null}
                                    {this.props.mode === 'edit' ? "แก้ไขประเภทอาหาร" : null}
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>ชื่อ (ภาษาไทย)</label>
                                    <input name="nameTH" className="form-control" required={true} defaultValue={this.props.item && this.props.item.nameTH ? this.props.item.nameTH : ''} />
                                </div>
                                <div className="form-group">
                                    <label>ชื่อ (ภาษาอังกฤษ)</label>
                                    <input name="nameEN" className="form-control" required={true} defaultValue={this.props.item && this.props.item.nameEN ? this.props.item.nameEN : ''} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="hidden" name="storeUid" value={this.props.storeUid} />
                                {
                                    this.props.itemTypeUid ?
                                        <input type="hidden" name="type" value={this.props.itemTypeUid} />
                                        : null
                                }
                                <input type="hidden" name="storeUid" value={this.props.storeUid} />
                                <div className="flex row" style={{ width: '100%' }}>
                                    <button type={"button"} className="btn btn-default"
                                        onClick={() => {
                                            this.$modal.modal('hide');
                                        }}
                                    >ปิด</button>
                                    <button type="submit" className="btn btn-primary" style={{ marginLeft: 'auto' }}>
                                        {this.props.mode === 'create' ? "เพิ่ม" : null}
                                        {this.props.mode === 'edit' ? "แก้ไข" : null}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}