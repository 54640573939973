import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import FoodCategory from './FoodCategory';
import Foods from './Foods';
const axios = require('axios').default;

export default class FoodList extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            count_all_item: 0,
            count_item: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            menuType: Helper.getParameterByName('menuType') ? Helper.getParameterByName('menuType') : '',
            type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : 'foods',
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            itemTypeUid: Helper.getParameterByName('itemTypeUid') ? Helper.getParameterByName('itemTypeUid') : false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                menuType: Helper.getParameterByName('menuType') ? Helper.getParameterByName('menuType') : '',
                type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : 'foods',
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                itemTypeUid: Helper.getParameterByName('itemTypeUid') ? Helper.getParameterByName('itemTypeUid') : false,
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        if (!this.state.itemTypeUid) {
            let res = await axios.get(window.api_host + '/item-type');
            if (res.data.success) {
                if (res.data.item_type && res.data.item_type.length === 1) {
                    let data = {};
                    data.type = this.state.type;
                    data.menuType = res.data.item_type[0].code;
                    data.itemTypeUid = res.data.item_type[0].id;
                    let url = Helper.getQueryStringLinkFromObject({ ...data })
                    navigate(url)
                }
                if (res.data.item_type && res.data.item_type.length > 1) {
                    let itemType = [...res.data.item_type];
                    this.setState({
                        itemType: itemType,
                    })
                }

            }
        } else {
            this.setState({
                is_loading: false
            })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.is_loading ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader" ></div></div>
                        : null
                }
                {
                    !this.state.is_loading ?
                        <div className="card card-primary card-outline card-tabs">
                            <div className="card-header">
                                {/* <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                            <li className="nav-item">
                                                <Link className={this.state.menuType === 'food' && this.state.type === 'category' ? "nav-link active" : "nav-link"}
                                                    to={"/manage-menu?menuType=food&type=category&itemTypeUid=" + this.state.itemTypeUid}>
                                                    <h5>ประเภทอาหาร</h5>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={this.state.menuType === 'food' && this.state.type === 'foods' ? "nav-link active" : "nav-link"}
                                                    to={"/manage-menu?menuType=food&type=foods&itemTypeUid=" + this.state.itemTypeUid}>
                                                    <h5>เมนูอาหาร</h5>
                                                </Link>
                                            </li>
                                        </ul> */}
                                <div className='card-title'><h5>{this.state.type === 'category'
                                    ? "ประเภทอาหาร" : "เมนูอาหาร"}</h5></div>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-three-tabContent">
                                    <div className="tab-pane fade show active" id="foods-category-content" role="tabpanel" aria-labelledby="foods-category-tabs">

                                        {
                                            this.state.menuType === 'food'
                                                && this.state.type === 'category' ?
                                                <FoodCategory
                                                    itemTypeUid={this.state.itemTypeUid}
                                                    {...this.props}
                                                />
                                                : null
                                        }
                                        {
                                            this.state.menuType === 'food'
                                                && this.state.type === 'foods' ?
                                                <Foods
                                                    itemTypeUid={this.state.itemTypeUid}
                                                    {...this.props}
                                                />
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        : null
                }
            </>
        );
    }
}
