import React from 'react';
// import { auth } from "../firebase";
import { Link } from '@reach/router';
import {Helper} from './../Helper';
import { authProvider } from './../firebase';

export default class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            sign_out_loading: false
        }
    }
    render() {
        // let data = this.props.data.user_data
        return (
            <nav className="main-header navbar navbar-expand navbar-lightblue navbar-dark text-sm">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></div>
                    </li>
                    {/* <li className="nav-item">
                        <div className="nav-link" >
                            {data.store.name}
                        </div>
                    </li> */}
                </ul>
                <ul className="navbar-nav ml-auto">
                    {
                        this.props.data.user.displayName ?
                            <li className="nav-item">
                                <Link to={"/admin-detail/" + this.props.data.user.uid} className="nav-link">
                                    <i className="nav-icon fas fa-user-check"></i> {this.props.data.user.displayName}
                                </Link>
                            </li>

                            : null
                    }
                    <li className="nav-item">
                        <div className="nav-link btn"
                            style={{ cursor: 'pointer' }}
                            ref={(ref) => this.$signOut = window.$(ref)}
                            onClick={() => {
                                if (this.$signOut.hasClass('loading')) { return; }
                                this.$signOut.addClass('loading')
                                this.setState({
                                    sign_out_loading: true,
                                }, async () => {
                                    // auth.signOut();
                                    // window.localStorage.setItem('user', "");
                                    authProvider.signOut();
                                    Helper.setCookie('user', "",1);
                                    window.location = '/';
                                })
                            }}
                        >
                            <i className="fas fa-sign-out-alt"></i> ออกจากระบบ
                        </div>
                    </li>
                </ul>
            </nav >
        );
        
    }
}