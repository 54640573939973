import React from 'react';
import Pagination from '../../../Components/Pagination';
import { Helper } from '../../../Helper';
import { getLiveRequest, getLivesResultSet } from '../../../services/ArtistServices';

export default class ArtistLiveHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            live_loading: true,
            request_loading: false,

            lives: [],
            lives_count: 0,
            live_page: 1,
            live_limit: 10,

            live_seleted: false,

            requests: [],
            request_page: 1,
            request_limit: 5,
            is_done: false,
            request_total: 0,
            request_end: 0,
        }
    }
    firstTimeFetch() {
        if (this.state.lives.length == 0) {
            this.fetch();
        }
    }
    async fetch() {
        let res = await getLivesResultSet({
            artistId: this.props.uid,
            page: this.state.live_page,
            rows: this.state.live_limit,
            statusIds: 3,
        });
        let lives = [];
        let lives_count = 0;
        if (!res.error) {
            lives = res.items;
            lives_count = res.total;
        }
        this.setState({
            lives: lives,
            lives_count: lives_count,
            live_loading: false
        })
    }
    async fetchRequest(is_refresh) {
        let res = await getLiveRequest(this.state.live_seleted.id, {
            page: this.state.request_page,
            rows: this.state.request_limit,
            sortBy: 'tip-desc'
        })
        let requests = [];
        if (!is_refresh) {
            requests = [...this.state.requests]
        }
        let request_page = 1;
        let is_done = false;
        let request_total = 0;
        let request_end = 0;
        if (!res.error) {
            requests = requests.concat(res.items);
            request_page = res.page == res.endPage ? this.state.request_page : (this.state.request_page + 1);
            is_done = res.page == res.endPage;
            request_total = res.total;
            request_end = res.itemEnd;
        } else {
            is_done = true;
        }
        console.log(is_done)
        this.setState({
            requests: requests,
            is_done: is_done,
            request_page: request_page,
            request_total: request_total,
            request_end: request_end,
            request_loading: false,
        }, () => {
            if (this.$viewMore) {
                this.$viewMore.removeClass('loading')
            }
        })
        console.log(res)
    }
    render() {
        return (
            <div className='card-body'>
                {
                    this.props.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                {
                    !this.props.is_loading ?
                        <div className='row pt-4'>
                            <div className='col-12 col-md-4'>
                                {
                                    this.state.live_loading ?
                                        <div className={"absolute_loading"}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                <div className="flex row flex-center mb-3">
                                    <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>การขึ้นแสดง
                                        <div className="badge badge-warning" style={{ marginLeft: 10 }}>
                                            {Helper.numberFormat(this.state.lives_count)} <i className="fas fa-guitar"></i>
                                        </div>
                                    </h5>
                                </div>
                                <div className="card mb-3 mt-3">
                                    {
                                        this.state.lives.length > 0 ?
                                            <ul className="list-group">
                                                {
                                                    this.state.lives.map((item, item_i) => {
                                                        return (
                                                            <button className={this.state.live_seleted && item.id === this.state.live_seleted.id ? "list-group-item list-group-item-info list-group-item-action" : "list-group-item list-group-item-action"} key={item_i}
                                                                onClick={() => {
                                                                    if (this.state.live_seleted.id == item.id) { return; }
                                                                    let live_seleted = item;
                                                                    this.setState({
                                                                        request_loading: true,
                                                                        live_seleted: live_seleted,
                                                                        request_page: 1,
                                                                    }, () => {
                                                                        this.fetchRequest(true);
                                                                    })
                                                                }}
                                                                type="button"
                                                            >
                                                                <div className="flex row" style={{ width: '100%' }}>
                                                                    <div>
                                                                        <b style={{ color: '#000' }}>{item.store.name}</b>
                                                                        <div>ขึ้นเวที : {Helper.getDateThai(new Date(item.start), { is_show_time: true, plus_hours: 7 })}</div>
                                                                        <div>ลงเวที : {Helper.getDateThai(new Date(item.end), { is_show_time: true, plus_hours: 7 })}</div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            : null
                                    }
                                    {
                                        this.state.lives.length === 0 ?
                                            <div className="card-body">
                                                <div className="flex row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                                <Pagination
                                    now={this.state.live_page}
                                    all_items={this.state.lives_count}
                                    limit={this.state.live_limit}
                                    location={this.props.location}
                                    onReturnPage={(page) => {
                                        this.setState({
                                            live_loading: true,
                                            live_page: page,
                                            live_seleted: false,
                                            requests: [],
                                            request_page: 1,
                                            request_limit: 5,
                                            is_done: false,
                                            request_total: 0,
                                            request_end: 0,
                                        }, () => {
                                            this.fetch();
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.live_seleted ?
                                    <div className='col-12 col-md-4'>
                                        <>

                                            <div className="flex row flex-center mb-3">
                                                <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>การขอเพลง
                                                    <div className="badge badge-warning" style={{ marginLeft: 10 }}>
                                                        {Helper.numberFormat(this.state.request_total)} <i className="fas fa-guitar"></i>
                                                    </div>
                                                </h5>
                                            </div>
                                            <ul className="list-group" style={{ position: 'relative' }}>
                                                {
                                                    this.state.request_loading ?
                                                        <div className={"absolute_loading"}>
                                                            <div className="ui active inline loader" ></div>
                                                        </div>
                                                        : null
                                                }
                                                <RenderList
                                                    data={this.state.requests}
                                                    is_loading={this.props.is_loading}
                                                />
                                            </ul>
                                            {
                                                this.state.request_total > 0 ?
                                                    <div className='d-flex mt-3'>
                                                        {
                                                            !this.state.is_done ?
                                                                <button
                                                                    ref={(ref) => { this.$viewMore = window.$(ref) }}
                                                                    className={"btn btn-default"}
                                                                    onClick={() => {
                                                                        if (this.$viewMore.hasClass('loading')) { return; }
                                                                        this.$viewMore.addClass('loading')
                                                                        this.fetchRequest();
                                                                    }}
                                                                >
                                                                    โหลดเพิ่ม
                                                                </button>
                                                                : null
                                                        }
                                                        <div className='right-floated'>
                                                            {this.state.request_end}/{this.state.request_total}
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </>
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }
            </div>
        )
    }
}
class RenderList extends React.Component {
    renderStatusId(statusId) {
        if (statusId == 1) {
            return <p className="mb-0" style={{ fontSize: 12, color: '#000' }}>สถานะ : <span style={{ color: 'orange' }}>ขอเพลง</span></p>
        }
        if (statusId == 2) {
            return <p className="mb-0" style={{ fontSize: 12, color: '#000' }}>สถานะ : <span style={{ color: 'blue' }}>ยอมรับ</span></p>
        }
        if (statusId == 3) {
            return <p className="mb-0" style={{ fontSize: 12, color: '#000' }}>สถานะ : <span style={{ color: 'red' }}>ปฏิเสธ</span></p>
        }
        if (statusId == 4) {
            return <p className="mb-0" style={{ fontSize: 12, color: '#000' }}>สถานะ : <span style={{ color: 'green' }}>เล่นเสร็จ</span></p>
        }
        return null
    }
    render() {
        if (this.props.is_loading) {
            return null;
        }
        if (!this.props.is_loading && (!this.props.data || (this.props.data.length === 0))) {
            return (
                <li className="list-group-item">
                    <strong>ยังไม่มีการขอเพลง</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        return (
                            <li key={i} className="list-group-item"
                                onClick={() => {
                                    if (!this.props.onClickList) { return; }
                                    else {
                                        this.props.onClickList(item)
                                    }
                                }}
                            >
                                <div className="d-flex">
                                    <p className='mb-0'><strong>{item.musicName}</strong> </p>
                                    {
                                        item.tipAmount
                                            && item.tipAmount > 0 ?
                                            <div className="right-floated d-flex align-items-center">
                                                <p className=" mb-0"><strong>{item.tipAmount ? Helper.numberFormat(item.tipAmount, { decimal: 2 }) : 0}</strong>
                                                    <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                </p>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <p className='mb-0' style={{ fontSize: 14, color: '#777' }}>ศิลปิน {item.artistName} </p>
                                        {
                                            item.user && item.user.displayName ?
                                                <p className='mb-0' style={{ fontSize: 14, color: '#777' }}>ขอเพลงโดย {item.user.displayName}</p>
                                                : null
                                        }
                                        {
                                            item.created ?
                                                <p className="mb-0" style={{ fontSize: 12, color: '#777' }}>{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                                : null
                                        }
                                        {
                                            item.statusId ? this.renderStatusId(item.statusId) : null
                                        }
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }
}