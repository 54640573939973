import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../Helper';
import { postPayment } from '../../services/PaymentServices';
import { getAllPackage } from '../../services/CoinPackageServices';
import { getUser } from '../../services/UserServices';
import { getChannels, getPacakgeChannels } from '../../services/ChannelsServices'
import CryptoJS from 'crypto-js';
import { getStore, getStorePackageChannels } from '../../services/StoreServices';
import InputMask from 'react-input-mask';
const axios = require('axios').default;

window.wedev = {
    secretKey: process.env.REACT_APP_WEDEV_SECRET_KEY,
    merchantId: process.env.REACT_APP_WEDEV_MERCHANT_ID,
    merchantName: process.env.REACT_APP_WEDEV_MERCHANT_NAME,
    merchantCompany: process.env.REACT_APP_WEDEV_MERCHANT_COMPANY,
}
export default class PaymentChannel extends React.Component {

    constructor() {
        super();
        let years = [];
        let i;
        let nowYear = new Date().getFullYear()
        for (i = nowYear; i <= nowYear + 10; i++) {
            years.push(i)
        }
        this.state = {
            is_loading: true,
            channels: [],
            packages: [],
            list: [],
            selected_channel: false,
            selected_card: false,
            selected_pacakge: false,
            redirect: false,
            store: false,
            postForm: {},
            months: [
                '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
            ],
            years: [...years]
        }
    }

    componentDidMount() {
        this.fetchChannel();
    }

    async fetchChannel() {
        let res = await getChannels();
        let store = false;
        let store_res = await axios.post(window.api_host + '/store', {
            data: {
                uid: this.props.uid,
                package_type: true,
                only_main_package: true,
            }
        });
        if (store_res.data.success) {
            store = store_res.data.response.store;
        }
        let _store = await getStore(this.props.uid);
        if (!_store.error) {
            store = { ...store, ..._store };
        }
        let channels = [];
        let is_CP = false;
        if (!res.error) {
            channels = res;
            let get_cp = res.filter((e) => { return e.channelCode == 'CP' })
            is_CP = get_cp.length > 0 ? get_cp[0] : false;
        }
        this.setState({
            store: store,
            channels: channels,
            is_CP: is_CP,
            is_loading: false,
        })
    }
    async setDefault() {

        let postForm = {}
        postForm.merchantId = window.wedev.merchantId;
        postForm.buyerName = this.state.store.name || '';
        postForm.buyerSurname = ``;
        postForm.buyerEmail = this.state.store.email || ``;
        postForm.buyerMobile = this.state.store.phoneNumber ? this.state.store.phoneNumber.replace('+66', '0') : ``;
        postForm.amount = ``;
        postForm.currencyCode = `THB`;
        postForm.productDescription = ``;
        postForm.merchantReference = ``;
        postForm.redirectUrl = `${window.location.host}/package/payment/`;
        postForm.NotificationUrl = `${process.env.REACT_APP_SKITZ_API}/api/v1/wedev/payment`;
        return {
            postForm: postForm,
        };
    }

    async fetchPackageChannel() {
        let id = this.state.selected_channel ? this.state.selected_channel.id : false;
        let packages = [];
        if (id) {
            let res = await getStorePackageChannels(id);
            if (!res.error) {
                packages = res;
            }
        }
        this.setState({
            packages: packages,
            is_loading_package: false,
        })
    }
    async fetch() {
        // let res = await axios.get(`${window.api_host}/coin/package`);

        let res = await getAllPackage();
        let list = [];
        if (!res.error) {
            list = res;
        }
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        let user = await getUser(currentUser.uid);
        if (user) {
            currentUser.currentCoin = user.currentCoin;
            currentUser.userImage = user.userImage;
        }
        let postForm = {}
        postForm.merchantId = window.wedev.merchantId;
        postForm.buyerName = currentUser.displayName || '';
        postForm.buyerSurname = ``;
        postForm.buyerEmail = currentUser.email || ``;
        postForm.buyerMobile = currentUser.phoneNumber ? currentUser.phoneNumber.replace('+66', '0') : ``;
        postForm.amount = ``;
        postForm.currencyCode = `THB`;
        postForm.productDescription = ``;
        postForm.merchantReference = ``;
        postForm.redirectUrl = `${window.location.host}/package/payment/`;
        postForm.NotificationUrl = `${process.env.REACT_APP_SKITZ_API}/api/v1/wedev/payment`;
        // postForm.redirectUrl = ``;
        this.setState({
            currentUser: currentUser,
            is_loading: false,
            list: list,
            postForm: postForm,
        })
    }


    selectdChannel(data, card) {
        this.setState({
            selected_channel: data,
            selected_card: card,
            selected_pacakge: false,
            is_loading_package: true
        }, () => {
            this.fetchPackageChannel()
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">เติมเงินร้าน </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/store"}>ร้านค้า</Link></li>
                                    <li className="breadcrumb-item active">เติมเงินร้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    {
                        this.state.is_loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader" ></div></div>
                            : null
                    }
                    {
                        !this.state.is_loading ?
                            <div className="container-fluid">

                                {
                                    this.state.channels.length > 0 ?
                                        <>
                                            <div className='row justify-content-center align-items-center d-flex'>
                                                {
                                                    this.state.channels.map((data, i) => {
                                                        let is_selected = this.state.selected_channel && this.state.selected_channel.id === data.id;
                                                        // if (data.channelCode == 'CP') { return null }
                                                        return (
                                                            <div className='col-12 col-md-3 col-lg-2 mb-1' key={i}>
                                                                <div className={is_selected ? 'card card-selected' : 'card'}
                                                                    style={{ transition: 'all 0.1s' }}
                                                                    onClick={() => {
                                                                        if (this.state.selected_channel && this.state.selected_channel.id == data.id) { return; }
                                                                        if (data.channelCode == 'CP') {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <CreditCardForm
                                                                                    onSuccess={(card) => {
                                                                                        this.selectdChannel(data, card)
                                                                                    }}
                                                                                    selected_card={this.state.selected_card}
                                                                                />
                                                                            )
                                                                        } else {
                                                                            this.selectdChannel(data, this.state.selected_card)
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className='card-body d-flex'>
                                                                        <div>
                                                                            {
                                                                                is_selected ?
                                                                                    <i className='fas fa-check-circle' style={{ color: 'green', position: 'absolute', top: 10, right: 10, fontSize: 20 }}></i>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                data && data.name ?
                                                                                    <h3 className='mb-0' style={{ fontSize: 18 }}>{data.name}</h3>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                data && data.description ?
                                                                                    <p className='text-mute' style={{ color: '#888' }}>{data.description}</p>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                data.channelCode == 'CP' && this.state.selected_card ?
                                                                                    <>
                                                                                        <p className='text-mute mb-0' style={{ color: '#888' }}>**** **** **** {this.state.selected_card.cardNumber.slice(-4)}</p>
                                                                                        {/* <p className='text-mute mb-0' style={{ color: '#888' }}>{this.state.selected_card.cardName}</p> */}
                                                                                        <p className='text-mute mb-0' style={{ color: '#888' }}>{this.state.selected_card.cardExpiredMonth}/{this.state.selected_card.cardExpiredYear}</p>
                                                                                    </>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <img src={data.icon} style={{ width: 80 }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                        : <div className='d-flex align-items-center mt-0'>
                                            <h3 className={"mr-1 mb-0 text-center"}>ไม่มีช่องทางการชำระเงิน</h3>
                                        </div>
                                }
                                {
                                    this.state.channels.length > 0 ?
                                        <>
                                            {
                                                this.state.selected_channel ? <hr /> : null
                                            }
                                            <div className='row justify-content-center align-items-start d-flex'>
                                                <div className='col-12 col-md-3' style={{ position: 'relative' }}>

                                                    {
                                                        !this.state.selected_channel
                                                            && !this.state.is_loading_package
                                                            && this.state.packages.length == 0 ?
                                                            <>
                                                                <h3 className='text-center'>กรุณาเลือกแพ็กเกจ</h3>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.state.selected_channel
                                                            && !this.state.is_loading_package
                                                            && this.state.packages.length == 0 ?
                                                            <>
                                                                <h3 className='text-center'>ไม่มีแพ็กเกจ</h3>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.state.is_loading_package ?
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, position: 'absolute', left: 0, top: 0 }}>
                                                                <div className="ui active inline loader small mr-2" ></div> กำลังโหลดข้อมูล
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.selected_channel
                                                            && !this.state.is_loading_package
                                                            && this.state.packages.length > 0 ?
                                                            <>
                                                                <h3 className='text-center'>เลือกแพ็กเกจ</h3>
                                                                <ol className="list-group list-group-numbered ">
                                                                    {
                                                                        this.state.packages.map((item, i) => {
                                                                            let is_selected = this.state.selected_pacakge && this.state.selected_pacakge.id === item.id;
                                                                            return (
                                                                                <li className={is_selected ? "list-group-item d-flex justify-content-between align-items-start list-group-item-action card-selected" : "list-group-item d-flex justify-content-between align-items-start list-group-item-action"} key={i}
                                                                                    style={{ transition: 'all 0.1s' }}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            selected_pacakge: item
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <h5 >{item.name}</h5>
                                                                                        {
                                                                                            item.description ?
                                                                                                <p className='mb-0'>{item.description}</p>
                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                                        <div className='d-flex justify-content-end align-items-center'>
                                                                                            <div>฿ {Helper.numberFormat(item.price)}</div>
                                                                                            <i className="fas fa-exchange-alt mr-2 ml-2"></i>
                                                                                            <div>{Helper.numberFormat(item.coinAmount)} <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ height: 15 }} /></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ol>
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                                {
                                                    !this.state.selected_channel ? null
                                                        :
                                                        <div className='col-12 col-md-3 offset-md-1' style={{ position: 'relative' }}>
                                                            <PaymentSummary
                                                                state={this.state}
                                                            />
                                                            {
                                                                this.state.list.length > 0
                                                                    || this.state.packages.length > 0 ?
                                                                    <SubmitForm
                                                                        state={this.state}
                                                                    />
                                                                    : null
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </>
                                        : null
                                }

                            </div>
                            : null
                    }
                </section>
            </>
        );
    }
}
class SubmitForm extends React.Component {
    constructor() {
        super()
        this.state = {}
    }
    setDefault() {
        let state = this.props.state;
        let postForm = {}
        postForm.merchantId = window.wedev.merchantId;
        postForm.buyerName = state.store.name || '';
        postForm.buyerSurname = ``;
        postForm.buyerEmail = state.store.contact && state.store.contact.email ? state.store.contact.email : ``;
        postForm.buyerMobile = state.store.contact && state.store.contact.telephone ? state.store.contact.telephone.replace('+66', '0') : ``;
        postForm.amount = ``;
        postForm.currencyCode = `THB`;
        postForm.productDescription = ``;
        postForm.merchantReference = ``;
        postForm.redirectUrl = `${window.location.host}/payment/result`;
        postForm.NotificationUrl = `${process.env.REACT_APP_SKITZ_API}/api/v1/wedev/payment`;
        return postForm;
    }
    async onSubmit() {
        let state = this.props.state;
        if (this.submit.hasClass('loading')) { return; }
        this.submit.addClass('loading');
        if (!this.props.state.selected_pacakge) {
            Helper.messageTop({ message: 'กรุณาเลือกแพ็กเกจ' });
            this.submit.removeClass('loading');
            return;
        }
        window.Omise.setPublicKey(process.env.REACT_APP_OMISE_PUBLIC_KEY);
        // window.Omise.setPublicKey(undefined);

        let data = { ...this.setDefault() }
        if (state.selected_channel && state.selected_channel.channelCode && state.selected_channel.channelCode == 'CP') {
            var cardInformation = {
                name: state.selected_card.cardName,
                number: state.selected_card.cardNumber,
                expiration_month: state.selected_card.cardExpiredMonth,
                expiration_year: state.selected_card.cardExpiredYear,
                security_code: state.selected_card.securityCode
            };
            await window.Omise.createToken('card', cardInformation, async (statusCode, response) => {
                // console.log(statusCode, response)
                if (statusCode === 200) {
                    data.cardToken = response.id;
                    this.onSumbitRedirect(data);
                } else {
                    Helper.messageTop({ message: response.message });
                    this.submit.removeClass('loading');
                    return;
                }
            });
        } else {
            this.onSumbitRedirect(data);
        }
    }

    async onSumbitRedirect(data) {
        let state = this.props.state;
        let paymentPost = {
            amount: state.selected_pacakge.price,
            coinAmount: state.selected_pacakge.coinAmount,
            storeId: state.store.id,
            payerName: data.buyerName,
            paymentTypeId: 4,
            paymentChannelId: 3
        }
        if (state.selected_channel && state.selected_channel.channelCode) {
            paymentPost.channelCode = state.selected_channel.channelCode;
        }
        // console.log(paymentPost, data);
        // this.submit.removeClass('loading');
        // return;
        let res = await postPayment(paymentPost);
        if (res.error) {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' });
            this.submit.removeClass('loading');
            return;
        }
        data.amount = state.selected_pacakge.price.toFixed(2);
        data.productDescription = `${data.buyerName} เพิ่มเหรียญ ${Helper.numberFormat(state.selected_pacakge.coinAmount)} Skitz Coin จากแพ็จเกจ ${state.selected_pacakge.name} ในราคา ${Helper.numberFormat(state.selected_pacakge.price)} บาท`;
        data.merchantReference = res.transactionNumber;

        let preparePaintext = { ...data };
        delete preparePaintext.checkSum;
        delete preparePaintext.productItemsJson;
        delete preparePaintext.NotificationUrl;
        delete preparePaintext.cardToken;
        //set paintext
        let paintext = ``;
        for (const item of Object.entries(preparePaintext)) {
            // if (item[1]) {
            paintext += item[1];
            // }
        }
        // console.log(paintext)
        // console.log(window.wedev.secretKey)
        // encrypted
        let encrypted = CryptoJS.HmacSHA256(paintext, window.wedev.secretKey).toString();
        data.checkSum = encrypted;
        let productItemsJson = [{
            productName: state.selected_pacakge.name,
            price: Helper.numberFormat(state.selected_pacakge.price, { decimal: 1 }),
        }];
        data.productItemsJson = JSON.stringify(productItemsJson);
        this.setState({
            postForm: data
        }, () => {
            this.postForm.trigger('submit');
            // console.log(this.state.postForm)
            // this.submit.removeClass('loading');
        })
    }

    render() {
        let state = this.props.state;
        return (
            <>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSubmit()
                    }}
                    className="d-flex justify-content-center"
                >
                    <button
                        ref={(ref) => {
                            this.submit = window.$(ref);
                        }}
                        type={'submit'}
                        className="btn btn-lg rounded-pill login-btn inverted text-lg" style={{ width: '100%' }}>
                        ดำเนินการชำระเงิน <i className='fas fa-arrow-right'></i>
                    </button>
                </form>
                <form
                    ref={(ref) => { this.postForm = window.$(ref) }}
                    method={"post"}
                    action={process.env.REACT_APP_WEDEV_POST_PAYMENT}
                >
                    <input type="hidden" name="merchantId" value={window.wedev.merchantId} />

                    <input type="hidden" name="buyerName" value={this.state.postForm && this.state.postForm.buyerName ? this.state.postForm.buyerName : ''} />
                    <input type="hidden" name="buyerSurname" value={this.state.postForm && this.state.postForm.buyerSurname ? this.state.postForm.buyerSurname : ''} />
                    <input type="hidden" name="buyerEmail" value={this.state.postForm && this.state.postForm.buyerEmail ? this.state.postForm.buyerEmail : ''} />
                    <input type="hidden" name="buyerMobile" value={this.state.postForm && this.state.postForm.buyerMobile ? this.state.postForm.buyerMobile : ''} />
                    <input type="hidden" name="amount" value={this.state.postForm && this.state.postForm.amount ? this.state.postForm.amount : '0.00'} />
                    <input type="hidden" name="currencyCode" value={this.state.postForm && this.state.postForm.currencyCode ? this.state.postForm.currencyCode : ''} />
                    <input type="hidden" name="productDescription" value={this.state.postForm && this.state.postForm.productDescription ? this.state.postForm.productDescription : ''} />
                    <input type="hidden" name="productItemsJson" value={this.state.postForm && this.state.postForm.productItemsJson ? this.state.postForm.productItemsJson : ''} />
                    <input type="hidden" name="merchantReference" value={this.state.postForm && this.state.postForm.merchantReference ? this.state.postForm.merchantReference : ''} />
                    <input type="hidden" name="notificationUrl" value={this.state.postForm && this.state.postForm.NotificationUrl ? this.state.postForm.NotificationUrl : ''} />
                    <input type="hidden" name="redirectUrl" value={this.state.postForm && this.state.postForm.redirectUrl ? this.state.postForm.redirectUrl : ''} />
                    <input type="hidden" name="checkSum" value={this.state.postForm && this.state.postForm.checkSum ? this.state.postForm.checkSum : ''} />
                    {
                        state.selected_channel && state.selected_channel.channelCode ?
                            <>
                                <input type="hidden" name="channelCode" value={state.selected_channel && state.selected_channel.channelCode ? state.selected_channel.channelCode : ''} />
                                {
                                    state.selected_channel.channelCode == "CP" ?
                                        <>
                                            {/* <input type="hidden" name="cardName" value={this.state.postForm && this.state.postForm.cardName ? this.state.postForm.cardName : ''} />
                                            <input type="hidden" name="cardNumber" value={this.state.postForm && this.state.postForm.cardNumber ? this.state.postForm.cardNumber : ''} />
                                            <input type="hidden" name="cardExpiredMonth" value={this.state.postForm && this.state.postForm.cardExpiredMonth ? this.state.postForm.cardExpiredMonth : ''} />
                                            <input type="hidden" name="cardExpiredYear" value={this.state.postForm && this.state.postForm.cardExpiredYear ? this.state.postForm.cardExpiredYear : ''} />
                                            <input type="hidden" name="securityCode" value={this.state.postForm && this.state.postForm.securityCode ? this.state.postForm.securityCode : ''} /> */}
                                            <input type="hidden" name="cardToken" value={this.state.postForm && this.state.postForm.cardToken ? this.state.postForm.cardToken : ''} />
                                        </>
                                        : null
                                }
                            </>
                            : null
                    }
                </form>
            </>
        )
    }
}
class PaymentSummary extends React.Component {
    render() {
        let state = this.props.state;
        let selected_pacakge = state.selected_pacakge;
        let selected_channel = state.selected_channel;
        return (
            <>
                <h3 className='text-center'>สรุปรายการชำระเงิน</h3>
                <div className='card'>
                    <div className='card-body' style={{ fontSize: 18 }}>

                        <div className='d-flex'>
                            <div className='text-muted' >ช่องทางการชำระเงิน</div>
                            <div style={{ marginLeft: 'auto' }}>
                                {
                                    selected_channel ?
                                        <>
                                            <img src={selected_channel.icon} style={{ width: 24 }} /> {selected_channel.name}
                                        </> : '-'
                                }
                            </div>
                        </div>
                        {
                            selected_channel.channelCode == 'CP' ?
                                <>
                                    <hr />
                                    <div className='d-flex'>
                                        <div className='text-muted' >ข้อมูลบัตร</div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <div style={{ textAlign: 'right' }}>{state.selected_card.cardName}</div>
                                            <div style={{ textAlign: 'right' }}>**** **** **** {state.selected_card.cardNumber.slice(-4)}</div>
                                            <div style={{ textAlign: 'right' }}>{state.selected_card.cardExpiredMonth}/{state.selected_card.cardExpiredYear}</div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            selected_pacakge ?
                                <>
                                    <hr />
                                    <div className='d-flex'>
                                        <div className='text-muted' >แพ็กเกจ</div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            {
                                                selected_pacakge ?
                                                    selected_pacakge.name : '-'
                                            }
                                        </div>
                                    </div>

                                    <div className='d-flex'>
                                        <div className='text-muted' >จำนวนเหรียญที่ได้รับ</div>
                                        <div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
                                            {
                                                selected_pacakge ?
                                                    <div>
                                                        <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ height: 15 }} /> {Helper.numberFormat(selected_pacakge.coinAmount)}
                                                    </div>
                                                    : '-'

                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='d-flex'>
                                        <div className='text-muted' >ราคา</div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            {
                                                selected_pacakge ?
                                                    `฿ ${Helper.numberFormat(selected_pacakge.price)}` : '-'
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    }
}
class CreditCardForm extends React.Component {
    constructor() {
        super();
        let years = [];
        let i;
        let nowYear = new Date().getFullYear()
        for (i = nowYear; i <= nowYear + 10; i++) {
            years.push(i)
        }
        this.state = {
            months: [
                '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
            ],
            years: [...years]
        }
    }
    render() {
        let title = "กรอกข้อมูลบัตรเครดิต"
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            let _data = { ...data }
                            if (!data.cardNumber || !data.cardName || !data.cardExpiredMonth || !data.cardExpiredYear || !data.securityCode) {
                                Helper.messageTop({ message: 'กรุณากรอกข้อมูลบัตรเครดิต' });
                                $submit.removeClass('loading');
                                return;
                            }
                            if (data.cardNumber) {
                                _data.cardNumber = data.cardNumber.replace(/\s/g, '');
                            }
                            this.props.onSuccess(_data)
                            this.$modal.modal('hide');
                            return;

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                <div className='form-group'>
                                    <InputMask
                                        mask="9 9 9 9   9 9 9 9   9 9 9 9   9 9 9 9"
                                        name="cardNumber"
                                        id="cardNumber"
                                        className='form-control'
                                        placeholder='Card Number'
                                        defaultValue={this.props.selected_card.cardNumber}
                                    />
                                </div>
                                <div className='form-group'>
                                    <input name="cardName" id="cardName" className='form-control' placeholder='Card Name' defaultValue={this.props.selected_card.cardName} />
                                </div>
                                <div className='row' >
                                    <div className='col-12 col-md-4'>
                                        <select className='form-control'
                                            name="cardExpiredMonth"
                                            defaultValue={this.props.selected_card.cardExpiredMonth}
                                        >
                                            <option value="">Month</option>
                                            {
                                                this.state.months.map((month) => {
                                                    return (
                                                        <option value={month} key={month}>{month}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <select className='form-control'
                                            name="cardExpiredYear"
                                            defaultValue={this.props.selected_card.cardExpiredYear}
                                        >
                                            <option value="">Year</option>
                                            {
                                                this.state.years.map((year) => {
                                                    return (
                                                        <option value={year} key={year}>{year}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <input name="securityCode" id="securityCode" className='form-control' placeholder='***' style={{ letterSpacing: 5 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        if (this.props.onClose) { this.props.onClose(); }
                                    }}
                                >ปิด</button>
                                <button type="submit" className={"btn btn-primary"}>ยืนยัน</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}