import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../Helper';
const axios = require('axios').default;

export default class Transection extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/transection-checking', { transectionUid: this.props.transectionUid });
        // console.log(res.data)
        let data = false;
        if (res.data.success) {
            data = res.data.data;
        }
        this.setState({
            is_loading: false,
            data: data,
        })
    }

    paymentByText() {
        if (this.state.data.billing.paymentBy === 'creditcard') {
            return "บัตรเครดิต";
        }
        if (this.state.data.billing.paymentBy === 'freePackage') {
            return "ฟรีแพ็กเกจ";
        }
        if (this.state.data.billing.paymentBy === 'adminService') {
            let text = "ผู้ดูแลระบบ";
            if (this.state.data.billing.admin && this.state.data.billing.admin.displayName) {
                text = `${text} - ${this.state.data.billing.admin.displayName}`;
            }
            return text
        }
    }
    render() {
        console.log(this.state.data.billing)
        let expiredMonth = false;
        let expiredYear = false;
        let startMonth = false;
        let startYear = false;
        let total_price = 0;
        if (this.state.data && this.state.data.billing) {
            let date = Helper.monthRage(this.state.data.billing.expiredAt)
            expiredMonth = date.expiredMonth;
            expiredYear = date.expiredYear;
            startMonth = date.startMonth;
            startYear = date.startYear;
            // total_price = this.state.data.billing.summary.total_price.toFixed(2);
            total_price = this.state.data.billing.summary.package_price.toFixed(2);
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ชำระเงิน </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/store"}>ร้านค้า</Link></li>
                                    <li className="breadcrumb-item active">ชำระเงิน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    {
                        this.state.is_loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader" ></div></div>
                            : null
                    }
                    {
                        !this.state.is_loading ?
                            <div className="container-fluid">
                                {
                                    this.state.data
                                        && this.state.data.billing
                                        && this.state.data.billing.paymentStatus === 'successful' ?
                                        <div className="callout callout-success">
                                            <h5><i className="fas fa-check" style={{ color: 'green' }}></i> ชำระเงินสำเร็จ :</h5> ท่านได้ทำการชำระเงินผ่านทางบัตรเครดิตสำเร็จแล้ว
                                        </div>
                                        : null
                                }
                                <div className="invoice p-3 mb-3">

                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                <img src={window.location.origin + "/assets/images/logo.png"} alt="skitz Logo" style={{ maxWidth: '100%', maxHeight: 40 }} />
                                                {/* <small className="float-right">วันที่: {Helper.getDateThai(new Date(), { dateNumber: true, space: '/' })}</small> */}
                                            </h4>
                                        </div>

                                    </div>

                                    <div className="row invoice-info">
                                        <div className="col-sm-4 invoice-col">
                                            From
                                            <address>
                                                <strong>Skitz</strong><br />
                                                {/* 795 Folsom Ave, Suite 600<br />
                                                        San Francisco, CA 94107<br />
                                                            Phone: (804) 123-5432<br />
                                                                Email: info@almasaeedstudio.com */}
                                            </address>
                                        </div>

                                        <div className="col-sm-4 invoice-col">
                                            To
                                            <address>

                                                <strong>ร้าน </strong>
                                                {
                                                    this.state.data
                                                        && this.state.data.transection
                                                        && this.state.data.transection.store
                                                        && this.state.data.transection.store.nameTH ?
                                                        this.state.data.transection.store.nameTH
                                                        : '-'
                                                }
                                                <br />
                                                {
                                                    this.state.data
                                                        && this.state.data.transection
                                                        && this.state.data.transection.store
                                                        && this.state.data.transection.store.contact ?
                                                        <>
                                                            {
                                                                this.state.data.transection.store.contact.address ?
                                                                    <div>{this.state.data.transection.store.contact.address}</div>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.transection.store.contact.telephone ?
                                                                    <div>Phone: {this.state.data.transection.store.contact.telephone}</div>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.transection.store.contact.email ?
                                                                    <div>Email: {this.state.data.transection.store.contact.email}</div>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </address>
                                        </div>

                                        <div className="col-sm-4 invoice-col">
                                            <b>Invoice #{this.state.data.billing.uid}</b><br />
                                            <br />
                                            <b>วันที่ชำระเงิน:</b> {Helper.getDateThai(this.state.data.billing.createdAt, { is_show_time: true })}<br />
                                            <b>ชำระเงินโดย:</b> {
                                                this.paymentByText()
                                            }
                                            <br />
                                            {
                                                this.state.data.billing.paymentBy === 'creditcard' ?
                                                    <>

                                                        <b>เลขบัตร:</b> {
                                                            this.state.data.transection.card ?
                                                                "xxxx xxxx xxxx " + this.state.data.transection.card.last_digits
                                                                : null
                                                        }<br />
                                                    </>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                    {/* <div className="row">
                                        <div className="col-12 table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 250 }}>วันที่</th>
                                                        <th style={{ width: 150 }}>รอบสัญญา</th>
                                                        <th>รายละเอียด</th>
                                                        <th style={{ width: 150 }}>จำนวนเงิน</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{Helper.getDateThai(this.state.data.billing.createdAt, { is_show_time: true })}</td>
                                                        <td>
                                                            {
                                                                expiredMonth && expiredYear ?
                                                                    <>{expiredMonth}/{expiredYear}</>
                                                                    : '-'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                this.state.data.billing.packagePaymentType === 'renew' ?
                                                                    <>ต่ออายุ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.billing.packagePaymentType === 'upgrade' ?
                                                                    <>อัพเกรด</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.billing.packagePaymentType === 'downgrade' ?
                                                                    <>ลดกรด</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.transection.package.name ?
                                                                    <>แพ็คเกจ : {this.state.data.transection.package.name}</>
                                                                    : null
                                                            }
                                                        </td>
                                                        <td>{Helper.numberFormat(total_price)}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div> */}


                                    <div className="row">

                                        <div className="col-8 table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 300 }}>วันที่</th>
                                                        <th style={{ width: 250 }}>รอบสัญญา</th>
                                                        <th>รายละเอียด</th>
                                                        {/* <th style={{ width: 150 }}>จำนวนเงิน</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{Helper.getDateThai(this.state.data.billing.createdAt, { is_show_time: true })}</td>
                                                        <td>
                                                            {
                                                                startMonth && startYear ?
                                                                    <>{startMonth}/{startYear}</>
                                                                    : '-'
                                                            }
                                                            {' - '}

                                                            {
                                                                expiredMonth !== undefined && expiredYear ?
                                                                    <>{expiredMonth}/{expiredYear}</>
                                                                    : '-'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                this.state.data.billing.packagePaymentType === 'renew' ?
                                                                    <>ต่ออายุ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.billing.packagePaymentType === 'upgrade' ?
                                                                    <>อัพเกรด</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.billing.packagePaymentType === 'downgrade' ?
                                                                    <>ลดกรด</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.transection.package.name ?
                                                                    <>แพ็คเกจ : {this.state.data.transection.package.name}</>
                                                                    : null
                                                            }
                                                        </td>
                                                        {/* <td>{Helper.numberFormat(total_price)}</td> */}
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-4">
                                            <p className="lead">การชำระเงิน</p>

                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        {
                                                            (this.state.data.billing.packagePaymentType === 'upgrade'
                                                                || this.state.data.billing.packagePaymentType === 'downgrade')
                                                                && this.state.data.billing
                                                                && this.state.data.billing.summary
                                                                //&& this.state.data.billing.summary.package_price_paid 
                                                                ?
                                                                <>
                                                                    <tr>
                                                                        <th>ราคาแพ็คเกจเดิม : {this.state.data.billing.summary.package_before_change ? this.state.data.billing.summary.package_before_change : null}</th>
                                                                        <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.package_price ? Helper.numberFormat(this.state.data.billing.summary.package_price.toFixed(2), { decimal: 2 }) : 0}</td>
                                                                    </tr>
                                                                    {
                                                                        this.state.data.billing.packagePaymentType === 'upgrade' ?
                                                                            <tr>
                                                                                <th>จำนวนการใช้งาน {this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.now_day ? Helper.numberFormat(this.state.data.billing.summary.now_day.toFixed(2)) : 0} วัน</th>
                                                                                <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.package_used_price ? Helper.numberFormat(this.state.data.billing.summary.package_used_price.toFixed(2), { decimal: 2 }) : 0}</td>
                                                                            </tr>
                                                                            : null
                                                                    }

                                                                    <tr>
                                                                        <th>ราคาแพ็คเกจที่อัพเกรด : {this.state.data.billing.summary.package_after_change ? this.state.data.billing.summary.package_after_change : null}</th>
                                                                        <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.selected_package_price ? Helper.numberFormat(this.state.data.billing.summary.selected_package_price.toFixed(2), { decimal: 2 }) : 0}</td>
                                                                    </tr>

                                                                    {
                                                                        this.state.data.billing.packagePaymentType === 'upgrade' ?
                                                                            <>
                                                                                <tr>
                                                                                    <th>จำนวนการใช้งานที่เหลือ {this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.package_used_day_left ? Helper.numberFormat(this.state.data.billing.summary.package_used_day_left.toFixed(2)) : 0} วัน</th>
                                                                                    <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.update_price ? Helper.numberFormat(this.state.data.billing.summary.update_price.toFixed(2), { decimal: 2 }) : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>ที่ต้องจ่าย</th>
                                                                                    <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.update_price ? Helper.numberFormat(this.state.data.billing.summary.update_price.toFixed(2), { decimal: 2 }) : 0}</td>
                                                                                </tr>
                                                                                {
                                                                                    this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.package_price_paid ?
                                                                                        <tr>
                                                                                            <th>จ่ายไปแล้ว</th>
                                                                                            <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.package_price_paid ? Helper.numberFormat(this.state.data.billing.summary.package_price_paid.toFixed(2), { decimal: 2 }) : null}</td>
                                                                                        </tr>
                                                                                        : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            this.state.data.billing.packagePaymentType === 'renew' ?
                                                                <>
                                                                    <tr>
                                                                        <th>ราคาแพ็คเกจ:</th>
                                                                        <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.selected_package_price ? Helper.numberFormat(this.state.data.billing.summary.selected_package_price.toFixed(2), { decimal: 2 }) : null}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>จำนวนการใช้งานที่เหลือ {this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.package_used_day_left ? Helper.numberFormat(this.state.data.billing.summary.package_used_day_left.toFixed(2)) : null} วัน</th>
                                                                        <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.summary && this.state.data.billing.summary.update_price ? Helper.numberFormat(this.state.data.billing.summary.update_price.toFixed(2), { decimal: 2 }) : null}</td>
                                                                    </tr>

                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            this.state.data.billing.paymentBy === 'adminService' ?
                                                                <tr>
                                                                    <th>ชำระผ่านผู้ดูแลระบบ:</th>
                                                                    <td align={"right"}>{this.state.data && this.state.data.billing && this.state.data.billing.paymentByDetails && this.state.data.billing.paymentByDetails.receiveAmount ? Helper.numberFormat(parseFloat(this.state.data.billing.paymentByDetails.receiveAmount).toFixed(2), { decimal: 2 }) : 0}</td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <th>ราคารวม:</th>
                                                                    <td align={"right"}>{Helper.numberFormat(total_price, { decimal: 2 })}</td>
                                                                </tr>
                                                        }

                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-print">
                                        <div className="col-12">
                                            {
                                                this.state.data
                                                    && this.state.data.transection
                                                    && this.state.data.transection.store
                                                    && this.state.data.transection.store.uid ?
                                                    <Link to={"/store"}
                                                        className="btn btn-default btn-lg">
                                                        กลับ
                                                    </Link>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </section>
            </>
        );
    }
}