import React from 'react';
import { Helper } from './../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from './../Components/Pagination';
// import { db, auth } from './../firebase';
import PermissionCheckBox from './../Components/PermissionCheckBox';
const axios = require('axios').default;

export default class AdminSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count_all: 0,
            is_loading: true,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: props.userUid || false,
        }
    }


    componentDidMount() {
        this.fetchData();
        console.log(this.props.package)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let qs = {};
        qs.storeUid = this.props.uid;
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if (this.state.name) {
            qs.name = this.state.name;
        }
        let ref = await axios.get(window.api_host + '/user-permission?' + window.$.param(qs));
        // console.log(ref.data);
        let data = [];
        let count_all = 0;
        if (ref.data.response.users) {
            data = ref.data.response.users;
            count_all = ref.data.response.count_all;
        }
        this.setState({
            is_loading: false,
            data: data,
            count_all: count_all
        })
    }



    async delete(data) {
        // auth.deleteUser(data.id);
        try {
            let _delete = await axios.post(window.api_host + '/delete-user-store', {
                uid: data.uid,
            });
            console.log(_delete.data);
            if (_delete.data.success) {
                this.fetchData();
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
            }
            if (_delete.data.error) {
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลไม่สำเร็จ',
                    body: 'ไม่สามารถลบได้',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
            }
        } catch (error) {
            console.log(error);
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลไม่สำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        }

    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ผู้ดูแลและสิทธิการเข้าถึง</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ผู้ดูแลและสิทธิการเข้าถึง</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายชื่อผู้ดูแล</h3>
                                <div style={{ marginLeft: 15 }}>
                                    <button className={"btn btn-primary btn-xs"}
                                        onClick={() => {
                                            Helper.reactToDom(
                                                window.$('body'),
                                                <AdminSettingForm
                                                    mode={"create"}
                                                    uid={this.props.uid}
                                                    onCreated={(data) => {
                                                        this.fetchData();
                                                    }}
                                                />
                                            );
                                        }}
                                    >
                                        เพิ่มผู้ดูแล
                                    </button>
                                </div>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.admin_count} คน</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>ชื่อ</th>
                                            <th>สิทธิการเข้าถึง</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.displayName}
                                                                {
                                                                    _data.store.isOwner ?
                                                                        <div className="badge badge-success" style={{ marginLeft: 5 }}>เจ้าของร้าน</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td className={"btn-space"}>
                                                                <div className="flex row admin-icon-permission">
                                                                    <div
                                                                        style={_data.store.permission
                                                                            && _data.store.permission.storeProfile
                                                                            && _data.store.permission.storeProfile.delete
                                                                            && _data.store.permission.storeProfile.view
                                                                            && _data.store.permission.storeProfile.add
                                                                            && _data.store.permission.storeProfile.edit
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-store"></i> <div className="text">ข้อมูลร้าน</div>
                                                                    </div>
                                                                    {
                                                                        this.props.package
                                                                            && this.props.package.option
                                                                            && this.props.package.option.features
                                                                            && this.props.package.option.features.isPreviewMemberProfileStore ?

                                                                            <div
                                                                                style={_data.store.permission
                                                                                    && _data.store.permission.member
                                                                                    && _data.store.permission.member.delete
                                                                                    && _data.store.permission.member.view
                                                                                    && _data.store.permission.member.add
                                                                                    && _data.store.permission.member.edit
                                                                                    ? { opacity: 1 } : { opacity: 0.5 }}
                                                                            >
                                                                                <i className="fas fa-users"></i> <div className="text">ข้อมูลสมาชิก</div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.props.package
                                                                            && this.props.package.option
                                                                            && this.props.package.option.features
                                                                            && this.props.package.option.features.haveMenu ?
                                                                            <div
                                                                                style={_data.store.permission
                                                                                    && _data.store.permission.menu
                                                                                    && _data.store.permission.menu.delete
                                                                                    && _data.store.permission.menu.view
                                                                                    && _data.store.permission.menu.add
                                                                                    && _data.store.permission.menu.edit
                                                                                    ? { opacity: 1 } : { opacity: 0.5 }}
                                                                            >
                                                                                <i className="fas fa-book"></i> <div className="text">จัดการเมนู</div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.props.package
                                                                            && this.props.package.option
                                                                            && this.props.package.option.features
                                                                            && ( this.props.package.option.features.haveArtistLive || this.props.package.option.features.haveLive )  ?
                                                                            <>
                                                                                <div
                                                                                    style={_data.store.permission
                                                                                        && _data.store.permission.artist
                                                                                        && _data.store.permission.artist.delete
                                                                                        && _data.store.permission.artist.view
                                                                                        && _data.store.permission.artist.add
                                                                                        && _data.store.permission.artist.edit
                                                                                        ? { opacity: 1 } : { opacity: 0.5 }}
                                                                                >
                                                                                    <i className="fas fa-music"></i> <div className="text">จัดการนักดนตรี</div>
                                                                                </div>
                                                                                <div
                                                                                    style={_data.store.permission
                                                                                        && _data.store.permission.live
                                                                                        && _data.store.permission.live.delete
                                                                                        && _data.store.permission.live.view
                                                                                        && _data.store.permission.live.add
                                                                                        && _data.store.permission.live.edit
                                                                                        ? { opacity: 1 } : { opacity: 0.5 }}
                                                                                >
                                                                                    <i className="fas fa-music"></i> <div className="text">จัดการ Live</div>
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                    }

                                                                    <div
                                                                        style={_data.store.permission
                                                                            && _data.store.permission.manageStore
                                                                            && _data.store.permission.manageStore.delete
                                                                            && _data.store.permission.manageStore.view
                                                                            && _data.store.permission.manageStore.add
                                                                            && _data.store.permission.manageStore.edit
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-tasks"></i> <div className="text">จัดการร้าน</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.store.permission
                                                                            && _data.store.permission.manageEventPromotion
                                                                            && _data.store.permission.manageEventPromotion.delete
                                                                            && _data.store.permission.manageEventPromotion.view
                                                                            && _data.store.permission.manageEventPromotion.add
                                                                            && _data.store.permission.manageEventPromotion.edit
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-percentage"></i> <div className="text">จัดการโปรโมชั่นและอีเว้นท์</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    {

                                                                        !_data.store.isOwner ||
                                                                            (this.props.isOwner && _data.store.isOwner) ?
                                                                            <>
                                                                                <button className="btn btn-default"
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(
                                                                                            window.$('body'),
                                                                                            <AdminSettingForm
                                                                                                mode={"edit"}
                                                                                                data={_data}
                                                                                                onUpdated={(data) => {
                                                                                                    this.fetchData();
                                                                                                    window.location = window.location.href
                                                                                                }}
                                                                                                isOwner={this.props.isOwner}
                                                                                                package={this.props.package}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                >แก้ไขข้อมูลผู้ดูแล</button>
                                                                                {
                                                                                    !_data.store.isOwner ?
                                                                                        <button type="button" className="btn btn-danger"
                                                                                            ref={(ref) => {
                                                                                                window.$(ref).off().on('click', () => {
                                                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                                                    window.$(ref).addClass('loading');
                                                                                                    Helper.confirmModal({
                                                                                                        title: "ยืนยันลบผู้ดูแล",
                                                                                                        description: "ต้องการลบผู้ดูแลนี้หรือไม่ ?",
                                                                                                        onConfirm: () => {
                                                                                                            window.$(ref).removeClass('loading');
                                                                                                            this.delete(_data);
                                                                                                        },
                                                                                                        onCancel: () => {
                                                                                                            window.$(ref).removeClass('loading');
                                                                                                        }
                                                                                                    });
                                                                                                })
                                                                                            }
                                                                                            }
                                                                                        >ลบ</button>
                                                                                        : null
                                                                                }

                                                                            </>
                                                                            : null
                                                                    }

                                                                    {/* <Link to={'/user/' + _data.id} type="button" className="btn btn-default">ดูข้อมูล</Link> */}
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.count_all}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}


class AdminSettingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
        }
    }

    async create(data) {
        try {
            let ref = await axios.post(window.api_host + '/create-user-store', { data: data });
            if (ref.data.code === 200 && ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มผู้ใช้งานสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.$modal.modal('hide');
                this.props.onCreated()
            }
            if (ref.data.code === 200 && ref.data.error) {
                window.$(document).Toasts('create', {
                    title: ref.data.message,
                    body: ref.data.description,
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                });
                this.$form.find('button[type="submit"]').removeClass('loading')
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.$form.find('button[type="submit"]').removeClass('loading')
        }
    }

    async update(data) {
        try {
            let ref = await axios.post(window.api_host + '/update-user-store', { data: data });
            // console.log(this.props.adminUid);
            if (ref.data.code === 200 && ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'แก้ไขข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                if (ref.data.user.uid === this.props.adminUid) {
                    let __date = ref.data.user;
                    __date.login_time = new Date().getTime();
                    delete __date.password;
                    Helper.setCookie('user', JSON.stringify(__date), 1);
                    window.location.href = window.location;
                }
                this.$modal.modal('hide');
                this.props.onUpdated()
            }
            if (ref.data.code === 200 && ref.data.error) {
                let message = {
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                }
                message.title = ref.data.message;
                if (ref.data.description) {
                    message.description = ref.data.description
                }
                window.$(document).Toasts('create', { ...message });
                this.$form.find('button[type="submit"]').removeClass('loading')
            }
        } catch (error) {
            console.log(error)
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.$form.find('button[type="submit"]').removeClass('loading')
        }
    }


    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // data.is_super_admin = JSON.parse(data.is_super_admin);
                            // console.log(data);
                            // return;
                            if (this.props.mode === 'edit') {
                                this.update(data);
                            }
                            if (this.props.mode === 'create') {
                                this.create(data);
                            }

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลผู้ดูแล</h4>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.data.id ?
                                        <input type="hidden" name="id" defaultValue={this.state.data.id} />
                                        : null
                                }
                                <input type="hidden" name="is_super_admin" defaultValue={this.state.data.is_super_admin || false} />
                                {
                                    this.props.mode === 'create' ?
                                        <>
                                            <div className={"form-group"}>
                                                <label>อีเมล์</label>
                                                <input className={"form-control"} type="email" name="email" defaultValue={this.state.data.email || ''} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>รหัสผ่าน <span className="small-text">* อย่างน้อย 6 ตัวอักษร</span></label>
                                                <input type={"password"} className={"form-control"} required={true} name="password" defaultValue={this.state.data.password || ''} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>เบอร์โทรศัพท์</label>
                                                <input type={"text"} className={"form-control"} required={true} name="phoneNumber" defaultValue={this.state.data.phoneNumber || ''} />
                                                <input type={'hidden'} name="storeUid" value={this.props.uid} />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <input type={'hidden'} name="storeUid" value={this.state.data.store.uid} />
                                            <input type={'hidden'} name="uid" value={this.state.data.uid} />
                                        </>
                                }
                                <div className={"form-group"}>
                                    <label>ชื่อ</label>
                                    <input className={"form-control"} name="displayName" defaultValue={this.state.data.displayName || ''} disabled={this.props.mode === 'edit'} />
                                </div>

                                <PermissionCheckBox
                                    title="สิทธิ์การจัดการข้อมูลร้านค้า"
                                    permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.storeProfile ? this.state.data.store.permission.storeProfile : false}
                                    inputKeywordName={"store"}
                                />
                                {
                                    this.props.package
                                        && this.props.package.option
                                        && this.props.package.option.features
                                        && this.props.package.option.features.isPreviewMemberProfileStore ?
                                        <PermissionCheckBox
                                            title="สิทธิ์การจัดการข้อมูลสมาชิก"
                                            permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.member ? this.state.data.store.permission.member : false}
                                            inputKeywordName={"member"}
                                        />
                                        : null
                                }
                                {
                                    this.props.package
                                        && this.props.package.option
                                        && this.props.package.option.features
                                        && this.props.package.option.features.haveMenu ?
                                        <PermissionCheckBox
                                            title="สิทธิ์การจัดการเมนู"
                                            permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.menu ? this.state.data.store.permission.menu : false}
                                            inputKeywordName={"menu"}
                                        />
                                        : null
                                }
                                {
                                    this.props.package
                                        && this.props.package.option
                                        && this.props.package.option.features
                                        && ( this.props.package.option.features.haveArtistLive || this.props.package.option.features.haveLive ) ?
                                        <>
                                            <PermissionCheckBox
                                                title="สิทธิ์การจัดการนักดนตรี"
                                                permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.artist ? this.state.data.store.permission.artist : false}
                                                inputKeywordName={"artist"}
                                            />
                                            <PermissionCheckBox
                                                title="สิทธิ์การจัดการ Live"
                                                permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.live ? this.state.data.store.permission.live : false}
                                                inputKeywordName={"live"}
                                            />
                                        </>
                                        : null
                                }
                                <PermissionCheckBox
                                    title="สิทธิ์การจัดการร้าน"
                                    permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.manageStore ? this.state.data.store.permission.manageStore : false}
                                    inputKeywordName={"managestore"}
                                />
                                <PermissionCheckBox
                                    title="สิทธิ์การจัดการโปรโมชั่นและอีเว้น"
                                    permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.manageEventPromotion ? this.state.data.store.permission.manageEventPromotion : false}
                                    inputKeywordName={"manageeventpromotion"}
                                />
                                {/* <PermissionCheckBox
                                    title="สิทธิ์การจัดการผู้ดูแลระบบ"
                                    permission={this.state.data.store && this.state.data.store.permission && this.state.data.store.permission.manageEventPromotion ? this.state.data.store.permission.manageEventPromotion : false}
                                    inputKeywordName={"havePermissionStore"}
                                /> */}

                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}