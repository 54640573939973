import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import PaymentCreditCardCheckingModal from './PaymentCreditCardCheckingModal';
const axios = require('axios').default;

export default class PaymentPackageDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            data: false,
            is_user_loading: true,
            is_package_loading: true,
            is_review_loading: true,
            is_promotion_loading: true,
            users: [],
            promotions: [],
            feeds: [],
            package_types: [],
            package: false,
            provider: false,
            selected_package: false,
            is_card_loading: false,
            selected_package_uid: props.packageUid,
            selected_package_option_uid: false,
        }
    }
    componentDidMount() {
        window.OmiseCard.configure({
            publicKey: window.omise_pkey,
        });
        this.fetch();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/store', {
            data: {
                uid: this.props.uid,
                package_type: true,
                only_main_package: true,
                show_package_option_list: true,
            }
        });
        let store = false;
        let _package = false;
        let provider = false;
        let package_types = false;
        let selected_package = false;
        let selected_card = false;
        let selected_package_option_uid = false;
        let selected_package_type = 'renew';
        if (res.data.success) {
            store = res.data.response.store;
            _package = res.data.response.store.package;
            _package.current_billing = res.data.response.store.current_billing;
            provider = res.data.response.provider;
            package_types = res.data.response.package_types;
            selected_card = res.data.response.store.owner.omise.default_card;
            selected_package = package_types.filter((e) => { return e.uid === this.props.packageUid })[0];
            if (_package.option) {
                selected_package_option_uid = selected_package.options_list.filter((e) => { return e.isMain })[0].uid;
            }
            if (parseFloat(selected_package.sequence) < parseFloat(_package.sequence)) {
                selected_package_type = 'downgrade'
            }
            if (parseFloat(selected_package.sequence) > parseFloat(_package.sequence)) {
                selected_package_type = 'upgrade'
            }
        }
        // console.log(_package);
        this.setState({
            selected_package_type: selected_package_type,
            data: store,
            package: _package,
            provider: provider,
            package_types: package_types,
            selected_package: selected_package,
            selected_card: selected_card,
            is_loading: false,
            is_card_loading: false,
            selected_package_option_uid: selected_package_option_uid,
        }, () => {
            if (!this.state.selected_package) {
                window.$(document).Toasts('create', {
                    title: 'ไม่พบ Package ที่เลือกกรุณาลองใหม่อีกครั้ง',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                });
                navigate('/store')
            }
        })
    }

    deleteCard(card, options) {
        Helper.confirmModal({
            title: "ยืนยันยกเลิกบัตรเครดิต",
            description: "ต้องการยกเลิกบัตรเครดิต " + card.name + " (" + card.last_digits + ") หรือไม่ ?",
            onConfirm: async () => {
                let _data = {}
                _data.customer_id = this.state.data.owner.omise.id;
                _data.card_id = card.id;
                _data.provider_uid = this.state.data.ownerUid;
                _data.omise_uid = this.state.data.owner.omise.uid;
                _data.card_uid = card.uid;
                // console.log(_data);
                // return;
                let res = await axios.post(window.api_host + '/delete-creditcard', { ..._data });
                if (res.data.success) {
                    window.$(document).Toasts('create', {
                        title: 'ลบเครดิตการ์ดสำเร็จ',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    });
                    this.setState({
                        is_card_loading: true,
                    }, () => {
                        this.fetch();
                    })
                }
                if (res.data.error) {
                    window.$(document).Toasts('create', {
                        title: res.data.message,
                        icon: 'fas fa-times',
                        autohide: true,
                        class: "bg-danger",
                        delay: 3000,
                    });
                }
            },
            onCancel: () => {
                options.onCancel();
                // window.$(ref).removeClass('loading');
            }
        })

    }
    async paymentFree(summary) {
        let _data = {}
        _data.customerID = this.state.data.owner.omise.id;
        // _data.cardID = this.state.selected_card;
        // _data.cardUid = this.state.data.owner.cards.filter((e) => { return e.id === this.state.selected_card })[0].uid;
        _data.providerUid = this.state.data.ownerUid;
        _data.omiseUid = this.state.data.owner.omise.uid;
        _data.packageUid = this.state.selected_package_uid;
        _data.package = this.state.selected_package;
        _data.packageOptionUid = this.state.selected_package_option_uid;
        _data.storeUid = this.props.uid;
        _data.packagePaymentType = this.state.selected_package_type;
        _data.summary = summary;
        _data.store = this.state.data;
        delete _data.store.workingTime;
        delete _data.store.package;
        delete _data.store.billing;
        // let price = 0;
        // if (summary.total_price) {
        //     price = (parseFloat(summary.total_price).toFixed(2) + '').replace('.', '');
        // }
        // _data.amount = price;
        // _data.currency = 'thb';
        // _data.return_uri = window.origin + '/transection/';
        console.log(_data);
        let res = await axios.post(window.api_host + '/omise-payment', { ..._data });
        if (res.data.success) {
            console.log(res.data);
            navigate('/transection/' + res.data.transectionUid)
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            this.paymentBtn.removeClass("loading");
        }
    }
    async payment(summary) {
        if (this.state.data.owner.cards.length === 0 || !this.state.selected_card) {
            window.$(document).Toasts('create', {
                title: "กรุณาเลือกบัตรเครดิต",
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            return false;
        }
        let _data = {}
        _data.customerID = this.state.data.owner.omise.id;
        _data.cardID = this.state.selected_card;
        _data.cardUid = this.state.data.owner.cards.filter((e) => { return e.id === this.state.selected_card })[0].uid;
        _data.providerUid = this.state.data.ownerUid;
        _data.omiseUid = this.state.data.owner.omise.uid;
        _data.packageUid = this.state.selected_package_uid;
        _data.package = this.state.selected_package;
        _data.packageOptionUid = this.state.selected_package_option_uid;
        _data.storeUid = this.props.uid;
        _data.packagePaymentType = this.state.selected_package_type;
        _data.summary = summary;
        _data.store = this.state.data;
        delete _data.store.workingTime;
        delete _data.store.package;
        delete _data.store.billing;
        let price = 0;
        if (summary.total_price) {
            price = (parseFloat(summary.total_price).toFixed(2) + '').replace('.', '');
        }
        let now = new Date();
        if (parseFloat(now.getDate()) > 15 && this.state.selected_package_type === 'upgrade') {
            _data.packagePaymentType = 'renew_upgrade';
            _data.note = "ชำระเงินเกินวันที่ 15 ของเดือน แพ็กเกจจะปรับเป็นรอบบิลใหม่ (รอบบิลของเดือนหน้า)";
        }
        _data.amount = price;
        _data.currency = 'thb';
        _data.return_uri = window.origin + '/transection/';
        // let tests = await axios.post(window.api_host + '/transection-checking-test');
        // console.log(tests);
        // console.log(_data);return;
        let res = await axios.post(window.api_host + '/omise-payment', { ..._data });
        if (res.data.success) {
            window.location = res.data.redirect_uri;
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            this.paymentBtn.removeClass("loading");
        }
    }

    summary() {
        // console.log(this.state.package);
        let date = new Date();
        let month_days = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
        let month_used_day = date.getDate();
        let month_day_left = (month_days - month_used_day) + 1;
        // console.log(month_days, used_day, day_left);
        let total_price = 0;
        let pacakge_price_left = 0;
        let selected_package_price = 0;
        let selected_package_price_perday = 0;
        let package_start_day = 0;
        let package_used_day = 0;
        let package_used_day_left = parseFloat(month_days) - parseFloat(date.getDate()) + 1;
        let update_price = 0;
        let merge_price = 0;
        let package_price = 0;
        let package_price_perday = 0;
        let package_used_price = 0;
        let package_used_price_left = 0;
        let charge_price = 0;
        let current_billing_price = 0;
        let package_price_paid = 0;
        let package_before_change = false;
        let package_after_change = false;
        if (this.state.package) {
            let default_current_price = '000';
            package_price = parseFloat(this.state.package.option.summary.total);
            if (this.state.package.current_billing) {
                let package_createdAt = new Date(this.state.package.current_billing.createdAt);
                package_start_day = parseFloat(package_createdAt.getDate())
                package_used_day = parseFloat(date.getDate()) - package_start_day;
                if (package_createdAt.getFullYear() === date.getFullYear() && package_createdAt.getMonth() === date.getMonth()) {
                    package_used_day_left = ((parseFloat(month_days) - package_start_day) - package_used_day) + 1;
                }
                default_current_price = parseFloat(this.state.package.current_billing.summary.total).toString();
                package_price = this.state.package.current_billing.summary.selected_package_price;
            }
            current_billing_price = default_current_price.slice(0, -2) + '.' + default_current_price.slice(-2);
            package_price_paid = parseFloat(current_billing_price);
            package_price_perday = (parseFloat(this.state.package.option.summary.total) / month_days);
            if (this.state.package.current_billing) {
                package_used_price = parseFloat(package_price_perday) * parseFloat(package_used_day);
                package_used_price_left = parseFloat(package_price_perday) * parseFloat(package_used_day_left);
                pacakge_price_left = this.state.package.current_billing ? (package_price_paid - package_used_price_left) : 0;
            }
            package_before_change = this.state.package.name;
        }
        if (this.state.selected_package) {
            selected_package_price = parseFloat(this.state.selected_package.options.summary.total);
            selected_package_price_perday = (parseFloat(selected_package_price) / month_days);
            update_price = parseFloat(selected_package_price_perday) * parseFloat(package_used_day_left);
            package_after_change = this.state.selected_package.name;
        }
        if (this.state.package.current_billing) {
            charge_price = update_price - pacakge_price_left;
            merge_price = pacakge_price_left + update_price;
            total_price = merge_price - package_price_paid;
        }
        // console.log(this.props.isExpired, this.state.selected_package_type, parseFloat(date.getDate()), parseFloat(date.getDate()) > 15)
        if (
            (this.state.selected_package_type === "renew"
                && parseFloat(date.getDate()) <= 15)
            || this.props.isExpired
        ) {
            // alert(1)
            total_price = update_price;
        }
        if (
            (this.state.selected_package_type === "renew" && !this.props.isExpired)
            || (this.state.selected_package_type === "downgrade" && !this.props.isExpired)
            || (
                parseFloat(date.getDate()) > 15
                && this.state.package.current_billing
            )
        ) {
            // alert(2)
            total_price = selected_package_price
        }
        if (total_price < 0) {
            total_price = 0;
        }
        let summary = {
            package_before_change: package_before_change,
            package_after_change: package_after_change,
            total_price: total_price,
            month_days: month_days,
            month_used_day: month_used_day,
            month_day_left: month_day_left,
            selected_package_price: selected_package_price,
            selected_package_price_perday: selected_package_price_perday,
            update_price: update_price,
            merge_price: merge_price,
            package_price: package_price,
            package_price_perday: package_price_perday,
            package_used_price: package_used_price,
            package_price_paid: package_price_paid,
            package_start_day: package_start_day,
            package_used_day: package_used_day,
            pacakge_price_left: pacakge_price_left,
            package_used_day_left: package_used_day_left,
            charge_price: charge_price,
            current_billing_price: current_billing_price,
        }
        // console.log(summary)
        return summary;
    }

    render() {
        // console.log(this.state);
        // console.log(this.state.package);
        // console.log(this.props.current_billing);
        let summary = this.summary();
        let date = new Date();
        let preview_month = date.getMonth() + 1;
        let preview_year = date.getFullYear();
        if (this.state.selected_package_type !== 'upgrade'
            && (((this.state.selected_package_type === 'downgrade' && this.props.current_billing)
                || this.state.selected_package_type === 'renew_upgrade')
                || (this.state.selected_package_type === 'renew' && this.props.current_billing)
            )) {
            preview_month += 1;
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ชำระเงิน </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/store">ร้านค้า</Link></li>
                                    <li className="breadcrumb-item active">ชำระเงิน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    {
                        this.state.is_loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader" ></div></div>
                            : null
                    }
                    {
                        !this.state.is_loading ?
                            <div className="container-fluid">
                                <div className={"row"}>
                                    <div className="col-12 col-md-4">
                                        <div className={"card"} style={{ position: 'relative' }}>
                                            <div className="card-header">
                                                <h6 className="card-tiel" style={{ marginBottom: 0 }}>แพ็คเกจ {this.state.selected_package && this.state.selected_package.name ? ': ' + this.state.selected_package.name : null}</h6>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.is_loading ?
                                                        <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                        : null
                                                }
                                                {
                                                    !this.state.is_loading
                                                        && !this.state.selected_package ?
                                                        <div>ไม่มีข้อมูล</div>
                                                        : null
                                                }
                                                {
                                                    this.state.selected_package ?
                                                        <>
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.haveTimeline
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ระบบโพส</label>
                                                            </div>
                                                            {/* <div className="flex row" style={{ paddingLeft: '1.5rem' }}>
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.canGuestPostTimeline
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ผู้ใช้งาน</label>
                                                            </div> */}
                                                            <div className="flex row" style={{ paddingLeft: '1.5rem' }}>
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.canStorePostTimeline
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ร้าน</label>
                                                            </div>
                                                            <div className="flex row" style={{ paddingLeft: '1.5rem' }}>
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.canStoreRemoveTimeline
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ลบโพส</label>
                                                            </div>
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.canKickMember
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >บังคับผู้ใช้งานออกจากระบบ (เตะผู้ใช้งาน)</label>
                                                            </div>
                                                            {/* <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.haveBanUser
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >แบนผู้ใช้งาน</label>
                                                            </div> */}
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.canManageEvent
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ระบบข่าวสารของร้าน</label>
                                                            </div>
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.havePermissionStore
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ระบบจัดการผู้ดูแลระบบและสิทธิ์การเข้าถึง</label>
                                                            </div>
                                                            {/* <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.haveSkitz
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ระบบสกิด</label>
                                                            </div> */}
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.isPreviewMemberProfileStore
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ดูรูปโปรไฟล์ผู้ใช้งาน</label>
                                                            </div>
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.haveArtistLive
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ระบบนักดนตรี/Live</label>
                                                            </div>
                                                            <div className="flex row">
                                                                {
                                                                    this.state.selected_package.options
                                                                        && this.state.selected_package.options.features
                                                                        && this.state.selected_package.options.features.haveMenu
                                                                        ?
                                                                        <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                        :
                                                                        <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                                }
                                                                <label >ระบบจัดการเมนู</label>
                                                            </div>
                                                            <div>
                                                                <label >จำนวนผู้เข้าสู่ระบบสูงสุด</label> {' '} {this.state.selected_package.options
                                                                    && this.state.selected_package.options.features
                                                                    && this.state.selected_package.options.features.onlineCapacity ? this.state.selected_package.options.features.onlineCapacity : 0} คน
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        {/* <div className={"card"} style={{ position: 'relative' }}>
                                            <div className="card-header">
                                                <h6 className="card-title" style={{ marginBottom: 0 }}>รายการบัตรเครดิต</h6>
                                                <div className="card-tools">
                                                    <button className="btn btn-default btn-sm"
                                                        onClick={() => {
                                                            Helper.reactToDom(
                                                                window.$('body'),
                                                                <PaymentCreditCardCheckingModal
                                                                    provider_uid={this.state.data.owner.uid}
                                                                    onSuccess={() => {
                                                                        this.setState({
                                                                            is_card_loading: true,
                                                                        }, () => {
                                                                            this.fetch();
                                                                        })
                                                                    }}
                                                                />
                                                            )
                                                            // let amount = 0;
                                                            // if (this.state.selected_package
                                                            //     && this.state.selected_package.options
                                                            //     && this.state.selected_package.options.summary
                                                            //     && this.state.selected_package.options.summary.total
                                                            // ) {
                                                            //     amount = this.state.selected_package.options.summary.total;
                                                            // }
                                                            // window.OmiseCard.open({
                                                            //     amount: amount,
                                                            //     currency: "THB",
                                                            //     defaultPaymentMethod: "credit_card",
                                                            //     onCreateTokenSuccess: (nonce) => {
                                                            //         console.log(nonce);
                                                            //         if (nonce.startsWith("tokn_")) {
                                                            //             this.form.omiseToken.value = nonce;
                                                            //         } else {
                                                            //             this.form.omiseSource.value = nonce;
                                                            //         };
                                                            //         // this.form.submit();
                                                            //     },
                                                            //     submitLabel: 'เพิ่มบัตรเครดิต',
                                                            // });
                                                        }}
                                                    >เพิ่มบัตรเครดิต</button>
                                                </div>
                                            </div>
                                            {
                                                this.state.is_loading
                                                    || this.state.is_card_loading ?
                                                    <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                    : null
                                            }
                                            {
                                                this.state.data
                                                    && this.state.data.owner ?
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            this.state.data
                                                                && this.state.data.owner
                                                                && this.state.data.owner.cards ?
                                                                this.state.data.owner.cards.map((card, card_i) => {
                                                                    let _class = "list-group-item";
                                                                    if (this.state.selected_card && card.id === this.state.selected_card) {
                                                                        _class += " list-group-item-success";
                                                                    }
                                                                    return (
                                                                        <li className={_class}
                                                                            key={card_i}
                                                                        >
                                                                            <div className="flex row" >
                                                                                <div className="flex row" style={{ alignItems: 'center' }}>
                                                                                    <div className={"icon-creditcard"}>
                                                                                        {
                                                                                            this.state.selected_card && card.id === this.state.selected_card ?
                                                                                                <i className="far fa-check-square"></i>
                                                                                                :
                                                                                                <i className="far fa-square"
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            selected_card: card.id,
                                                                                                        })
                                                                                                    }}
                                                                                                ></i>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>
                                                                                            {card.name}
                                                                                            <span style={{ marginLeft: 10 }}
                                                                                                className="badge bg-info text-dark"
                                                                                            >
                                                                                                {
                                                                                                    card.brand === "Visa" ?
                                                                                                        <i className="fab fa-cc-visa"></i>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    card.brand === "MasterCard" ?
                                                                                                        <i className="fab fa-cc-mastercard"></i>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    card.brand === 'JCB' ?
                                                                                                        <i className="fab fa-cc-jcb"></i>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    card.brand === 'Amex' ?
                                                                                                        <i className="fab fa-cc-amex"></i>
                                                                                                        : null
                                                                                                }
                                                                                                {' ' + card.brand}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div>**** **** **** {card.last_digits}</div>
                                                                                        <div>{card.expiration_month}/{card.expiration_year}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    <div className="btn-group">
                                                                                        <button type="button" className="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                                                                                            <i className="fas fa-cog"></i>
                                                                                        </button>
                                                                                        <div className="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" >
                                                                                            <button className="dropdown-item"
                                                                                                ref={(ref) => {
                                                                                                    window.$(ref).off('click').on('click', () => {
                                                                                                        if (window.$(ref).hasClass('loading')) { return; }
                                                                                                        window.$(ref).addClass('loading');
                                                                                                        this.deleteCard(card, {
                                                                                                            onCancel: () => {
                                                                                                                window.$(ref).removeClass('loading');
                                                                                                            }
                                                                                                        });
                                                                                                    })
                                                                                                }}
                                                                                            >ยกเลิกบัตรเครดิต</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : null
                                                        }
                                                        {
                                                            !this.state.data
                                                                || !this.state.data.owner
                                                                || !this.state.data.owner.cards
                                                                || (this.state.data.owner.cards && this.state.data.owner.cards.length === 0) ?
                                                                <li className="list-group-item ">ไม่มีข้อมูลบัตรเครดิต</li>
                                                                : null
                                                        }
                                                    </ul>
                                                    : null
                                            }
                                        </div> */}

                                        <div className="card" style={{ position: 'relative' }}>
                                            <div className="card-header">
                                                <div className="card-title">
                                                    {this.state.selected_package_type === 'upgrade' ? "อัพเกรด" : null}
                                                    {this.state.selected_package_type === 'renew' ? "ต่ออายุ" : null}
                                                    {this.state.selected_package_type === 'downgrade' ? "ลดเกรด" : null}
                                                    แพ็คเกจ
                                                </div>
                                                <div className="card-tools">
                                                    รอบบิล
                                                    {
                                                        ' ' + preview_month + '/' + preview_year
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.is_loading ?
                                                    <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                    : null
                                            }
                                            {
                                                this.state.selected_package
                                                    && !this.state.is_loading
                                                    && this.state.selected_package.options_list.length > 0 ?
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            this.state.selected_package.options_list.map((option, option_i) => {
                                                                let _class = "list-group-item";
                                                                if (this.state.selected_package_option_uid
                                                                    && option.uid === this.state.selected_package_option_uid) {
                                                                    _class += " list-group-item-success";
                                                                }
                                                                return (
                                                                    <li className={_class}
                                                                        key={option_i}
                                                                    >
                                                                        <div className="flex row" style={{ alignItems: 'center' }}>
                                                                            <div className={"icon-creditcard"}>
                                                                                {
                                                                                    this.state.selected_package_option_uid
                                                                                        && option.uid === this.state.selected_package_option_uid ?
                                                                                        <i className="far fa-check-square"></i>
                                                                                        :
                                                                                        <i className="far fa-square"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    selected_package_option_uid: option.uid,
                                                                                                })
                                                                                            }}
                                                                                        ></i>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    this.state.selected_package_type === 'upgrade'
                                                                                        && this.state.package
                                                                                        && this.state.package.name ?
                                                                                        <>
                                                                                            {this.state.package.name}
                                                                                            {' '}
                                                                                            <i className={"fas fa-arrow-right"} style={{ fontSize: 12 }} />
                                                                                            {' '}
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.selected_package_type === 'downgrade'
                                                                                        && this.state.package && this.state.package.name ?
                                                                                        <>
                                                                                            {this.state.package.name}
                                                                                            {' '}
                                                                                            <i className={"fas fa-arrow-right"} style={{ fontSize: 12 }} />
                                                                                            {' '}
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {option.name}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    : null
                                            }
                                        </div>
                                        <div className={"card"} style={{ position: 'relative' }}>
                                            <div className="card-header">
                                                <div className={"card-title"}>สรุปรายการชำระเงิน</div>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.is_loading
                                                        || this.state.is_card_loading ?
                                                        <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                        : null
                                                }
                                                {
                                                    this.state.package ?
                                                        <div>
                                                            <label>
                                                                แพ็คเกจ : </label> {' '}
                                                            {this.state.selected_package && this.state.selected_package.name ? this.state.selected_package.name : null}
                                                            {' '}
                                                            (
                                                            {this.state.selected_package_type === 'upgrade' ? "อัพเกรด" : null}
                                                            {this.state.selected_package_type === 'renew' ? "ต่ออายุ" : null}
                                                            {this.state.selected_package_type === 'downgrade' ? "ลดเกรด" : null}
                                                            )
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.data
                                                        && this.state.data.owner ?
                                                        <>
                                                            <div>
                                                                <label>ชื่อ : </label> {this.state.data.owner.displayName}
                                                            </div>
                                                            <div>
                                                                <label>อีเมล์ : </label> {this.state.data.owner.email}
                                                            </div>
                                                            <div>
                                                                <label>เบอร์โทรศัพท์ : </label> {this.state.data.owner.phoneNumber}
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                            {
                                                !this.state.is_loading
                                                    && this.state.selected_package ?
                                                    <div className="card-footer">
                                                        {
                                                            this.state.selected_package_type === 'renew'
                                                                || this.props.isExpired ?
                                                                <>
                                                                    <div className="flex row">
                                                                        <h6 style={{ marginBottom: 0 }}>ราคาแพ็คเกจที่เลือก ({this.state.selected_package.name})</h6>
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <h6 style={{ marginBottom: 0, color: 'green' }}>{
                                                                                    Helper.numberFormat(summary.selected_package_price.toFixed(2), { decimal: 2 })}
                                                                                </h6>
                                                                                <h6 style={{ marginLeft: 5 }}>฿</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.props.isExpired ?
                                                                            <div className="flex row">
                                                                                <p style={{ marginBottom: 0 }}>จำนวนการใช้งานที่เหลือ {summary.package_used_day_left} วัน</p >
                                                                                <div className={"right-floated "}>
                                                                                    <div className="flex row">
                                                                                        <p style={{ marginBottom: 0, color: 'green' }}>{
                                                                                            Helper.numberFormat(summary.update_price.toFixed(2), { decimal: 2 })}
                                                                                        </p >
                                                                                        <p style={{ marginLeft: 5, marginBottom: 0 }}>฿</p >
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    <hr className="divider" style={{ marginTop: 10 }} />
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            this.state.selected_package_type === 'upgrade'
                                                                && this.props.current_billing
                                                                && parseFloat(date.getDate()) <= 15 ?
                                                                <>
                                                                    <div className="flex row">
                                                                        <h6 style={{ marginBottom: 0 }}>ราคาแพ็คเกจเดิม ({this.state.package.name})</h6>
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <h6 style={{ marginBottom: 0, color: 'red' }}>{
                                                                                    Helper.numberFormat(summary.package_price.toFixed(2), { decimal: 2 })}
                                                                                </h6>
                                                                                <h6 style={{ marginLeft: 5 }}>฿</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex row">
                                                                        <p style={{ marginBottom: 0 }}>จำนวนการใช้งาน {summary.package_used_day} วัน</p >
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <p style={{ marginBottom: 0, color: 'red' }}>{
                                                                                    Helper.numberFormat(summary.package_used_price.toFixed(2), { decimal: 2 })}
                                                                                </p >
                                                                                <p style={{ marginLeft: 5, marginBottom: 0 }}>฿</p >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="divider" style={{ marginTop: 10 }} />
                                                                    <div className="flex row">
                                                                        <h6 style={{ marginBottom: 0 }}>ราคาแพ็คเกจที่เลือก ({this.state.selected_package.name})</h6>
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <h6 style={{ marginBottom: 0, color: 'green' }}>{
                                                                                    Helper.numberFormat(summary.selected_package_price.toFixed(2), { decimal: 2 })}
                                                                                </h6>
                                                                                <h6 style={{ marginLeft: 5 }}>฿</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex row">
                                                                        <p style={{ marginBottom: 0 }}>จำนวนการใช้งานที่เหลือ {summary.package_used_day_left || 0} วัน</p >
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <p style={{ marginBottom: 0, color: 'green' }}>{
                                                                                    Helper.numberFormat(summary.update_price.toFixed(2), { decimal: 2 })}
                                                                                </p >
                                                                                <p style={{ marginLeft: 5, marginBottom: 0 }}>฿</p >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="divider" style={{ marginTop: 10 }} />
                                                                    <div className="flex row">
                                                                        <h6 style={{ marginBottom: 0 }}>ที่ต้องจ่าย</h6>
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <h6 style={{ marginBottom: 0, color: 'green' }}>{
                                                                                    Helper.numberFormat(summary.merge_price.toFixed(2), { decimal: 2 })}
                                                                                </h6>
                                                                                <h6 style={{ marginLeft: 5 }}>฿</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex row">
                                                                        <h6 style={{ marginBottom: 0 }}>จ่ายไปแล้ว</h6>
                                                                        <div className={"right-floated "}>
                                                                            <div className="flex row">
                                                                                <h6 style={{ marginBottom: 0, color: 'green' }}>{
                                                                                    Helper.numberFormat(summary.package_price_paid.toFixed(2), { decimal: 2 })}
                                                                                </h6>
                                                                                <h6 style={{ marginLeft: 5 }}>฿</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="divider" style={{ marginTop: 10 }} />
                                                                </>
                                                                : null
                                                        }
                                                        <div className="flex row">
                                                            <h4 style={{ marginBottom: 0 }}>รวมราคา</h4>
                                                            <div className={"right-floated "}>
                                                                <div className="flex row">
                                                                    <h4 style={{ marginBottom: 0, color: 'green' }}>{
                                                                        Helper.numberFormat(summary.total_price.toFixed(2), { decimal: 2 })}
                                                                    </h4>
                                                                    <h4 style={{ marginLeft: 5 }}>฿</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="alert alert-info" role="alert">
                                            <h5 className="alert-heading">เงื่อนไขการชำระเงิน</h5>
                                            <ul>
                                                <li>ตัดรอบบิลทุกวันที่ 15 </li>
                                                <li>กรณีแพ็คเกจเดิมยังไม่หมดอายุ หากทำการต่ออายุแพ็คเกจเดิมอีกครั้ง จะเพิ่มเวลาการใช้งานให้ในรอบบิลถัดไป</li>
                                                <li>กรณีปรับลดแพ็คเกจ จะเริ่มใช้แพ็คเกจใหม่ในรอบบิลถัดไป</li>
                                                <li>กรณีอัพเกรตแพ็คเกจ หากยังไม่เกินวันที่ 15 จะสามารถใช้แพ็คเกจใหม่ได้ในรอบบิลเดิม โดยจะต้องทำการชำระเงินส่วนต่างระหว่างแพ็คเกจปัจจุบันกับแพ็คเกจใหม่</li>
                                                <li>กรณีอัพเกรดแพ็คเกจ หากเกินวันที่ 15 ไปแล้ว  จะเริ่มใช้แพ็คเกจใหม่ในรอบบิลถัดไป</li>
                                            </ul>
                                        </div>
                                        <div className="alert alert-warning" role="alert">
                                            <h5 className="alert-heading">ระบบยังไม่เปิดให้บริการ</h5>
                                            หากต้องการ{this.state.selected_package_type === 'upgrade' ? "อัพเกรด" : null}
                                                    {this.state.selected_package_type === 'renew' ? "ต่ออายุ" : null}
                                                    {this.state.selected_package_type === 'downgrade' ? "ลดเกรด" : null}แพ็คเกจ  กรุณาติดต่อผู้ดูแลระบบ
                                        </div>
                                        <div className="flex row">
                                            {
                                                !this.props.next_billing
                                                    || this.state.selected_package_type === 'upgrade' ?
                                                    <button
                                                        ref={(ref) => { this.paymentBtn = window.$(ref); }}
                                                        className="btn btn-primary btn-lg"
                                                        style={{ width: '100%' }}
                                                        id={"custom-payment-btn"}
                                                        disabled={true}
                                                        onClick={() => {
                                                            return;
                                                            if (this.paymentBtn.hasClass('loading')) { return; }
                                                            this.paymentBtn.addClass("loading");
                                                            Helper.confirmModal({
                                                                title: 'ยืนยันการชำระเงิน',
                                                                description: 'กรุณายืนยันการชำระเงินหากท่านตรวจสอบข้อมูลและวิธีการชำระเงินเรียบร้อยแล้ว',
                                                                onConfirm: async () => {
                                                                    if (parseFloat(this.state.selected_package.options.summary.total) > 0) {
                                                                        this.payment(summary);
                                                                        return;
                                                                    }
                                                                    if (parseFloat(this.state.selected_package.options.summary.total) === 0) {
                                                                        this.paymentFree(summary);
                                                                        return;
                                                                    }
                                                                },
                                                                onCancel: () => {
                                                                    this.paymentBtn.removeClass('loading');
                                                                }
                                                            })
                                                        }}
                                                    >ชำระเงิน</button>
                                                    :
                                                    <button className="btn btn-primary btn-lg disabled">ไม่สามารถชำระเงินได้</button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : null
                    }

                </section>
            </>
        );
    }
}