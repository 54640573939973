import { Link, navigate } from '@reach/router';
import React from 'react';
import { Helper } from '../../Helper';
import { skitz_api_host, ApplicationKey } from './../../services/HttpClient'
import { getRequestReport } from '../../services/ReportServices';
import { getStore, getStores } from '../../services/StoreServices';

const _serverUrl = skitz_api_host;
const baseUrlStore = `${_serverUrl}/store`;
export default class MusicRequest extends React.Component {
    constructor() {
        super();
        let monthList = [];
        for (let index = 1; index <= 12; index++) {
            monthList.push(index)
        }
        let date = new Date();
        let now_year = date.getFullYear();
        let yearList = [];
        for (let index = 2020; index <= now_year; index++) {
            yearList.push(index)
        }
        this.state = {
            is_loading: true,
            data: [],
            storeList: [],
            page: 1,
            rows: 20,
            month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : false,
            year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : false,
            storeId: Helper.getParameterByName('storeId') ? Helper.getParameterByName('storeId') : false,
            monthList: monthList,
            yearList: yearList,
            rowsList: [
                20, 50, 100, 150, 200, 1000
            ]
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== '/rank' && this.props.id !== prevProps.id) {
            this.setState({
                is_rank_info_loading: true,
                rank_info: false,
            }, () => {
                this.fetchRankInfo();
            })
        }
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : false,
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : false,
                storeId: Helper.getParameterByName('storeId') ? Helper.getParameterByName('storeId') : false,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.pathname === '/rank' && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                is_loading: true,
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : false,
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : false,
                storeId: Helper.getParameterByName('storeId') ? Helper.getParameterByName('storeId') : false,
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let _getStore = await getStore(this.props.uid)
        let items = [];
        if (!_getStore.error) {
            let filter = {
                storeId: _getStore.id
            };
            if (this.state.month) {
                filter.month = this.state.month;
            }
            if (this.state.year) {
                filter.year = this.state.year;
            }
            if (this.state.rows) {
                filter.rows = this.state.rows;
            }
            let res = await getRequestReport(filter);
            if (!res.error) {
                items = res;
            }
        }
        this.setState({
            data: items,
            is_loading: false,
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายการขอเพลง</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายการขอเพลง
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-12 col-md-3'>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการขอเพลง</h3>
                                    </div>
                                    <div className="card-body">
                                        <form
                                            ref={(ref) => {
                                                this.$filterForm = window.$(ref);
                                                this.$submit = this.$filterForm.find('button[type="submit"]');
                                                this.$selectYear = this.$filterForm.find('select[name="year"]');
                                                this.$selectMonth = this.$filterForm.find('select[name="month"]');
                                            }}

                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                let data = Helper.getFormInputObject(this.$filterForm);
                                                // let url = Helper.getQueryStringLinkFromObject({
                                                //     ...data
                                                // })
                                                // navigate(url)
                                                if (data.month && !data.year) {
                                                    let now_year = new Date().getFullYear();
                                                    data.year = now_year;
                                                    this.$selectYear.val(now_year)
                                                }
                                                this.setState({
                                                    ...data,
                                                    is_loading: true
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >
                                            <div className='row'>
                                               
                                                <div className='col-12 col-md-6'>

                                                    <div className='form-group'>
                                                        <label>การแสดงผล</label>
                                                        <select className='form-control' name="rows"
                                                            defaultValue={this.state.rows}
                                                            onChange={() => {
                                                                this.$submit.trigger('click')
                                                            }}
                                                        >
                                                            {
                                                                this.state.rowsList.map((row) => {
                                                                    return <option value={row}>{row}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex row merge-selectbox form-group'>
                                                <select className={"form-control"}
                                                    name={"month"}
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => {
                                                        this.$submit.trigger('click')
                                                    }}
                                                    defaultValue={this.state.month}
                                                >
                                                    <option value={""}>{"เดือนทั้งหมด"}</option>
                                                    {
                                                        window.months_th.map((month, month_i) => (
                                                            <option value={month_i + 1} key={month_i}>{month}</option>
                                                        ))
                                                    }
                                                </select>
                                                <select className={"form-control"}
                                                    style={{ flex: 1 }}
                                                    name={"year"}
                                                    onChange={(e) => {
                                                        if (!e.target.value) {
                                                            this.$selectMonth.val('');
                                                        }
                                                        this.$submit.trigger('click')
                                                    }}
                                                    defaultValue={this.state.year}
                                                >
                                                    <option value={""}>{"ปีทั้งหมด"}</option>
                                                    {
                                                        this.state.yearList.map((years) => (
                                                            <option value={years} key={years}>{years}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <button
                                                className="btn btn-default"
                                                onClick={() => {
                                                    this.setState({
                                                        month: false,
                                                        year: false,
                                                        storeId: false
                                                    }, () => {
                                                        if (this.$filterForm) {
                                                            this.$filterForm.find('select[name="month"]').val("");
                                                            this.$filterForm.find('select[name="year"]').val("");
                                                        }
                                                        if (this.$storefilter) {
                                                            this.$storefilter.val(null).trigger('change');
                                                        }
                                                        this.fetch();
                                                    })
                                                }}
                                            >Clear</button>
                                            {/* <Link to={"/staticts"} className="btn btn-default">Clear</Link> */}
                                            <button type="submit" style={{ display: 'none' }}></button>
                                        </form>
                                    </div>

                                </div>
                            </div>

                            <div className='col-12 col-md-9'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">เพลง</th>
                                                    <th style={{ width: 200 }}>ศิลปิน</th>
                                                    <th style={{ textAlign: 'right', width: 200 }}>จำนวนการขอ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={4}>ไม่มีข้อมูล</td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <div className="ui active inline loader small" ></div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.data.length > 0 ?
                                                        <>
                                                            {
                                                                this.state.data.map((_data, _data_i) => {
                                                                    return (
                                                                        <tr key={_data_i}>
                                                                            <td>{_data.musicName}</td>
                                                                            <td>{_data.artistName}</td>
                                                                            <td align='right'><div>{_data.totalRequest ? Helper.numberFormat(_data.totalRequest) : 0}</div></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        : null
                                                }

                                            </tbody>
                                        </table>

                                        {
                                            this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                <div className="absolute_loading">
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </>
        )
    }
}