import { navigate } from "@reach/router";
import React, { Component, createContext } from "react";
import { Helper } from "../Helper";
import { authProvider } from "./../firebase";
import Error from "../Components/Error";
const axios = require('axios').default;
export const UserContext = createContext({ user: null, user_data: null });

class UserProvider extends Component {
  constructor() {
    super();
    this.state = {
      user: Helper.getCookie('user') && Helper.getCookie('user') !== '' ? JSON.parse(Helper.getCookie('user')) : false,
      user_data: false,
      loading: true,
      is_error: false
    }
  }

  componentDidMount = async () => {
    // if((!this.state.user || !authProvider.currentUser) && window.location.pathname === "/"){
    //   this.setState({
    //     loading: false,
    //   })
    // }
    // if ((!this.state.user || !authProvider.currentUser) && window.location.pathname !== "/") {
    //   this.signOut();
    // } 
    // if (this.state.user && authProvider.currentUser
    //   || (this.state.user || authProvider.currentUser) && window.location.pathname === "/"
    //   ) {
    //   let qs = {
    //     uid : this.state.user.uid,
    //   }
    //   let res = await axios.get(window.api_host + '/provider?' + window.$.param(qs));
    //   if(res.data.success) {
    //     let user = this.state.user;
    //     user.storeUid = res.data.response.user.stores[0].uid;
    //     this.setState({
    //       user: user,
    //       user_data: res.data,
    //       loading: false,
    //     })
    //   }
    //   if(res.data.error) {
    //     this.signOut();
    //   }
    // }
    if (!this.state.user && window.location.pathname === "/") {
      // alert(1)
      this.setState({
        loading: false,
      })
    }
    if (!this.state.user && window.location.pathname !== "/") {
      // alert(2)
      this.signOut();
    }
    if ((this.state.user && window.location.pathname === "/")
      || (this.state.user && window.location.pathname !== "/")
    ) {
      // alert(3)
      this.fetchProvider();
    }
  };

  async fetchProvider() {
    try {
      let qs = {
        uid: this.state.user.uid,
      }
      let res = await axios.get(window.api_host + '/provider?' + window.$.param(qs));
      if (res.data.success) {
        let user = this.state.user;
        user.storeUid = res.data.response.user.stores[0].uid;
        user.store = res.data.response.user.stores[0];
        if (Helper.getCookie('user') && Helper.getCookie('user') !== '' && res.data.response.user.updatedAt) {
          let nowUpdatedAt = new Date(this.state.user.updatedAt);
          let responseUpdatedAt = new Date(res.data.response.user.updatedAt);
          if (nowUpdatedAt.getTime() !== responseUpdatedAt.getTime()) {
            // alert("not match")
            this.signOut();
            return;
          }
        }
        this.setState({
          user: user,
          user_data: res.data.response,
          loading: false,
        })
      }
      if (res.data.error) {
        this.signOut();
      }
    } catch (error) {
      this.setState({
        is_error: true
      }, () => {
        navigate('/error')
      })
    }

  }

  async signOut() {
    Helper.setCookie('user', '');
    await authProvider.signOut();
    window.location = '/';
  }

  render() {
    if(this.state.is_error) {
      return <Error signOut={()=> {this.signOut()}}/>
    }
    if (this.state.loading) {
      return (
        <>
          <div style={{ padding: 15, display: 'flex', alignItems: 'center', backgroundColor: '#f4f6f9' }}>
            <div className="ui active inline loader small" style={{ marginRight: 5 }}></div> <span style={{ fontSize: 18 }}>
              Loading ...
            </span>
          </div>
        </>
      )
    }
    return (
      <UserContext.Provider value={{ user: this.state.user, user_data: this.state.user_data, fetchProvider: () => this.fetchProvider() }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
