import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../../Helper';
import { getPaymentByTransactionNumber } from './../../services/PaymentServices'
const axios = require('axios').default;

export default class PaymentResult extends React.Component {
    constructor() {
        super();
        this.state = {
            countFecth: 0,
            is_loading: true,
            payment_status: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let transaction_number = Helper.getParameterByName('merchantReference') ? Helper.getParameterByName('merchantReference') : false;
        if (!transaction_number) {
            window.location.href = '/';
        }
        let res = await getPaymentByTransactionNumber(transaction_number);
        // console.log(res)

        // [Description("ยังไม่ทำรายการ")]
        // NoAction = 1,
        // [Description("รอชำระเงิน")]
        // Pending = 2,
        // [Description("ชำระบางส่วน")]
        // Success = 3,
        // [Description("ยกเลิก")]
        // Canceled = 4,
        // [Description("ขอคืนเงิน")]
        // Refunding = 5,
        // [Description("คืนเงินแล้ว")]
        // Refunded = 6,
        let paymentStatusId = res.paymentStatusId
        if (paymentStatusId == 2 && this.state.countFecth < 5) {
            this.setState({
                countFecth: this.state.countFecth + 1,
            }, () => {
                this.fetch();
            })
        } else {
            if (paymentStatusId == 2 && this.state.countFecth >= 5) {
                paymentStatusId = 10;
            }
            if (!paymentStatusId) {
                paymentStatusId = 10;
            }
            this.setState({
                is_loading: false,
                payment_status: paymentStatusId
            })
        }
    }

    render() {
        // console.log('register render')
        return (
            <div >
                <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='card mt-5'>
                        <div className='card-body'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={window.location.origin + "/assets/img/logo.png"} />
                            </div>
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted huge mt-4" ></div>
                                        <h6 className="mt-3 sub-heading text-center mb-0">กำลังโหลด</h6>
                                        <div className="text-center">
                                            <img src={window.location.origin + "/assets/img/stopwatch.png"} style={{ maxWidth: '100%', width: 200 }} />
                                        </div>
                                        <h3 className="mb-3 heading text-center ">ระบบกำลังทำการตรวจสอบการชำระเงิน</h3>
                                        <p className="text">กรุณารอสักครู่</p>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="title d-inline-block mb-2 mt-3" >
                                            <h6 className="mb-0 sub-heading text-center">บริการเติมเหรียญ SKITz Coin</h6>
                                            {
                                                this.state.payment_status === 3 ?
                                                    this.renderSuccess()
                                                    : null
                                            }
                                            {
                                                this.state.payment_status === 4 ?
                                                    this.renderError()
                                                    : null
                                            }
                                            {
                                                this.state.payment_status === 10 ?
                                                    this.renderErrorCallAdmin()
                                                    : null
                                            }
                                        </div>

                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderSuccess() {
        return (
            <>
                <div className="text-center">
                    <img src={window.location.origin + "/assets/img/mobile-banking.png"} style={{ maxWidth: '100%', width: 200, marginTop: 20, marginBottom: 30 }} />
                </div>
                <h3 className="mb-3 heading text-center text-success">การชำระเงินสำเร็จ</h3>
                <p className="text text-center">การชำระเงินสำเร็จแล้ว ระบบได้ทำการเพิ่มเหรียญไปยัง Account SKITz ของท่านเรียบร้อยแล้ว</p>
                <div className="d-flex justify-content-center mt-3">
                    <Link to="/payment/channels"
                        className="btn btn-lg rounded-pill login-btn inverted">
                        ซื้อ Pacakge เพิ่ม <i className='fas fa-arrow-right'></i>
                    </Link>
                </div>
            </>
        )
    }
    renderErrorCallAdmin() {
        return (
            <>
                <div className="text-center">

                    <img src={window.location.origin + "/assets/img/error.png"} style={{ maxWidth: '100%', width: 200, marginTop: 20, marginBottom: 30 }} />
                </div>
                <h3 className="mb-3 heading text-center ">ไม่สามารถตรวจสอบการชำระเงินได้</h3>
                <p className="text text-center">เกิดข้อผิดพลาดในการชำระเงิน กรุณาติดต่อผู้ดูแลระบบ</p>
                <div className="d-flex justify-content-center mt-3">
                    <Link to="/payment/channels"
                        className="btn btn-lg rounded-pill main-btn inverted">
                        กลับไปเลือก Pacakge อีกครั้ง <i className='fas fa-arrow-right'></i>
                    </Link>
                </div>
            </>
        )
    }
    renderError() {
        return (
            <>
                <div className="text-center">

                    <img src={window.location.origin + "/assets/img/credit-card.png"} style={{ maxWidth: '100%', width: 200, marginTop: 20, marginBottom: 30 }} />
                </div>
                <h3 className="mb-3 heading text-center text-danger">การชำระเงินไม่สำเร็จ</h3>
                <p className="text text-center">การชำระเงินไม่สำเร็จแล้ว เกิดข้อผิดพลาดในการชำระเงินระบบไม่สามารถทำการเพิ่มเหรียญได้</p>
                <div className="d-flex justify-content-center mt-3">
                    <Link to="/payment/channels"
                        className="btn btn-lg rounded-pill main-btn inverted">
                        กลับไปเลือก Pacakge อีกครั้ง <i className='fas fa-arrow-right'></i>
                    </Link>
                </div>
            </>
        )
    }
}

