import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/distribute`;
export const sendAridrop = async (data) => {
    let url = `${baseUrl}/airdrop`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}