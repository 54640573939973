import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/artist`;
export const getArtist = async (page = 1, rows = 10, q = false, statusId = false, storeFirebaseUid = false) => {
    let url = `${baseUrl}?page=${page}&rows=${rows}`;
    if (statusId) {
        url += `&statusId=${statusId}`;
    }
    if (q) {
        url += `&q=${q}`;
    }
    if (storeFirebaseUid) {
        url += `&storeFirebaseUid=${storeFirebaseUid}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLive = async (id) => {
    let url = `${baseUrl}/lives/${id}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLivesResultSet = async (filter) => {
    let url = `${baseUrl}/lives`;
    console.log(filter)
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLives = async (filter) => {
    let url = `${baseUrl}/lives/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLiveRequestList = async (id, filter) => {
    let url = `${baseUrl}/lives/${id}/request/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLiveRequest = async (id, filter) => {
    let url = `${baseUrl}/lives/${id}/request`;
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getArtistInfo = async (id) => {
    let url = `${baseUrl}/${id}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const requestMusic = async (id, data) => {
    const url = `${baseUrl}/lives/${id}/request`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const logInLobby = async (data) => {
    const url = `${baseUrl}/lives`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const logOutLobby = async (id) => {
    const url = `${baseUrl}/lives/${id}`;
    try {
        const res = await httpClient.put(url, { statusId: 3 })
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const startLive = async (id) => {
    const url = `${baseUrl}/lives/${id}`;
    try {
        const res = await httpClient.put(url, { statusId: 2 })
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const endLive = async (id) => {
    const url = `${baseUrl}/lives/${id}`;
    try {
        const res = await httpClient.put(url, { statusId: 3 })
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}