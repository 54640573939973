import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import FoodList from './FoodList';
import StoreFoodImages from './include/StoreFoodImages'
const axios = require('axios').default;

export default class MenuPage extends React.Component {

    constructor(props) {
        super(props);
        let selected = 'list';
        if (props.foodRenderType) {
            selected = props.foodRenderType;
        }
        console.log(props.foodRenderType)
        this.state = {
            is_loading: false,
            selected: selected,
            data: false,
        };
    }



    async updateStore(value) {
        let data = {
            foodRendertype: value
        }
        let ref = await axios.put(`${window.api_host}/update-store/${this.props.uid}/food-render-type`, { ...data });
        let selected = this.state.selected;
        if (ref.data.success) {
            selected = value;
        }
        this.setState({
            selected: selected
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการเมนู </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการเมนู</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row form-group'>
                            <div className='col-12 col-md-4 col-lg-3'>
                                <select
                                    className='form-control'
                                    defaultValue={this.state.selected}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (this.state.selected == value) { return; }
                                        this.updateStore(value)
                                    }}
                                >
                                    <option value="list">แสดงเป็นรายการ</option>
                                    <option value="image">แสดงเมนูภาพ</option>
                                </select>
                            </div>
                        </div>
                        {
                            this.state.selected == 'list' ?
                                <FoodList {...this.props} />
                                : null
                        }
                        {
                            this.state.selected == 'image' ?
                                <StoreFoodImages
                                    {...this.props}
                                />
                                : null
                        }

                    </div>
                </section>
            </>
        );
    }
}
