import { Link, navigate } from '@reach/router';
import React from 'react';
import { Helper } from './../../Helper';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { db } from '../../firebase';
const axios = require('axios').default;
export default class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            count_all_item: 0,
            count_item: 0,
            promotions: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            is_timeline_loading: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_timeline_loading: true,
                count_all_item: 0,
                count_item: 0,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 3,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                is_timeline_loading: true,
                is_loading: true,
                page: 1,
                limit: 10,
                count_all_item: 0,
                count_item: 0,
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let qs = {
            uid: this.props.uid,
            page: this.state.page,
            limit: this.state.limit,
            lang: 'th',
            isActive: true
        }
        let ref = await axios.get(window.api_host + '/store-promotion?' + window.$.param(qs));
        // console.log(ref.data)
        let promotions = [];
        let count_all_item = 0;
        let count_item = 0;
        if (ref.data.success) {
            promotions = ref.data.response.promotions;
            count_all_item = ref.data.response.count_all_item;
            count_item = ref.data.response.count_item;
        }
        this.setState({
            is_loading: false,
            is_timeline_loading: false,
            promotions: promotions,
            count_all_item: count_all_item,
            count_item: count_item,
        })
    }
    async update(update) {
        let res = await axios.post(window.api_host + '/update-store-promotion', { ...update });
        if (res.data.success) {
            this.fetch();
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.setState({
                is_timeline_loading: false
            })
        }
    }

    render() {
        if (this.state.is_loading) {
            return (<div style={{ padding: '1rem 0', textAlign: 'center' }}><div className="ui active inline loader" ></div></div>)
        }
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการโปรโมชั่นและอีเว้นท์</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={"/dashboard"}>หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการโปรโมชั่นและอีเว้นท์</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className="col-12 col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="flex row">
                                            <h6 className="card-title" style={{ fontWeight: 'bold' }}>โปรโมชั่นและอีเว้นท์ทั้งหมด : {Helper.numberFormat(this.state.count_all_item)} รายการ</h6>
                                            <div className={"right-floated"}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ position: 'relative' }}>
                                        <div className="flex row form-group">
                                            <Link to={'/manage-promotion-event/add'} className="btn btn-primary btn-sm">เพิ่มโปรโมชั่นและอีเวนท์</Link>
                                            <Link to={'/manage-promotion-event/history'} className="btn btn-default btn-sm right-floated">ประวัติโปรโมชั่นและอีเวนท์</Link>
                                        </div>
                                        <div >
                                            {
                                                this.props.is_loading
                                                    || this.state.is_timeline_loading ?
                                                    <div className={"absolute_loading"}>
                                                        <div className="ui active inline loader" ></div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="timeline">
                                                {
                                                    !this.state.promotions
                                                        || this.state.promotions.length === 0 ?
                                                        <div>
                                                            <i className="far fa-file bg-gray"></i>
                                                            <div className="timeline-item">
                                                                {/* <span className="time"><i className="far fa-calendar-alt"></i> {Helper.getDateThai(new Date(), { is_show_time: true })}</span> */}
                                                                <div className="timeline-body"><div className="description-pre-line">ไม่มีข้อมูล</div></div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.promotions.map((item, item_i) => {
                                                        return (
                                                            <div key={item_i}>
                                                                <i className="fas fa-percentage bg-green"></i>
                                                                <div className="timeline-item">
                                                                    <h3 className="timeline-header">
                                                                        <div className="flex row">
                                                                            <div>
                                                                                <div style={{ marginBottom: 5 }}>
                                                                                    {item.name + ' '}
                                                                                    {
                                                                                        item.type === 'event' ?
                                                                                            <span className="badge badge-primary" >Event</span>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.type === 'promotion' ?
                                                                                            <span className="badge badge-primary">Promotion</span>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                <div style={{ fontSize: 12 }}>
                                                                                    {Helper.getDateThai(item.createdAt, { is_show_time: true })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="right-floated btn-group btn-sm">
                                                                                <Link className={"btn btn-default"} to={'/manage-promotion-event/edit/' + item.uid}><i className="fas fa-edit"></i></Link>
                                                                                <button className="btn btn-default"
                                                                                    onClick={(e) => {
                                                                                        let ref = e.target;
                                                                                        if (window.$(ref).hasClass('loading')) { return false; }
                                                                                        window.$(ref).addClass('loading');
                                                                                        Helper.confirmModal({
                                                                                            title: 'ยกเลิกแพ็คเกจ',
                                                                                            description: 'ยืนยันการยกเลิกแพ็คเกจ',
                                                                                            onConfirm: async () => {
                                                                                                let ref = await axios.post(window.api_host + '/delete-store-promotion', { storeUid: this.props.uid, uid: item.uid });
                                                                                                // console.log(ref.data);
                                                                                                if (ref.data.success) {
                                                                                                    window.$(document).Toasts('create', {
                                                                                                        title: 'ลบข้อมูลสำเร็จ',
                                                                                                        icon: 'fas fa-check',
                                                                                                        autohide: true,
                                                                                                        class: "bg-success",
                                                                                                        delay: 3000,
                                                                                                    })
                                                                                                    this.fetch();
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                                if (ref.data.error) {
                                                                                                    let message = {
                                                                                                        icon: 'fas fa-times',
                                                                                                        autohide: true,
                                                                                                        class: "bg-danger",
                                                                                                        delay: 3000,
                                                                                                    }
                                                                                                    message.title = ref.data.message;
                                                                                                    if (ref.data.description) {
                                                                                                        message.description = ref.data.description
                                                                                                    }
                                                                                                    window.$(document).Toasts('create', { ...message });
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            },
                                                                                            onCancel: () => {
                                                                                                window.$(ref).removeClass('loading');
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash-alt" style={{ cursor: 'pointer' }} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </h3>
                                                                    <div className="timeline-body">
                                                                        <div className="row" style={{ marginBottom: 5 }}>
                                                                            {
                                                                                item.photoPath
                                                                                    && item.photoPath.length > 0 ?
                                                                                    <div className="col-12 col-md-3">
                                                                                        <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                                                                            {
                                                                                                item.photoPath.map((url, url_i) => {
                                                                                                    return (
                                                                                                        <div key={url_i} className="slide-image-frame">
                                                                                                            <img src={url || "/assets/images/no_img.png"} alt="PromotionEvent" />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Slider>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                            <div className="col-12 col-md-9">
                                                                                <div className="description-pre-line">{item.description}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex row">
                                                                            <div className="custom-control custom-switch main-input-checkbox">
                                                                                <input type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id={"appVisible" + item_i}
                                                                                    name={"appVisible"}
                                                                                    defaultValue={"on"}
                                                                                    defaultChecked={item.appVisible}
                                                                                    onChange={() => {
                                                                                        let update = {};
                                                                                        update.storeUid = this.props.uid;
                                                                                        update.uid = item.uid;
                                                                                        update.data = {};
                                                                                        update.data.appVisible = !item.appVisible;
                                                                                        this.setState({
                                                                                            is_timeline_loading: true
                                                                                        }, () => {
                                                                                            this.update(update);
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor={"appVisible" + item_i}>การแสดงผลบนแอพ</label>
                                                                            </div>
                                                                            {
                                                                                item.createUser ?
                                                                                    <div className="right-floated">
                                                                                        {item.createUser.displayName}
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div>
                                                    {
                                                        (this.state.page * this.state.limit) < this.state.count_all_item ?
                                                            <i className="fas fa-angle-down bg-gray"
                                                                onClick={() => {
                                                                    navigate('/manage-promotion-event?page=' + (parseFloat(this.state.page) + 1))
                                                                }}
                                                            />
                                                            :
                                                            <i className="fas fa-minus bg-gray" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
