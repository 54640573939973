import React from 'react';
import { Link, navigate } from '@reach/router';
import GoogleMap from './../Components/GoogleMap';
import { Helper, SortableContainer, SortableItem } from '../Helper';
import { QRCode } from 'react-qrcode-logo';
import { storage } from '../firebase';
import { getStore } from '../services/StoreServices';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Pagination from './../../Components/Pagination';
const axios = require('axios').default;
export default class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            is_user_loading: true,
            is_package_loading: true,
            is_review_loading: true,
            is_promotion_loading: true,
            is_gallery_loading: true,
            is_sequence_loading: false,
            users: [],
            promotions: [],
            feeds: [],
            reviews: [],
            store_gallery: [],
            package: false,
            provider: false,
        }
    }

    async componentDidMount() {
        await this.fetch();
        await this.fetchGallery();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/store', {
            data: {
                uid: this.props.uid,
                package_type: true,
                only_main_package: true,
            }
        });
        let store = false;
        let _package = false;
        let provider = false;
        let package_types = false;
        if (res.data.success) {
            store = res.data.response.store;
            _package = res.data.response.store.package;
            provider = res.data.response.provider;
            package_types = res.data.response.package_types;
            let _store = await getStore(this.props.uid);
            if (!_store.error) {
                store.storeData = _store;
            }
        }
        this.setState({
            data: store,
            package: _package,
            provider: provider,
            package_types: package_types,
            is_loading: false,
        })
    }

    async fetchGallery() {
        let qs = {};
        qs.storeUid = this.props.uid;
        let res = await axios.get(window.api_host + '/store-gallery?' + window.$.param(qs));
        let store_gallery = [];
        // console.log(res.data);
        if (res.data.success) {
            store_gallery = res.data.store_gallery;
        }
        this.setState({
            store_gallery: store_gallery,
            is_sequence_loading: false,
            is_gallery_loading: false,
        })
    }



    render() {
        // console.log(this.state.data.photoPath)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ร้านค้า </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ร้านค้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                {/* STORE INFO */}
                                <div className="card card-outline">
                                    <div className="card-body box-profile">
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && !this.state.data ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data ?
                                                <>
                                                    <div className="flex row" style={{ alignItems: 'center' }}>
                                                        <div>
                                                            <div>{Helper.getStoreStatus(this.state.data.status)}</div>
                                                            <div>
                                                                {
                                                                    this.state.data.isPrivate ?
                                                                        <span className="badge badge-info">ส่วนตัว</span>
                                                                        :
                                                                        <span className="badge badge-dark">สาธารณะ</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            !this.props.disableEdit ?
                                                                <Link to={"/store/edit"} className="link right-floated"> แก้ไข</Link>
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img
                                                                src={this.state.data.photoPath ? this.state.data.photoPath : "/assets/images/no_upload_image.jpg"}
                                                                alt="Store profile" />
                                                        </div>
                                                    </div>
                                                    <h3 className="profile-username text-center">{this.state.data.nameTH || '-'}</h3>

                                                    <p className="text-muted text-center">{this.state.data.nameEN || '-'}</p>
                                                    <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', borderRight: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {this.state.data && this.state.data.storeData && this.state.data.storeData.currentCoin ? Helper.numberFormat(this.state.data.storeData.currentCoin) : 0}
                                                            <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </span>
                                                        <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {this.state.data && this.state.data.storeData && this.state.data.storeData.currentPoint ? Helper.numberFormat(this.state.data.storeData.currentPoint) : 0}
                                                            <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </span>
                                                    </div>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        <li className="list-group-item">
                                                            <b>ผู้ติดตาม</b> <div className="float-right">{Helper.numberFormat(this.state.follow_amount)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>ราคา</b> <div className="float-right">฿ {Helper.numberFormat(this.state.data.minPrice)} - {Helper.numberFormat(this.state.data.maxPrice)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>จำนวนผู้เข้าร้าน</b> <div className="float-right">{Helper.numberFormat(this.state.data.guestsLimit)}</div>
                                                        </li>
                                                    </ul>

                                                    <div className="flex row">

                                                        <div className={"right-floated"}>{Helper.getDateThai(this.state.data.createdAt, { is_show_time: true })}</div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.data ?
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><i className="far fa-file-alt mr-1"></i> ภาพรวม</strong>
                                                <p className="text-muted">
                                                    {this.state.data.overview}
                                                </p>
                                                <hr />
                                                <strong><i className="fas fa-map-marker-alt mr-1"></i>ที่อยู่</strong>
                                                <p className="text-muted">
                                                    {
                                                        this.state.data.contact
                                                            && this.state.data.contact.address ?
                                                            this.state.data.contact.address + ' '
                                                            : null
                                                    }
                                                    {
                                                        this.state.data.contact.province
                                                            && this.state.data.contact.province.name ?
                                                            this.state.data.contact.province.name.th : null
                                                    }
                                                </p>
                                                <hr />
                                                <div className="flex row">
                                                    <div>
                                                        <strong><i className="far fa-envelope"></i> อีเมล์</strong>
                                                    </div>
                                                    <div className="text-muted right-floated">
                                                        {
                                                            this.state.data.contact
                                                                && this.state.data.contact.email ?
                                                                this.state.data.contact.email
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div>
                                                        <strong><i className="fas fa-mobile-alt"></i> เบอร์โทรศัพท์</strong>
                                                    </div>
                                                    <div className="text-muted right-floated">
                                                        {
                                                            this.state.data.contact
                                                                && this.state.data.contact.telephone ?
                                                                this.state.data.contact.telephone
                                                                : null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={"col-12 col-md-3"}>
                                {
                                    this.state.data.workingTime ?
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="card-title">วันเวลาทำการ</div>
                                            </div>
                                            <div className="card-body">
                                                <DateTimeView
                                                    day={this.state.data.workingTime.monday}
                                                    title={"วันจันทร์"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.tuesday}
                                                    title={"วันอังคาร"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.wednesday}
                                                    title={"วันพุธ"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.thursday}
                                                    title={"วันพฤหัส"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.friday}
                                                    title={"วันศุกร์"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.saturday}
                                                    title={"วันเสาร์"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.sunday}
                                                    title={"วันอาทิตย์"}
                                                />
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={"col-12 col-md-6"}>
                                {
                                    this.state.data ?
                                        <>
                                            {
                                                this.state.data
                                                    && this.state.data.coordinate
                                                    && this.state.data.coordinate._latitude
                                                    && this.state.data.coordinate._longitude ?
                                                    <GoogleMap
                                                        height={425}
                                                        mode={"view"}
                                                        lat={this.state.data.coordinate._latitude}
                                                        lng={this.state.data.coordinate._longitude}
                                                    />
                                                    : null
                                            }

                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class DateTimeView extends React.Component {
    render() {
        return (
            <div className="flex row">
                <div>
                    <strong><i className="far fa-calendar-alt"></i> {this.props.title}</strong>
                </div>
                <div className="text-muted right-floated">
                    {
                        this.props.day
                            && this.props.day.time
                            && this.props.day.time.open
                            && this.props.day.time.close ?
                            <><i className="far fa-clock"></i> {this.props.day.time.open + ' - ' + this.props.day.time.close}</>
                            : 'ปิดทำการ'
                    }
                </div>
            </div>
        );
    }
}