import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
// const NavLink = props => (
//   <Link
//     {...props}
//     getProps={({ isCurrent }) => {
//       return {
//         className: isCurrent ? "item active" : "item"
//       };
//     }}
//   />
// );
export default class SideBar extends React.Component {
  componentDidMount() {
    window.$(document).ready(() => {
      window.$('[data-widget="treeview"]').Treeview('init')
    });
  }

  componentDidUpdate() {
    // console.log(this.props.location)
  }

  currentLink = ({ isCurrent }) => {
    return {
      className: isCurrent ? "nav-link active" : "nav-link"
    };
  }

  render() {
    let data = this.props.data.user_data;
    let isExpired = true;
    if (data && data.store && data.store.isExpired !== undefined) {
      isExpired = data.store.isExpired;
    }
    let expiredDate = false;
    if (data && data.current_billing && data.current_billing.expiredAt) {
      let dateexpired = new Date(data.current_billing.expiredAt);
      expiredDate = new Date(dateexpired.getFullYear(), dateexpired.getMonth(), 1, dateexpired.getHours(), dateexpired.getMinutes(), dateexpired.getSeconds())
    }
    // console.log(data.current_billing)
    return (
      <aside className="main-sidebar elevation-4 sidebar-dark-lightblue">
        <Link to={"/dashboard"} className="brand-link navbar-lightblue" style={{ paddingTop: 3, paddingBottom: 3 }}>
          <img src={window.location.origin + "/assets/images/skitz-white.png"} alt="skitz Logo" style={{ maxWidth: '100%', maxHeight: 40 }} />
        </Link>
        <div className="sidebar ">
          {
            data.store
              ?
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img src={data.store.photoPath && data.store.photoPath.length > 0 ? data.store.photoPath[0] : window.location.origin + '/assets/images/no_upload_image.jpg'} className="img-circle elevation-2" alt="UserProfile" />
                </div>
                <div className="info">
                  <Link to="/store/" className="d-block">{data.store.name}</Link>
                </div>
              </div>
              : null
          }
          {
            data.package ?
              <div className="user-panel mt-3 pb-3 mb-3 d-flex package">
                <div className="flex row" style={{ width: '100%', alignItems: 'center' }}>
                  <div className="d-block" style={{ color: '#fff' }}>{data.package.name}</div>
                  {
                    expiredDate ?
                      <div className="right-floated" style={{ color: '#fff', fontSize: 10 }}>{Helper.getDateThai(new Date(expiredDate))}</div>
                      : null
                  }
                </div>
              </div>
              : null
          }
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/" || window.location.pathname === "" ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>หน้าหลัก</p>
                </Link>
              </li>
              {
                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.storeProfile
                  && this.props.data.user.store.permission.storeProfile.view ?
                  <>
                    <li className="nav-item">
                      <Link to={"/store"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/transection/') !== -1
                              || window.location.pathname.indexOf('/store/') !== -1
                              || window.location.pathname.indexOf('/payment-package/') !== -1
                              || window.location.pathname === "/store/"
                              || window.location.pathname === "/payment-package/" ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-store"></i>
                        <p>ข้อมูลร้าน</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/payment/channels"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/payment/channels/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-comments-dollar"></i>
                        <p>เติมเงินร้าน</p>
                      </Link>
                    </li>
                  </>
                  : null
              }
              {
                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.member
                  && this.props.data.user.store.permission.member.view ?
                  <li className="nav-item">
                    <Link to={"/member"} className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/member/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-users"></i>
                      <p>ข้อมูลสมาชิก</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.manageStore
                  && this.props.data.user.store.permission.manageStore.view ?
                  <>
                    <li className="nav-item">
                      <Link to={"/manage-store"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent || window.location.pathname.indexOf('/manage-store/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-tasks"></i>
                        <p>จัดการร้าน</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/exchange/transaction"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent || window.location.pathname.indexOf('/exchange-request/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-exchange-alt"></i>
                        <p>ประวัติแลกเปลี่ยนแต้ม</p>
                      </Link>
                    </li>
                  </>
                  : null
              }
              {
                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.manageEventPromotion
                  && this.props.data.user.store.permission.manageEventPromotion.view
                  && (this.props.data.user_data
                    && this.props.data.user_data.package
                    && this.props.data.user_data.package.option
                    && this.props.data.user_data.package.option.features
                    && this.props.data.user_data.package.option.features.canManageEvent
                  ) ?
                  <li className="nav-item">
                    <Link to={"/manage-promotion-event"} className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/manage-promotion-event/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-percentage"></i>
                      <p>จัดการโปรโมชั่นและอีเว้นท์</p>
                    </Link>
                  </li>
                  : null
              }
              {

                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.artist
                  && this.props.data.user.store.permission.artist.view
                  && (this.props.data.user_data
                    && this.props.data.user_data.package
                    && this.props.data.user_data.package.option
                    && this.props.data.user_data.package.option.features
                    && this.props.data.user_data.package.option.features.haveArtistLive) ?
                  <li className="nav-item">
                    <Link to={"/manage-artist"} className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/manage-artist/') !== -1 || window.location.pathname.indexOf('/artist/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-guitar"></i>
                      <p>จัดการนักดนตรี</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.live
                  && this.props.data.user.store.permission.live.view
                  && (this.props.data.user_data
                    && this.props.data.user_data.package
                    && this.props.data.user_data.package.option
                    && this.props.data.user_data.package.option.features
                    && this.props.data.user_data.package.option.features.haveArtistLive) ?
                  <>

                    <li className="nav-item">
                      <Link to={"/manage-live"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent || window.location.pathname.indexOf('/manage-live/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-music"></i>
                        <p>จัดการ Live</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/staticts"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent || window.location.pathname.indexOf('/staticts/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-chart-line"></i>
                        <p>รายการขอเพลง</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/exchange-request"} className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent || window.location.pathname.indexOf('/exchange-request/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-exchange-alt"></i>
                        <p>เบิกเงิน</p>
                      </Link>
                    </li>
                  </>
                  : null
              }
              {


                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.menu
                  && this.props.data.user.store.permission.menu.view
                  && (this.props.data.user_data
                    && this.props.data.user_data.package
                    && this.props.data.user_data.package.option
                    && this.props.data.user_data.package.option.features
                    && this.props.data.user_data.package.option.features.haveMenu) ?
                  <li className="nav-item">
                    <Link to={"/manage-menu"} className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/manage-menu') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-book"></i>
                      <p>จัดการเมนู</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.data.user
                  && this.props.data.user
                  && this.props.data.user.store
                  && !isExpired
                  && this.props.data.user.store.permission
                  && this.props.data.user.store.permission.managePermission
                  && this.props.data.user.store.permission.managePermission.view
                  && (this.props.data.user_data
                    && this.props.data.user_data.package
                    && this.props.data.user_data.package.option
                    && this.props.data.user_data.package.option.features
                    && this.props.data.user_data.package.option.features.havePermissionStore
                  ) ?
                  <li className="nav-item">
                    <Link to={"/permission"} className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/permission/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-user-cog"></i>
                      <p>ผู้ดูแลและสิทธิ์การเข้าถึง</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.data.user.displayName ?
                  <>
                    <li className="nav-item">
                      <Link to={"/admin-detail/" + this.props.data.user.uid} className="nav-link"
                        getProps={this.currentLink}
                      >
                        <i className="nav-icon fas fa-user-check"></i>
                        <p>
                          {this.props.data.user.displayName}
                        </p>
                      </Link>
                    </li>
                  </>
                  : null
              }
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}