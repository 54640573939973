import React from 'react';
import { Link } from '@reach/router';
import { db } from './../../firebase'
import { Helper } from '../../Helper';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { QRCode } from 'react-qrcode-logo';
import { getLives, startLive, logOutLobby, endLive, getLiveRequestList } from '../../services/ArtistServices';
const axios = require('axios').default;
// const storeUid = '0sRWNCri8Gbb3IYCknP3';
// const storeUid = 'peu7Ynk0wKWw6KpPW5cK';
export default class Live extends React.Component {
    constructor() {
        super();
        this.state = {
            brandList: [],
            playingBrand: false,
            musicList: [],
            isLoading: true,
            isMusicListLoading: true,
            isAuto: false,
            snapShotdata: false,
        }
    }

    componentDidMount() {
        this.fecth();
        this.fetchStore();
    }

    // componentWillUnmount() {
    //     this.fecth();
    // }


    async fetchStore() {
        let ref = await db.collection('stores').doc(this.props.uid).get();
        let isAuto = false;
        if (ref.data().isAuto) {
            isAuto = ref.data().isAuto
        }
        this.setState({
            isAuto: isAuto,
        })
    }
    async fecth() {
        let storeUid = this.props.uid;
        try {
            db.collection('onSnapArtistLive').doc(storeUid).onSnapshot(async (snapDoc) => {
                if (snapDoc.exists) {
                    let data = snapDoc.data();

                    let brandList = [...this.state.brandList];
                    let brand = await getLives({
                        storeFirebaseUid: storeUid,
                        statusIds: 1,
                    })
                    if (!brand.error) {
                        brandList = brand;
                    }
                    let live = await getLives({
                        storeFirebaseUid: storeUid,
                        statusIds: 2,
                    })
                    let playingBrand = false;
                    if (live.length > 0) {
                        playingBrand = live[0]
                    }
                    let musicList = [];
                    if (playingBrand) {
                        let music = await getLiveRequestList(playingBrand.id);
                        if (!music.error) {
                            musicList = music;
                        }
                    }
                    let message = await axios.get(`${window.api_host}/live-message/${storeUid}`);
                    let liveMessage = {};
                    if (message?.data?.code == 200) {
                        liveMessage = message.data.liveMessage;
                    }
                    console.log(message.data);
                    this.setState({
                        snapShotdata: data,
                        brandList: brandList,
                        playingBrand: playingBrand,
                        isLoading: false,
                        musicList: musicList,
                        liveMessage: liveMessage,
                        isMusicListLoading: false,
                    })
                } else {
                    this.setState({
                        snapShotdata: false,
                        brandList: [],
                        playingBrand: false,
                        isLoading: false,
                        musicList: [],
                        isMusicListLoading: false,
                    })
                }
            })
        } catch (error) {
            this.setState({
                snapShotdata: false,
                brandList: [],
                playingBrand: false,
                isLoading: false,
                musicList: [],
                isMusicListLoading: false,
            })
        }

    }


    async onLive(brand, node) {
        let getLive = await getLives({
            // artistId: 181,
            storeFirebaseUid: this.props.uid,
            statusIds: 2,
        })
        if (getLive.error) {
            Helper.messageTop({ message: 'มีวงดนตรีขึ้นแสดงอยู่' })
            node.removeClass('loading');
            return;
        }
        if (getLive && getLive.total > 0) {
            Helper.messageTop({ message: 'มีวงดนตรีขึ้นแสดงอยู่' })
            node.removeClass('loading');
            return;
        }

        let res = await startLive(brand.id);
        if (!res.error) {
            let cloudfnData = {
                storeUid: this.props.uid,
                artistId: brand.artist.id,
            }
            await axios.put(`${window.api_host}/artist-start-live`, cloudfnData);
        } else {
            Helper.messageTop({ message: 'มีวงดนตรีขึ้นแสดงอยู่' })
            node.removeClass('loading');
        }

    }
    async signOut(brand) {
        let res = await logOutLobby(brand.id);
        if (!res.error) {
            await axios.post(`${window.api_host}/artist-update-lobby`, { storeUid: this.props.uid, type: 'logout', artistId: brand.artist.id })
        }
    }

    async endShow(options) {
        let live_id = this.state.playingBrand.id;
        let res = await endLive(live_id);
        if (!res.error) {
            let cloudfnData = {
                storeUid: this.props.uid,
                artistId: this.state.playingBrand.artist.id,
            }
            await axios.post(`${window.api_host}/artist-update-lobby`, { storeUid: this.props.uid, type: 'logout' })
            if (options.onSuccess) {
                options.onSuccess();
            }
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            if (options.onError) {
                options.onError();
            }
        }
    }

    async updateLiveMessage() {
        let messageBtn = this.liveMessageBtn;
        if (messageBtn.hasClass('loading')) {
            return;
        }
        messageBtn.addClass('loading');
        let message = this.liveMessageInput.val();
        let messageStatus = 'warning';
        let sendMessage = await axios.put(`${window.api_host}/live-message/${this.props.uid}`, {
            liveMessage: message,
            liveMessageStatus: messageStatus,
        });
        if (sendMessage?.data?.code == 200) {
            window.$(document).Toasts('create', {
                title: 'ส่งข้อความสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.setState({
                liveMessage: {
                    message: message,
                    messageStatus: messageStatus
                }
            })
        } else {
            let msg = 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาติดต่อผู้ดูแลระบบ';
            if (sendMessage?.data?.message) {
                msg = sendMessage.data.message
            }
            window.$(document).Toasts('create', {
                title: msg,
                icon: 'fas fa-ban',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.liveMessageInput.val("")
        }
        messageBtn.removeClass('loading');
    }
    async removeLiveMessage() {
        let messageBtn = this.liveMessageBtn;
        if (messageBtn.hasClass('loading')) {
            return;
        }
        messageBtn.addClass('loading');
        let sendMessage = await axios.delete(`${window.api_host}/live-message/${this.props.uid}`);
        if (sendMessage?.data?.code == 200) {
            window.$(document).Toasts('create', {
                title: 'ยกเลิกข้อความแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.setState({
                liveMessage: {}
            }, () => {
                this.liveMessageInput.val("")
            })
        } else {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาติดต่อผู้ดูแลระบบ',
                icon: 'fas fa-ban',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        }
        messageBtn.removeClass('loading');
    }

    render() {
        let storeUid = this.props.uid;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการ Live </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการ Live</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <div className="mb-3">
                                    <a href={`https://${process.env.REACT_APP_FRONTEND_URL}/live?uid=${this.props.uid}`} className="btn btn-default" target={`_blank`}>
                                        <i className="fab fa-slideshare"></i> ดูไลฟ์ผ่านหน้าเว็บ
                                    </a>
                                </div>
                                {
                                    !this.state.isLoading ?
                                        <div className='mb-3'>
                                            <label>แสดงข้อความระหว่าง Live ?</label>
                                            <div className="input-group">
                                                <input
                                                    ref={(ref) => {
                                                        this.liveMessageInput = window.$(ref);
                                                    }}
                                                    name="liveMessage"
                                                    className='form-control'
                                                    placeholder=''
                                                    defaultValue={this.state.liveMessage?.message || ""}
                                                />
                                                {/* <span className="input-group-text">$</span> */}
                                                {
                                                    this.state.liveMessage?.message ?
                                                        <button
                                                            ref={(ref) => {
                                                                this.liveMessageBtn = window.$(ref);
                                                            }}
                                                            className="btn btn-default"
                                                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                            onClick={() => {
                                                                this.removeLiveMessage();
                                                            }}
                                                        >

                                                            ยกเลิก
                                                        </button>
                                                        :
                                                        <button
                                                            ref={(ref) => {
                                                                this.liveMessageBtn = window.$(ref);
                                                            }}
                                                            className="btn btn-default"
                                                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                            onClick={() => {
                                                                this.updateLiveMessage();
                                                            }}
                                                        >

                                                            บันทึก
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">ล็อบบี้</h3>
                                        {/* <div className="card-tools" style={{ margin: 0 }}>
                                            <label className="custom-control custom-switch" style={{ marginBottom: 0 }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={"isAuto"}
                                                    name={"isAuto"}
                                                    defaultValue={"true"}
                                                    checked={
                                                        this.state.isAuto
                                                    }
                                                    onChange={(ref) => {
                                                        let _ref = window.$(ref.target);
                                                        _ref.attr('disabled', true)
                                                        this.setAuto(_ref.prop("checked"), {
                                                            onSuccess: (isAuto) => {
                                                                this.setState({
                                                                    isAuto: isAuto
                                                                })
                                                                _ref.attr('disabled', false)
                                                            }
                                                        });
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor={"isAuto"}> Auto</label>
                                            </label>
                                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.isLoading ?
                                                <div className="absolute_loading">
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.isLoading
                                                && this.state.brandList.length > 0 ?
                                                this.state.brandList.map((brand, brand_i) => {
                                                    return (
                                                        <React.Fragment key={brand_i}>
                                                            {brand_i > 0 ? <hr /> : null}
                                                            <div className="flex row">
                                                                <strong><i className="fas fa-music mr-1"></i> {brand.artist.name}</strong>
                                                                <div className="right-floated btn-group">
                                                                    <button type="button"
                                                                        className="btn btn-default btn-sm"
                                                                        ref={(ref) => {
                                                                            let $btnRef = window.$(ref)
                                                                            $btnRef.off().on('click', async () => {
                                                                                if ($btnRef.hasClass('loading')) { return }
                                                                                $btnRef.addClass("loading")
                                                                                await this.onLive(brand, $btnRef);
                                                                            })
                                                                        }}
                                                                    >ขึ้นแสดง</button>
                                                                    <button type="button"
                                                                        className="btn btn-danger btn-sm"
                                                                        ref={(ref) => {
                                                                            let $btnRef = window.$(ref)
                                                                            $btnRef.off().on('click', () => {
                                                                                if ($btnRef.hasClass('loading')) { return }
                                                                                $btnRef.addClass("loading")
                                                                                Helper.confirmModal({
                                                                                    title: 'นักดนตรีออกจากร้าน',
                                                                                    description: 'ยืนยันการออกจากร้าน',
                                                                                    onConfirm: () => {
                                                                                        this.signOut(brand)
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        $btnRef.removeClass("loading")
                                                                                    }
                                                                                })
                                                                            })
                                                                        }}
                                                                    ><i className="fas fa-sign-out-alt" /></button>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.isLoading
                                                && this.state.brandList.length === 0 ?
                                                <div className="flex row flex-center">
                                                    <strong>ไม่มีนักดนตรี</strong>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {/* {
                                        !this.state.isLoading
                                            && this.state.brandList.length > 1 ?
                                            <div className="card-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    style={{ width: '100%' }}
                                                    ref={(ref) => {
                                                        let $btnRef = window.$(ref)
                                                        $btnRef.off().on('click', () => {
                                                            if ($btnRef.hasClass('loading')) { return }
                                                            $btnRef.addClass("loading")
                                                            Helper.reactToDom(window.$('body'),
                                                                <SequenceLiveModal
                                                                    onClose={() => {
                                                                        $btnRef.removeClass("loading")
                                                                    }}
                                                                    uid={storeUid}
                                                                    data={this.state.brandList}
                                                                />
                                                            );
                                                        })
                                                    }}
                                                >เรียงลำดับนักดนตรี</button>
                                            </div>
                                            : null
                                    } */}
                                </div>
                                {
                                    this.props.uid ?
                                        <div>
                                            <QRCode value={`https://${process.env.REACT_APP_WEBMUSIC}/?storeUid=${this.props.uid}`}
                                                logoImage={window.location.origin + "/assets/images/skitz-qr-logo.png"}
                                                size={250}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                            {
                                this.state.playingBrand ?
                                    <>
                                        <div className="col-12 col-lg-3">
                                            <div className="card card-outline">
                                                <div className="card-body box-profile">
                                                    <div className="text-center">
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img
                                                                src={this.state.playingBrand && this.state.playingBrand.artist && this.state.playingBrand.artist.photoURL ? this.state.playingBrand.artist.photoURL : "/assets/images/no_img.png"}
                                                                alt="User profile" />
                                                        </div>
                                                    </div>
                                                    <h3 className="profile-username text-center">{this.state.playingBrand.artist.name || '-'}</h3>
                                                    <div className="flex row" style={{ justifyContent: 'center' }}>
                                                        <Link to={'/artist/view/' + this.state.playingBrand.artist.id}>ดูข้อมูล</Link>
                                                    </div>
                                                    <ul className="list-group list-group-unbordered mb-3 mt-2">
                                                        {
                                                            this.state.playingBrand
                                                                && this.state.playingBrand.created ?
                                                                <li className="list-group-item">
                                                                    <b>เข้าสู่ระบบ</b> <div className="float-right">{Helper.getDateThai(this.state.playingBrand.created, { is_show_time: true, plus_hours: 7 })}</div>
                                                                </li>
                                                                : null
                                                        }
                                                        {
                                                            this.state.playingBrand
                                                                && this.state.playingBrand.start ?
                                                                <li className="list-group-item">
                                                                    <b>ขึ้นแสดง</b> <div className="float-right">{Helper.getDateThai(this.state.playingBrand.start, { is_show_time: true, plus_hours: 7 })}</div>
                                                                </li>
                                                                : null
                                                        }
                                                    </ul>
                                                    <div className="flex row">
                                                        <div className="right-floated">
                                                            <button type="button"
                                                                className="btn btn-danger"
                                                                ref={(ref) => {
                                                                    let $btnRef = window.$(ref)
                                                                    $btnRef.off().on('click', () => {
                                                                        if ($btnRef.hasClass('loading')) { return }
                                                                        $btnRef.addClass("loading")
                                                                        this.endShow({
                                                                            onError: () => {
                                                                                $btnRef.removeClass("loading")
                                                                            },
                                                                            onSuccess: () => {
                                                                                $btnRef.removeClass("loading")
                                                                            }
                                                                        })
                                                                    })
                                                                }}
                                                            >ลงเวที</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="flex row flex-center form-group">
                                                <h4 style={{ marginBottom: 0 }}>รายการขอเพลง</h4>
                                                <div className="right-floated">
                                                    <strong>{this.state.musicList.length} เพลง</strong>
                                                </div>
                                            </div>
                                            <hr />
                                            {
                                                this.state.isMusicListLoading ?
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                !this.state.isMusicListLoading
                                                    && this.state.musicList.length === 0 ?
                                                    <div className="callout callout-danger">
                                                        <h5 style={{ marginBottom: 0 }}>ไม่มีรายการเพลง</h5>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                !this.state.isMusicListLoading
                                                    && this.state.musicList.length > 0 ?
                                                    this.state.musicList.map((music, music_i) => {
                                                        let return_class = 'callout callout-warning';
                                                        if (music.statusId == 2) {
                                                            return_class = 'callout callout-info';
                                                        }
                                                        if (music.statusId == 3) {
                                                            return_class = 'callout callout-danger';
                                                        }
                                                        if (music.statusId == 4) {
                                                            return_class = 'callout callout-success';
                                                        }
                                                        return (
                                                            <div className={return_class} key={music_i}>
                                                                <div className="flex row">
                                                                    <div className="flex row flex-center" style={{ paddingRight: 15 }}>
                                                                        {
                                                                            music.statusId == 2 ?
                                                                                <i className="fas fa-play" style={{ fontSize: 24 }} />
                                                                                :
                                                                                music.statusId == 3 ?
                                                                                    <i className="fas fa-times" style={{ fontSize: 24 }} />
                                                                                    : music.statusId == 4 ?
                                                                                        <i className="fas fa-check" style={{ fontSize: 24 }} />
                                                                                        :
                                                                                        <i className="fas fa-pause" style={{ fontSize: 24 }} />
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <h5 style={{ marginBottom: 0 }}>{music.musicName}</h5>
                                                                        <p className='mb-0'>ศิลปิน : {music.artistName}</p>
                                                                        {
                                                                            music.user ?

                                                                                <div >
                                                                                    ผู้ขอเพลง : {music.user.displayName}
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                    <div className="right-floated">
                                                                        <p className='mb-0' style={{ color: '#555' }}>{Helper.getDateThai(music.created, { is_show_time: true, plus_hours: 7 })}</p>
                                                                        {
                                                                            music.tipAmount > 0 ?
                                                                                <div style={{ marginTop: 10 }} className={"d-flex align-items-center justify-content-end"}>
                                                                                    {Helper.numberFormat(music.tipAmount)}
                                                                                    <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class SequenceLiveModal extends React.Component {
    constructor(props) {
        super(props);
        let updateData = this.setSequenceData(props.data)
        this.state = {
            data: props.data,
            updateData: [...updateData],
        }
    }
    async onSubmit() {
        let data = {
            updateData: this.state.updateData,
            storeUid: this.props.uid
        };
        let res = await axios.post(window.api_host + '/artist-update-sequence', data);
        this.$btn.removeClass("loading");
        this.props.onClose();
        this.$modal.modal('hide');
    }

    setSequence(oldIndex, newIndex) {
        let aftermovearray = arrayMove(this.state.data, oldIndex, newIndex);
        let setUpdate = this.setSequenceData(aftermovearray);
        this.setState({
            data: aftermovearray,
            updateData: [...setUpdate],
        })
    }

    setSequenceData(array) {
        let setSquence = [];
        array.map((item, item_i) => {
            setSquence.push({
                uid: item.artist.uid,
                sequence: item_i + 1,
            })
            return true;
        })
        return setSquence;
    }

    render() {
        const DragHandle = sortableHandle(({ item }) => {
            return (
                <li className={"list-group-item"} style={{ zIndex: 100000 }}>
                    <div className="flex row">
                        <div className="flex row" style={{ alignItems: 'center' }}>
                            <div><strong>{item.artist.displayName}</strong></div>
                        </div>
                    </div>
                </li>
            )
        });
        const SortableItem = sortableElement(({ item }) => (

            <DragHandle item={item} />
        ));
        const SortableContainer = sortableContainer(({ children }) => {
            return <ul className="list-group">{children}</ul>;
        });
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        if (this.props.onClose) {
                            this.props.onClose();
                        }
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('button[type="submit"]')
                }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">เรียงลำดับนักดนตรี</h4>
                        </div>
                        <div className="modal-body">

                            <SortableContainer
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    this.setSequence(oldIndex, newIndex);
                                }}
                                useDragHandle
                            >
                                {this.state.data.map((item, item_i) => (
                                    <SortableItem key={`item-${item_i}`} index={item_i} item={item} />
                                ))}
                            </SortableContainer>

                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    this.props.onClose();
                                    this.$modal.modal('hide');
                                }}
                            >{this.props.cancle_text || "ยกเลิก"}</button>
                            <button type="button" className="btn btn-primary"
                                ref={ref => { this.$btn = window.$(ref) }}
                                onClick={() => {
                                    if (this.$btn.hasClass('loading')) { return; }
                                    this.$btn.addClass("loading")
                                    this.onSubmit();
                                }}
                            >{this.props.submit_text || "ตกลง"}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class PreviewUsersModal extends React.Component {
    componentDidMount() {
        this.$modal.modal('show');
    }

    render() {
        let music = this.props.music;
        return (
            <div ref={(ref) => {
                this.$modal = window.$(ref);
                this.$modal.modal('show');
                this.$modal.on('hidden.bs.modal', () => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    let settime = window.setTimeout(() => {
                        this.$modal.remove();
                        clearTimeout(settime);
                    }, 500)
                });
            }}
                className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">รายละเอียด</h4>
                        </div>
                        <div className="modal-body" style={{ borderBottom: '1px solid #e9ecef' }}>
                            <div className="flex row">
                                <div>
                                    <h5 style={{ marginBottom: 0 }}>{music.name}</h5>
                                    <p style={{ marginBottom: 0 }}>{music.artistName}</p>
                                </div>
                                <div className="right-floated">
                                    {
                                        music.users
                                            && music.users.length > 1 ?
                                            <div  >
                                                <i className="fas fa-users" />  {Helper.numberFormat(music.users.length)} ครั้ง
                                            </div>
                                            : null
                                    }
                                    {
                                        music.tip > 0 ?
                                            <div style={{ marginTop: 10 }}>
                                                <i className="fas fa-coins" />  {Helper.numberFormat(music.tip)}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            music
                                && music.users.length > 0 ?
                                <ul className='nav nav-pills flex-column'>
                                    {
                                        music.users.map((user, user_i) => {
                                            return (
                                                <li className="nav-item" key={user_i} style={{ paddingBottom: 0 }}>
                                                    <div className="nav-link">
                                                        <div className="store-item item-row">
                                                            <div className="icon"
                                                                style={{ width: 40, height: 40, padding: 0, marginRight: 10 }}
                                                            >
                                                                {
                                                                    user.photoURL ?
                                                                        <div><img src={user.photoURL} alt="UserIMG" /></div>
                                                                        : <i className="fas fa-user"></i>
                                                                }

                                                            </div>
                                                            <div className="description">
                                                                <div style={{ lineHeight: 1 }}>{user.displayName}</div>
                                                            </div>
                                                            <div className="right-floated">
                                                                <div className="small-text">{Helper.getDateThai(new Date(user.requestAt).getTime())}</div>
                                                                <div className="small-text">{Helper.getTime(new Date(user.requestAt).getTime())}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                :
                                <div className="modal-body"><p>ไม่มีข้อมูล</p></div>
                        }
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.$modal.modal('hide');
                                }}
                            >{this.props.cancle_text || "ปิด"}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}