import { Link, navigate } from '@reach/router';
import React from 'react';
import { Helper } from '../../Helper';
import { db, storage } from '../../firebase';
const axios = require('axios').default;
export default class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            file_stack: [],
            storagePath: [],
            photoPath: [],
            remove_image: [],
            image_paths: [],
            promotion: false,
        }
    }

    componentDidMount() {
        if (this.props.mode === 'add') {
            this.setState({
                is_loading: false
            })
        }
        if (this.props.mode === 'edit') {
            this.fetch();
        }

    }

    async fetch() {
        // console.log(this.props.eventPromotionUid);
        let qs = {
            uid: this.props.uid,
            lang: 'th',
            eventPromotionUid: this.props.eventPromotionUid
        }
        let ref = await axios.get(window.api_host + '/store-promotion?' + window.$.param(qs));
        let promotion = false;
        let image_paths = [];
        if (ref.data.success && ref.data.response && ref.data.response.promotion) {
            promotion = ref.data.response.promotion;
            if (ref.data.response.promotion.photoPath) {
                ref.data.response.promotion.photoPath.map((path, path_i) => {
                    image_paths.push({
                        photo: path,
                        storage: ref.data.response.promotion.storagePath[path_i]
                    })
                    return true;
                })
            }
        }
        // console.log(image_paths);
        this.setState({
            promotion: promotion,
            image_paths: image_paths,
            is_loading: false
        })
    }

    readURL(input) {
        if (input.files && input.files.length > 0) {
            let prepare_file_stack = [...this.state.file_stack];
            for (var key of Object.keys(input.files)) {
                prepare_file_stack.push(input.files[key]);
            }
            let file_stack = [];
            file_stack = [...prepare_file_stack]
            // file_stack.push(prepare_file_stack[prepare_file_stack.length - 1]);
            this.setState({
                file_stack: file_stack
            }, () => {
                this.$file.val('');
            })
        }
    }


    async uploadFile(upload_index, options) {
        let file = this.state.file_stack[upload_index]
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 1280, maxHeight: 768 });
        var uploadTask = storage.ref().child('store_promotion_event/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
        }, async () => {
            let url = 'store_promotion_event/' + filename;
            const photoURL = await storage.ref(url).getDownloadURL();

            let photoPath = [...this.state.photoPath];
            let storagePath = [...this.state.storagePath];;
            photoPath.push(photoURL)
            storagePath.push(url);
            this.setState({
                photoPath: photoPath,
                storagePath: storagePath,
            }, async () => {
                upload_index++;
                if (upload_index >= this.state.file_stack.length) {
                    await db.doc('stores/' + options.storeUid + '/promotions/' + options.newUid).update({
                        photoPath: photoPath,
                        storagePath: storagePath
                    })
                    window.$(document).Toasts('create', {
                        title: 'เพิ่มข้อมูลสำเร็จ',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    })
                    // window.location.href = window.location.origin + '/manage-promotion-event'
                    navigate('/manage-promotion-event');
                    return;
                }
                if (upload_index < this.state.file_stack.length) {
                    this.uploadFile(upload_index, {
                        newUid: options.newUid,
                        storeUid: options.storeUid
                    });
                }
            })

            // if (options.onSuccess) {
            //     options.onSuccess(url);
            // }
        });
    }

    async update(data) {
        data.photoPath = [];
        data.storagePath = [];
        this.state.image_paths.map((photo) => {
            data.photoPath.push(photo.photo);
            data.storagePath.push(photo.storage);
            return true;
        })
        let update = {};
        update.uid = this.props.eventPromotionUid;
        update.storeUid = this.props.uid;
        update.data = data;
        if (this.state.remove_image && this.state.remove_image.length > 0) {
            update.remove_image = this.state.remove_image;
        }
        let ref = await axios.post(window.api_host + '/update-store-promotion', { ...update });
        if (ref.data.success) {
            if (this.state.remove_image && this.state.remove_image.length > 0) {
                for (const imagePath of this.state.remove_image) {
                    storage.ref().child(imagePath).delete();
                }
            }
            let upload_index = 0;
            if (this.state.file_stack.length > 0) {
                this.setState({
                    photoPath: data.photoPath,
                    storagePath: data.storagePath
                })
                this.uploadFile(upload_index, {
                    newUid: this.props.eventPromotionUid,
                    storeUid: this.props.uid
                })
            } else {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                // window.location.href = window.location.origin + '/manage-promotion-event'
                navigate('/manage-promotion-event');
            }
        } else {
            this.$submit.removeClass("loading");
            this.$file.parent().find('.btn').removeClass('loading');
        }
    }
    async createPromotionAndEvent(data) {
        let _data = { ...data };
        _data.isVisible = true;
        _data.createUser = {
            uid: this.props.user.uid,
            displayName: this.props.user.displayName,
            phoneNumber: this.props.user.phoneNumber,
            email: this.props.user.email,
        }
        let ref = await axios.post(window.api_host + '/add-store-promotion', {
            data: _data
        });
        if (ref.data.success) {
            let upload_index = 0;
            if (this.state.file_stack.length > 0) {
                this.uploadFile(upload_index, {
                    newUid: ref.data.response.newUid,
                    storeUid: data.storeUid
                })
            } else {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                // window.location.href = window.location.origin + '/manage-promotion-event'
                navigate('/manage-promotion-event');
            }
        } else {
            this.$submit.removeClass("loading");
            this.$file.parent().find('.btn').removeClass('loading');
        }


        // console.log("photos", _data.photoPath);

    }


    render() {
        if (this.state.is_loading) {
            return (<div style={{ padding: '1rem 0', textAlign: 'center' }}><div className="ui active inline loader" ></div></div>)
        }
        // console.log(this.props.mode)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{this.props.mode === 'add' ? "เพิ่ม" : "แก้ไข"}โปรโมชั่นและอีเว้นท์</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={"/dashboard"}>หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/manage-promotion-event"}>จัดการโปรโมชั่นและอีเว้นท์</Link></li>
                                    <li className="breadcrumb-item active">{this.props.mode === 'add' ? "เพิ่ม" : "แก้ไข"}โปรโมชั่นและอีเว้นท์</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className="col-12 col-md-4">
                                <form className="card"
                                    ref={(ref) => { this.$searchForm = window.$(ref) }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (this.$submit.hasClass('loading')) { return; }
                                        this.$submit.addClass('loading');
                                        this.$file.parent().find('.btn').addClass('loading');
                                        let data = Helper.getFormInputObject(this.$searchForm);
                                        if (this.props.mode === 'add') {
                                            this.createPromotionAndEvent(data);
                                        }
                                        if (this.props.mode === 'edit') {
                                            this.update(data);
                                        }
                                    }}
                                >
                                    <div className="card-header">
                                        <div className="flex row">
                                            <h6 className="card-title" style={{ fontWeight: 'bold' }}>เพิ่มโปรโมชั่นและอีเว้นท์</h6>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div style={{ position: 'relative' }}>
                                            {
                                                this.state.is_loading ?
                                                    <div className={"absolute_loading"}>
                                                        <div className="ui active inline loader" ></div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                !this.state.is_loading
                                                    && (this.props.mode === 'add'
                                                        || (this.props.mode === 'edit' && this.state.promotion)) ?
                                                    <>
                                                        <div className={"form-group"}>
                                                            <label>รูปภาพ</label>
                                                            <input name="image"
                                                                type={"file"}
                                                                accept="image/*"
                                                                ref={(ref) => this.$file = window.$(ref)}
                                                                style={{ display: 'none' }}
                                                                id="file_image"
                                                                multiple="multiple"
                                                                onChange={(e) => {
                                                                    if (this.$file.parent().find('.btn').hasClass('loading')) {
                                                                        return;
                                                                    }
                                                                    this.readURL(this.$file[0]);
                                                                }}
                                                            />
                                                            {/* <div className="row" id="show_image">
                                                                {
                                                                    this.state.file_stack
                                                                        && this.state.file_stack.length > 0 ?
                                                                        this.state.file_stack.map((file_stack, file_stack_i) => {
                                                                            var reader = new FileReader();
                                                                            reader.onload = (e) => {
                                                                                window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                                            };
                                                                            reader.readAsDataURL(file_stack);
                                                                            return (
                                                                                <div className="col-12" key={file_stack_i} >
                                                                                    <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                                                        <div className="promotion-delete">
                                                                                            <button className="btn btn-danger"
                                                                                                type="button"
                                                                                                onClick={() => {
                                                                                                    let file_stack = [...this.state.file_stack];
                                                                                                    file_stack.splice(file_stack_i, 1);
                                                                                                    this.setState({
                                                                                                        file_stack: file_stack
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-trash-alt"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="card-body">
                                                                                            <div id={"images_file_upload_" + file_stack_i} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                        : null
                                                                }
                                                                {
                                                                    this.props.data && this.props.data.imageURL ?
                                                                        <img src={this.props.data.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                                        : null
                                                                }
                                                            </div> */}
                                                            <label htmlFor="file_image"
                                                                type={"button"}
                                                                className="btn btn-default"
                                                                style={{ width: '100%', marginBottom: 0 }}
                                                            >
                                                                อัพโหลด
                                                            </label>
                                                        </div>
                                                        <div className={"form-group"}>
                                                            <label>หัวข้อ (ไทย)</label>
                                                            <input name="nameTH"
                                                                className="form-control"
                                                                required={true}
                                                                defaultValue={this.state.promotion && this.state.promotion.nameTH ? this.state.promotion.nameTH : ""}
                                                            />
                                                        </div>
                                                        <div className={"form-group"} style={{ display: 'none' }}>
                                                            <label>หัวข้อ (อังกฤษ)</label>
                                                            <input name="nameEN" className="form-control"
                                                                defaultValue={this.state.promotion && this.state.promotion.nameEN ? this.state.promotion.nameEN : ""}
                                                            />
                                                        </div>
                                                        <div className={"form-group"}>
                                                            <label>รายละเอียด (ไทย)</label>
                                                            <textarea className="form-control" name="descriptionTH"
                                                                defaultValue={this.state.promotion && this.state.promotion.descriptionTH ? this.state.promotion.descriptionTH : ""}></textarea>
                                                        </div>
                                                        <div className={"form-group"} style={{ display: 'none' }}>
                                                            <label>รายละเอียด (อังกฤษ)</label>
                                                            <textarea className="form-control" name="descriptionEN"
                                                                defaultValue={this.state.promotion && this.state.promotion.descriptionEN ? this.state.promotion.descriptionEN : ""}></textarea>
                                                        </div>
                                                        <div className="custom-control custom-switch main-input-checkbox">
                                                            <input type="checkbox"
                                                                className="custom-control-input"
                                                                id="appVisible"
                                                                name={"appVisible"}
                                                                defaultValue={"on"}
                                                                defaultChecked={this.state.promotion && this.state.promotion.appVisible ? this.state.promotion.appVisible : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor="appVisible">การแสดงผลบนแอพ</label>
                                                        </div>
                                                        <input type="hidden" name="storeUid" value={this.props.uid} />
                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="flex row">
                                            <Link to={"/manage-promotion-event"} className="btn btn-default">ยกเลิก</Link>
                                            <div className="right-floated">
                                                <button className="btn btn-primary" type="submit" ref={(ref) => { this.$submit = window.$(ref) }}>{this.props.mode === 'add' ? "เพิ่ม" : "แก้ไข"}โปรโมชั่นและอีเว้นท์</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 col-md-6" id="show_image">
                                <div className="row">
                                    {
                                        this.state.image_paths
                                            && this.state.image_paths.length > 0 ?
                                            this.state.image_paths.map((photoPath, photoPath_index) => {

                                                return (
                                                    <div className="col-4" key={photoPath_index} >
                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        console.log(photoPath_index);
                                                                        let remove_image = [...this.state.remove_image];
                                                                        let image_paths = [...this.state.image_paths];
                                                                        image_paths.splice(photoPath_index, 1);
                                                                        remove_image.push(photoPath.storage);
                                                                        this.setState({
                                                                            remove_image: remove_image,
                                                                            image_paths: image_paths,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <img src={photoPath.photo} alt={"EventAndPromotion"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                    {
                                        this.state.file_stack
                                            && this.state.file_stack.length > 0 ?
                                            this.state.file_stack.map((file_stack, file_stack_i) => {
                                                var reader = new FileReader();
                                                reader.onload = (e) => {
                                                    window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                };
                                                reader.readAsDataURL(file_stack);
                                                return (
                                                    <div className="col-4" key={file_stack_i} >
                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let file_stack = [...this.state.file_stack];
                                                                        file_stack.splice(file_stack_i, 1);
                                                                        this.setState({
                                                                            file_stack: file_stack
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <div id={"images_file_upload_" + file_stack_i} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                </div>
                                {
                                    this.props.data && this.props.data.imageURL ?
                                        <img src={this.props.data.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}